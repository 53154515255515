import React, { useState } from 'react';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  convertToInternationalCurrencySystem,
  fetchDat,
  fetchdat,
  logoWrapper
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { MenuData } from '../../components/Tables/menuData';
import { useEffect } from 'react';
import * as actionTypes from '../../store/actionTypes';
import EditToken from '../../screens/editToken';
import PecuGraph from '../../components/Graphs/PecuGraph';
import CommonMenu from '../../components/commonMenu/commonMenu';
import TokenTable from '../../components/Tables/TokenTable/token.table';
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);

  timestamp.setHours(timestamp.getHours());
  return timestamp;
}
const DatTable = () => {
  const { dat, loading, isAdmin, PecuPrice } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blinkUpList, setBlinkUpList] = useState([]);
  const [blinkDownList, setBlinkDownList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState({});
  const [priceList, setPriceList] = useState([]);
  const [searchdat, setSearchdat] = useState('');
  const [datPage, setdatPage] = useState(0);
  const [page, setPage] = useState(1);
  const disabled = ['USD/JPY'];
  const filterdat = (searchdat) => {
    if (searchdat && searchdat !== '') {
      return dat?.tokens?.filter(
        (e) =>
          !disabled.includes(e.symbol) &&
          (e.symbol.toLowerCase().includes(searchdat.toLowerCase()) ||
            e.name.toLowerCase().includes(searchdat.toLowerCase()))
      );
    } else {
      return dat?.tokens?.filter((e) => !disabled.includes(e.symbol));
    }
  };

  useEffect(() => {
    fetchDat(dispatch, actionTypes.FETCH_DAT, page, 10);
    setTimeout(() => {
      setPriceList([]);
    }, 2000);
    const intervalId = setInterval(() => {
      fetchDat(dispatch, actionTypes.FETCH_DAT, page, 10);
    }, 1500);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [page]); // Include 'page' in the dependency array

  useEffect(() => {
    // Compare current prices with the previous prices
    if (priceList?.length > 0) {
      const updatedPriceList = dat?.tokens
        .filter(({ identitySymbol, currentPrice }) => {
          const prevPrice = priceList.find(
            (item) => item.identitySymbol === identitySymbol
          );
          return prevPrice && prevPrice.currentPrice !== currentPrice;
        })
        .map(({ identitySymbol, currentPrice }) => ({
          identitySymbol,
          currentPrice
        }));
      if (updatedPriceList.length > 0) {
        const newBlinkDownList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.identitySymbol === item.identitySymbol
          );
          return prevPrice && item.currentPrice < prevPrice.currentPrice;
        });
        const newBlinkUpList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.identitySymbol === item.identitySymbol
          );
          return prevPrice && item.currentPrice > prevPrice.currentPrice;
        });

        setPriceList((prevPriceList) => [
          ...prevPriceList.filter(
            (item) =>
              !updatedPriceList.some(
                (updatedItem) =>
                  updatedItem.identitySymbol === item.identitySymbol
              )
          ),
          ...updatedPriceList
        ]);

        setBlinkUpList(newBlinkUpList.map((item) => item.identitySymbol));
        setBlinkDownList(newBlinkDownList.map((item) => item.identitySymbol));

        setTimeout(() => {
          setBlinkUpList([]);
          setBlinkDownList([]);
        }, 500);
      }
    } else {
      setPriceList(
        dat?.tokens?.map(({ identitySymbol, currentPrice }) => ({
          identitySymbol,
          currentPrice
        }))
      );
    }
  }, [dat, priceList]);
  // console.log(dat, 'dat');
  return (
    <div
      className="screen"
      style={{
        zIndex: '100',
        position: 'sticky',
        width: '100%',
        marginTop: '4rem'
      }}
    >
      <PecuGraph />
      <div
        style={{
          width: '95%',
          minWidth: '340px'
        }}
        className="res-token"
      >
        <CommonMenu label={'DBTs'} setSearch={setSearchdat} />
        <TableContainer
          sx={{
            backgroundColor: '#1a1b1f',
            mt: 5,
            borderRadius: '1rem',
            minHeight: '80vh'
          }}
          component={Paper}
        >
          <p
            style={{
              color: 'rgb(195, 197, 203)',
              fontSize: '15px',
              fontWeight: 'bold',
              textAlign: 'left',
              padding: '1rem',
              backgroundColor: '#21242b',
              left: '0px',
              position: 'sticky'
            }}
          >
            Digital Basket Token
          </p>
          {loading && <LinearProgress color="inherit" />}
          {!loading ? (
            // <Table
            //   sx={{
            //     [`& .${tableCellClasses.root}`]: {
            //       borderBottom: ' 1px solid #1e2128'
            //     }
            //   }}
            // >
            //   <TableHead className="">
            //     <TableRow className="">
            //       <TableCell className="twhite">Name</TableCell>
            //       <TableCell className="twhite" align="left">
            //         Price (USD)
            //       </TableCell>

            //       <TableCell className="twhite" align="left">
            //         1hr %
            //       </TableCell>
            //       <TableCell className="twhite" align="left">
            //         24hr %
            //       </TableCell>
            //       <TableCell className="twhite" align="left">
            //         Volume 24H
            //       </TableCell>
            //       <TableCell className="twhite" align="left">
            //         TVL (USD)
            //       </TableCell>

            //       <TableCell className="twhite" align="left"></TableCell>
            //     </TableRow>
            //   </TableHead>

            //   <TableBody>
            //     {filterdat(searchdat)?.map((each, index) => {
            //       const {
            //         name,
            //         symbol,
            //         currentPrice,
            //         identitySymbol,
            //         logo,
            //         price1hour,
            //         price24hour,
            //         id,
            //         volume,
            //         tvl,
            //         timestamp
            //       } = each || {};
            //       return (
            //         <TableRow
            //           key={id}
            //           className={
            //             blinkUpList.includes(identitySymbol)
            //               ? 'blinkup'
            //               : blinkDownList.includes(identitySymbol)
            //               ? 'blinkdown'
            //               : ''
            //           }
            //         >
            //           <TableCell className="twhite" align="left">
            //             <Link
            //               to={`/dbt/${identitySymbol}`}
            //               state={{
            //                 price: currentPrice,
            //                 symbol: symbol
            //               }}
            //             >
            //               <div
            //                 style={{
            //                   display: 'flex',
            //                   alignItems: 'center'
            //                 }}
            //               >
            //                 <Avatar
            //                   className="rounded"
            //                   src={logoWrapper(logo)}
            //                   alt="token logo"
            //                   style={{
            //                     height: '35px',
            //                     width: '35px',
            //                     fontSize: '18px'
            //                   }}
            //                 />

            //                 <span
            //                   style={{
            //                     marginLeft: '1rem',
            //                     fontSize: '14px',
            //                     color: 'grey',
            //                     display: 'flex',
            //                     flexDirection: 'column'
            //                   }}
            //                 >
            //                   {' '}
            //                   <small
            //                     style={{
            //                       fontSize: '13px',
            //                       color: 'orange'
            //                     }}
            //                   >
            //                     {identitySymbol}
            //                   </small>
            //                   <small
            //                     style={{
            //                       fontSize: '14px',
            //                       marginRight: '4px',
            //                       fontWeight: 400
            //                     }}
            //                   >
            //                     {name}
            //                   </small>
            //                 </span>
            //               </div>
            //             </Link>
            //           </TableCell>
            //           <TableCell
            //             className={
            //               currentPrice - price1hour >= 0 ? `twhite ` : `twhite `
            //             }
            //             align="left"
            //           >
            //             {' '}
            //             {currentPrice >= 1
            //               ? currentPrice.toFixed(5).toLocaleString('en-US')
            //               : currentPrice.toFixed(5)}
            //           </TableCell>

            //           <TableCell
            //             className={
            //               each.currentPrice - each.price1hour >= 0
            //                 ? `twhite green`
            //                 : `twhite red`
            //             }
            //             align="left"
            //           >
            //             {each.currentPrice - each.price1hour > 0 ? (
            //               <ArrowUpward sx={{ fontSize: '13px' }} />
            //             ) : each.currentPrice - each.price1hour < 0 ? (
            //               <ArrowDownward sx={{ fontSize: '13px' }} />
            //             ) : (
            //               <ArrowUpward sx={{ fontSize: '13px' }} />
            //             )}
            //             {((currentPrice - price1hour) / currentPrice)
            //               .toFixed(4)
            //               .toLocaleString('en-US')}{' '}
            //             %
            //           </TableCell>
            //           <TableCell
            //             className={
            //               currentPrice - price24hour >= 0
            //                 ? `twhite green`
            //                 : `twhite red`
            //             }
            //             align="left"
            //           >
            //             {currentPrice - price24hour > 0 ? (
            //               <ArrowUpward sx={{ fontSize: '13px' }} />
            //             ) : currentPrice - price24hour < 0 ? (
            //               <ArrowDownward sx={{ fontSize: '13px' }} />
            //             ) : (
            //               <ArrowUpward sx={{ fontSize: '13px' }} />
            //             )}
            //             {((currentPrice - price24hour) / currentPrice)
            //               .toFixed(4)
            //               .toLocaleString('en-US')}{' '}
            //             %
            //           </TableCell>

            //           <TableCell className="twhite pink" align="left">
            //             {volume
            //               ? convertToInternationalCurrencySystem(volume)
            //               : '0.00'}
            //           </TableCell>

            //           <TableCell className="twhite pink" align="left">
            //             {volume
            //               ? convertToInternationalCurrencySystem(tvl)
            //               : '0.00'}
            //           </TableCell>

            //           <TableCell
            //             className={'twhite'}
            //             align="left"
            //             style={{
            //               display: 'flex',
            //               flexDirection: 'column'
            //             }}
            //           >
            //             <p style={{ fontSize: '12px', fontWeight: 300 }}>
            //               {getFormattedTime(timestamp).toUTCString()}
            //             </p>
            //           </TableCell>
            //           <TableCell>
            //             {' '}
            //             <a
            //               href={`https://app.hootdex.net/${each.identitySymbol}`}
            //               target="_blank"
            //               rel="noreferrer"
            //             >
            //               <Button
            //                 sx={{
            //                   color: 'white',
            //                   textTransform: 'capitalize',
            //                   backgroundColor: '#363736'
            //                   // m: 1,
            //                 }}
            //               >
            //                 Swap
            //               </Button>
            //             </a>
            //           </TableCell>
            //           {isAdmin && (
            //             <TableCell>
            //               {' '}
            //               <Button
            //                 sx={{
            //                   color: 'white',
            //                   textTransform: 'capitalize',
            //                   backgroundColor: '#363736'
            //                   // m: 1,
            //                 }}
            //                 onClick={() => {
            //                   setOpen(!open);
            //                   setSelectedToken(each);
            //                 }}
            //               >
            //                 Edit
            //               </Button>
            //             </TableCell>
            //           )}
            //         </TableRow>
            //       );
            //     })}
            //   </TableBody>
            // </Table>
            <TokenTable
              loading={loading}
              token={filterdat(searchdat)}
              blinkUpList={blinkUpList}
              blinkDownList={blinkDownList}
              href={'dbt'}
              PecuPrice={PecuPrice}
              swappable={true}
              getFormattedTime={getFormattedTime}
              showPecu={false}
            />
          ) : (
            <Skeleton
              sx={{ bgcolor: '#21242b', mt: 1 }}
              variant="rectangular"
              margin={'1rem'}
              height={100}
            />
          )}
        </TableContainer>
        <div className="nextprevcontainer">
          {' '}
          showing {`${(page - 1) * 10}-${page * 10}/${dat.totalPages * 10}`}
          <button
            onClick={() => {
              setPage((prev) => (prev >= 1 ? prev - 1 : prev));
              setdatPage((prev) => (prev >= 10 ? prev - 10 : prev));
            }}
          >{`<`}</button>
          <button
            onClick={() => {
              setPage((prev) =>
                prev < Math.abs(dat.totalPages * 10 - 10) ? prev + 1 : prev
              );
              setdatPage((prev) => (prev < dat.length - 10 ? prev + 10 : prev));
            }}
          >{`>`}</button>
        </div>
      </div>
      <EditToken token={selectedToken} open={open} setOpen={setOpen} />
    </div>
  );
};

export default React.memo(DatTable);
