import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Grid,
  IconButton,
  LinearProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BuyToken from '../../components/Modal/BuyToken';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../../components/coinInfo/coinInfo';
import { convertToInternationalCurrencySystem, logoWrapper } from '../../utils';
import { Helmet } from 'react-helmet-async';

export default function WrapPage({ pecuCoins, user }) {
  const [data, setData] = useState({});
  const tokenSymbol = useParams().tokenSymbol;
  const [pools, SetPoolInfo] = useState([]);
  const token = {
    tokenName: data?.baseToken,
    timestamp: data?.date_time,
    firstPrice: data?.firstPrice,
    id: data?.id,
    initialFinal: data?.initialFinal,
    pecuInvestement: data?.pecuInvestement,
    pecuValue: data?.pecuValue,
    public_key: data?.public_key,
    tokenSymbol: data?.symbol,
    image: data?.image,
    ...data
  };
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });
  // const [tokenPrice, setTokenPrice] = useState([
  //   {
  //     currentPrice: null,
  //     previousPrice: null
  //   }
  // ]);

  // const get_crypto_Data = () => {
  //   let symbol = tokenSymbol?.slice(1);
  //   axios.get(`${url}/crypto/coin?symbol=${symbol}`).then((res) => {
  //     setCryptoData(res.data.token);
  //   });
  // };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${url}/wallet/get_my_tokens_wrap_new?symbol=${tokenSymbol}`)
      .then((res) => {
        if (res.data.status) {
          const token = res.data.tokens;
          setData(token[0]);
          SetPoolInfo(res.data.pools);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [tokenSymbol]);

  return (
    <>
      <Helmet>
        <title>{`${token?.tokenSymbol ?? 'Wraps'} ${
          data?.currentPrice?.toFixed(5) ?? ''
        }`}</title>
        {/* Other meta tags or head elements */}
      </Helmet>
      {loading && (
        <div>
          <LinearProgress sx={{ backgroundColor: 'grey' }} />
        </div>
      )}
      {!loading && (
        <Box
          sx={{ padding: { xs: 1, md: '1rem 4rem' }, marginLeft: { xs: 4 } }}
        >
          <Grid
            sx={{ marginLeft: '-55px' }}
            container
            spacing={5}
            // padding={{ xs: 0, md: 0 }}
          >
            <Grid item xs={12} md={6}>
              {/* <div>
                <Link
                  to={`/`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >{`Home `}</Link>
                <Link
                  to={`/tokens`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >
                  {' >'} Tokens
                </Link>
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                >
                  {' >'} {token?.symbol}
                </span>
              </div> */}
            </Grid>

            <Grid item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginBottom: -40
                }}
              >
                {/* <p>Add Liquidity</p> */}

                {/* buy token modal */}
                <BuyToken each={token} pecuCoins={pecuCoins} user={user} />
              </div>
            </Grid>

            <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
              <div
                style={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  borderRadius: '20px',
                  height: '100%',
                  padding: '1rem',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start'
                }}
                className="shadowGrey"
              >
                <div
                  style={{
                    display: 'flex',
                    // alignItems: 'center',
                    margin: '0 0 1rem 0',
                    width: 'fit-content'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={logoWrapper(token?.logo)}
                    alt={data?.token_symbol?.slice(1)}
                    style={{
                      backgroundColor: 'orange',
                      height: '45px',
                      width: '45px',
                      fontSize: '18px',
                      alignSelf: 'center'
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 10
                    }}
                  >
                    <p
                      style={{
                        color: 'white',
                        // marginLeft: '5px',
                        fontSize: '16px',
                        fontWeight: '500'
                        // fontFamily: 'Inter var sans-serif'
                      }}
                    >
                      <span>{data?.token_symbol}</span>
                    </p>
                    <p style={{ fontSize: '1rem', color: 'grey' }}>
                      {data?.name}
                    </p>
                  </div>
                </div>
                <div>
                  <a
                    href="https://pecuscan.com/"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="outlined"
                      color="success"
                      style={{ borderRadius: 50, width: '100%' }}
                    >
                      Pecuscan
                    </Button>
                  </a>
                  <br />

                  <br></br>
                  <p>
                    {token?.symbol} Price : $
                    {convertToInternationalCurrencySystem(token?.currentPrice)}{' '}
                    <small
                      style={{
                        fontSize: '16px',
                        color:
                          token?.price1hour - token?.currentPrice > 0
                            ? 'green'
                            : 'red'
                      }}
                    >
                      {token?.price1hour - token?.currentPrice > 0 ? (
                        <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                      ) : (
                        <ArrowDownward sx={{ fontSize: '13px' }} />
                      )}
                      {(
                        Math.abs(
                          (token?.price1hour - token?.currentPrice) /
                            token?.price1hour
                        ) * 100
                      ).toFixed(5)}{' '}
                      %
                    </small>
                  </p>
                  <br></br>

                  <p>Name : {token?.name}</p>
                  <br></br>
                  <p>
                    TVL : $
                    {convertToInternationalCurrencySystem(
                      token?.amount_issued * token?.currentPrice
                    )}
                  </p>

                  <p style={{ fontSize: '12px', lineHeight: '25px' }}>
                    <h3>Description</h3>
                    {token?.description}
                  </p>
                  <br></br>

                  <p style={{ fontSize: '14px' }}>Amount: {token?.amount}</p>
                  <br></br>
                  <p style={{ fontSize: '12px' }}>
                    Created on :
                    {new Date(token?.timestamp)?.toLocaleDateString()}
                    {new Date(token?.timestamp)?.toLocaleTimeString()}
                  </p>
                  <br></br>
                  <Button
                    variant="contained"
                    color="success"
                    style={{ borderRadius: 50, backgroundColor: '#76C8A8' }}
                  >
                    <a
                      // className='learnmore'
                      style={{ fontSize: '10px', padding: 5, color: 'black' }}
                      href={token?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </Button>
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              sx={{ marginTop: { xs: 2, md: 3 }, order: { xs: 0, md: 1 } }}
            >
              <Box sx={{ height: '100%' }}>
                <Box
                  sx={{
                    backgroundColor: 'rgb(25, 27, 31)',
                    height: '100%',
                    borderRadius: '20px',
                    padding: { xs: '0 1rem 0 0', md: '1.5rem' }
                  }}
                  className="shadowGrey"
                >
                  <CoinInfo
                    coin={{
                      id:
                        token.cryptoSymbol === 'BTC'
                          ? 'bitcoin'
                          : token?.cryptoSymbol === 'ETH'
                          ? 'ethereum'
                          : token?.cryptoSymbol === 'USDT'
                          ? 'tether'
                          : '',
                      symbol: token?.symbol
                    }}
                  />

                  {/* <TokenGraph id={token?.id} setTokenPrice={setTokenPrice} /> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
              {/* <TopToken /> */}
              <Transactions />
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
              <Alert
                variant="outlined"
                severity={alert.type}
                sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
              >
                {alert.msg}
              </Alert>
            </Collapse>
          </div>
        </Box>
      )}
    </>
  );
}
