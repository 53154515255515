import React from 'react';
import {
  Avatar,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { api as axios } from '../../api';
import { useEffect, useState } from 'react';
import url from '../../serverUrl';
import symbols from '../../symbolsMapper';
import { ArrowForward, FiberManualRecord } from '@mui/icons-material';
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);

  return timestamp;
}
const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [currentValue, setCurrentValue] = useState(0);
  const [marketTrade, setMarketTrade] = useState([]);
  const get_current_index_coin = () => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        setCurrentValue(res.data[0].value);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const fetchToken = (target) => {
    if (target === 'all') {
      setLoading(true);
      axios
        .get(`${url}/hootdex/available-tokens-transactions`)
        .then((res) => {
          setTransactions(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      setTransactions(transactions.filter((each) => each.tokenName === target));
      setLoading(false);
    }
  };
  const fetchTrade = () => {
    axios
      .get(`${url}/crypto/marketTrade`)
      .then((res) => {
        setTransactions(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // fetchToken("all");
    fetchTrade();
    //get_current_index_coin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {' '}
      <p
        style={{
          color: 'white',
          fontSize: '1.5rem',
          fontWeight: 'bolder',
          marginTop: '2rem'
        }}
      >
        All Transactions
      </p>{' '}
      <div className="transaction-container">
        <TableContainer
          sx={{
            backgroundColor: '#1a1b1f',
            mt: 5,
            borderRadius: '1rem',
            maxHeight: '300px',
            paddingBottom: '1rem',
            overflowY: 'auto',
            flex: 0.49
          }}
          component={Paper}
        >
          {loading && <LinearProgress color="inherit" />}
          {transactions.length ? (
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: ' 1px solid #1e2128'
                }
              }}
            >
              <TableHead className="">
                <TableRow className="">
                  {/* {poolTableAttributes.map((e, index) => ( */}
                  <TableCell className="twhite" component="th" scope="row">
                    Symbol
                  </TableCell>
                  {/* <TableCell className="twhite"> Amount</TableCell> */}
                  <TableCell className="twhite" align="left">
                    Price
                  </TableCell>
                  <TableCell className="twhite" align="left">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions &&
                  transactions
                    ?.slice(0, transactions.length / 2)
                    ?.filter(
                      (e) =>
                        !e.symbol.includes('/PECU') &&
                        !e.symbol.includes('PECU/') &&
                        e.price !== 0
                    )
                    .map((each, index) => (
                      <TableRow key={each.id}>
                        <a
                          href={`https://app.hootdex.net/${
                            symbols[each.symbol] ?? each.symbol
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TableCell className="twhite" align="left">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={`${url}/logo/tokenlogo/${
                                  symbols[each.symbol] ?? each.symbol
                                }`}
                                alt="token logo"
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  fontSize: '18px'
                                }}
                              />

                              <span
                                style={{
                                  marginLeft: '1rem',
                                  fontSize: '14px',
                                  color: 'grey',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <small
                                  style={{
                                    fontSize: '13px',
                                    color: 'white'
                                  }}
                                >
                                  {symbols[each.symbol] ?? each.symbol}
                                </small>
                                {/* <small
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '4px',
                                    fontWeight: 400
                                  }}
                                >
                                  {' '}
                                  {symbols[each.symbol] ?? each.symbol}
                                </small> */}
                              </span>
                            </div>
                          </TableCell>
                        </a>

                        {/* <TableCell className="twhite green" align="left">
                          {each.amount}
                        </TableCell> */}
                        <TableCell className="twhite grey" align="left">
                          ${each.price}
                        </TableCell>

                        <TableCell className="twhite pink" align="left">
                          <div
                            style={{
                              diplay: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <p style={{ color: '#22BD86', fontSize: '14px' }}>
                              {' '}
                              $
                              {parseFloat(each.price * each.amount)?.toFixed(4)}
                            </p>
                            <p style={{ color: '#686F7E', fontSize: '12px' }}>
                              {' '}
                              {getFormattedTime(each.timestamp).toUTCString()}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}

                {/* <TablePagination
            sx={{ color: "white" }}
            rowsPerPageOptions={[10, 50]}
            onChange={(e) => setRows(e)}
          /> */}
              </TableBody>
            </Table>
          ) : (
            <Skeleton
              sx={{ bgcolor: '#21242b', mt: 1 }}
              variant="rectangular"
              margin={'1rem'}
              height={100}
            />
          )}
        </TableContainer>
        <TableContainer
          sx={{
            backgroundColor: '#1a1b1f',
            mt: 5,
            borderRadius: '1rem',
            maxHeight: '300px',
            paddingBottom: '1rem',
            overflowY: 'auto',
            flex: 0.49
          }}
          component={Paper}
        >
          {loading && <LinearProgress color="inherit" />}
          {transactions.length ? (
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: ' 1px solid #1e2128'
                }
              }}
            >
              <TableHead className="">
                <TableRow className="">
                  {/* {poolTableAttributes.map((e, index) => ( */}
                  <TableCell className="twhite" component="th" scope="row">
                    Symbol
                  </TableCell>
                  {/* <TableCell className="twhite"> Amount</TableCell> */}
                  <TableCell className="twhite" align="left">
                    Price
                  </TableCell>
                  <TableCell className="twhite" align="left">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions &&
                  transactions
                    ?.slice(transactions.length / 2)
                    ?.filter(
                      (e) =>
                        !e.symbol.includes('/PECU') &&
                        !e.symbol.includes('PECU/') &&
                        e.price !== 0
                    )
                    .map((each, index) => (
                      <TableRow key={each.id}>
                        <a
                          href={`https://app.hootdex.net/${
                            symbols[each.symbol] ?? each.symbol
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TableCell className="twhite" align="left">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={`${url}/logo/tokenlogo/${
                                  symbols[each.symbol] ?? each.symbol
                                }`}
                                alt="token logo"
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  fontSize: '18px'
                                }}
                              />

                              <span
                                style={{
                                  marginLeft: '1rem',
                                  fontSize: '14px',
                                  color: 'grey',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <small
                                  style={{
                                    fontSize: '13px',
                                    color: 'white'
                                  }}
                                >
                                  {symbols[each.symbol] ?? each.symbol}
                                </small>
                                {/* <small
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '4px',
                                    fontWeight: 400
                                  }}
                                >
                                  {' '}
                                  {symbols[each.symbol] ?? each.symbol}
                                </small> */}
                              </span>
                            </div>
                          </TableCell>
                        </a>

                        {/* <TableCell className="twhite green" align="left">
                          {each.amount}
                        </TableCell> */}
                        <TableCell className="twhite grey" align="left">
                          ${each.price}
                        </TableCell>

                        <TableCell className="twhite pink" align="left">
                          <div
                            style={{
                              diplay: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <p style={{ color: '#22BD86', fontSize: '14px' }}>
                              {' '}
                              $
                              {parseFloat(each.price * each.amount)?.toFixed(4)}
                            </p>
                            <p style={{ color: '#686F7E', fontSize: '12px' }}>
                              {' '}
                              {getFormattedTime(each.timestamp).toUTCString()}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}

                {/* <TablePagination
            sx={{ color: "white" }}
            rowsPerPageOptions={[10, 50]}
            onChange={(e) => setRows(e)}
          /> */}
              </TableBody>
            </Table>
          ) : (
            <Skeleton
              sx={{ bgcolor: '#21242b', mt: 1 }}
              variant="rectangular"
              margin={'1rem'}
              height={100}
            />
          )}
        </TableContainer>
      </div>
      <div
        style={{
          justifyContent: 'center',
          color: '#76C8A8',
          fontWeight: 'bold',
          display: 'flex',
          cursor: 'pointer',
          marginTop: '2rem'
        }}
      >
        <span style={{ textDecoration: 'none', paddingRight: 10 }}>
          See All Transactions
        </span>
        <FiberManualRecord
          fontSize="1rem"
          style={{ alignSelf: 'center', marginRight: '-4px' }}
        />
        <ArrowForward />
      </div>
    </>
  );
};

export default Transactions;
