import React from 'react';

function FillWalletInfo({ setActiveStep, onChangeHandler, account }) {
  const formStyle = {
    width: '600px', // Adjust the width as needed
    margin: '50px auto',
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: 'black',
    minHeight: '500px'
  };

  const inputStyle = {
    marginBottom: '30px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '3px'
  };

  return (
    <div>
      <form style={formStyle} onSubmit={(e) => e.preventDefault()}>
        <label>Derive Pecu From </label>
        <select
          style={inputStyle}
          name="baseInvestor"
          onChange={onChangeHandler}
        >
          <option value={1}>ACC 1</option>
          <option value={2}>ACC 2</option>
          <option value={3}>ACC 3</option>
          <option value={4}>ACC 4</option>
          <option value={5}>ACC 5</option>
          <option value={6}>ACC 6</option>
          <option value={7}>ACC 7</option>
          <option value={8}>ACC 8</option>
          <option value={9}>ACC 9</option>
        </select>
        <label>Pecu Amount</label>
        <input
          type="number"
          placeholder="Pecu"
          name="pecuAmount"
          value={account?.pecuAmount}
          onChange={onChangeHandler}
          style={inputStyle}
        />
        <label>Pecu Value (USD)</label>
        <input
          type="number"
          placeholder="Pecu"
          name="pecuValue"
          disabled
          value={account?.pecuValue}
          onChange={onChangeHandler}
          style={inputStyle}
        />
        <label>Token Amount</label>
        <input
          type="number"
          placeholder="amount"
          name="tokenAmount"
          value={account?.tokenAmount}
          onChange={onChangeHandler}
          style={inputStyle}
        />
        <label>Token Price</label>
        <input
          type="number"
          placeholder="price"
          value={account?.price}
          name="price"
          disabled
          onChange={onChangeHandler}
          style={inputStyle}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'flex-end'
          }}
        >
          {' '}
          <button
            type="submit"
            style={{
              width: '130px',
              border: '1px solid grey',
              color: 'black',
              padding: '10px',
              margin: '5px',
              borderRadius: '3px',
              cursor: 'pointer'
            }}
            onClick={() => {
              setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
            }}
          >
            Back
          </button>
          <button
            type="submit"
            style={{
              width: '130px',
              backgroundColor: 'orange',
              color: 'white',
              padding: '10px',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer',
              margin: '5px'
            }}
            onClick={() => {
              setActiveStep((prev) => prev + 1);
            }}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

export default FillWalletInfo;
