import {
  Alert,
  Avatar,
  Collapse,
  Grid,
  IconButton,
  LinearProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BuyToken from '../components/Modal/BuyToken';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../serverUrl';
import Transactions from '../modules/Transactions/transaction.table';
import TokenGraph from '../components/Graphs/TokenGraph.js';
import soapbox_btn from '../assets/images/soapbox_btn.svg';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../components/coinInfo/coinInfo';
function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return labelValue > 1 ? labelValue?.toFixed(5) : labelValue?.toFixed(5);
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(5) + 'b'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(5) + 'm'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(5) + 'k'
    : Math.abs(Number(labelValue))
    ? Math.abs(Number(labelValue))?.toFixed(5)
    : '0.00';
}
const removeDuplicatedToken = (allData) => {
  for (let i = 0; i < allData.length; i++) {
    for (let j = i + 1; j < allData.length; j++) {
      if (allData[i].symbol == allData[j].symbol) {
        allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
        allData[i].initialFinal =
          allData[j].initialFinal + allData[i].initialFinal;
        allData = allData.filter((e) => e !== allData[j]);
      }
    }
  }

  for (let i = 0; i < allData.length; i++) {
    for (let j = i + 1; j < allData.length; j++) {
      if (allData[i].symbol == allData[j].symbol) {
        return removeDuplicatedToken(allData);
      }
    }
  }

  return allData;
};
export default function HoldingTokenPage({ pecuCoins, user }) {
  const [data, setData] = useState({});
  const tokenSymbol = useParams().tokenSymbol;
  const [projectTokens, setProjectTokens] = useState([]);

  const token = {
    tokenName: data?.token_name,
    timestamp: data?.date_time,
    firstPrice: data?.firstPrice,
    id: data?.id,
    initialFinal: data?.initialFinal,
    pecuInvestement: data?.pecuInvestement,
    pecuValue: data?.pecuValue,
    public_key: data?.public_key,
    tokenSymbol: data?.token_symbol,
    image: data?.image,
    ...data
  };

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });
  const [tokenPrice, setTokenPrice] = useState([
    {
      currentPrice: null,
      previousPrice: null
    }
  ]);

  const fetchProjectTokens = (symbol) => {
    axios
      .get(`${url}/hootdex/get_my_tokens_project?symbol=${symbol}`)
      .then((res) => {
        setProjectTokens([...projectTokens, res.data.tokens[0]]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${url}/wallet/get_my_tokens_holding?symbol=${tokenSymbol}`)
      .then((res) => {
        if (res.data.status) {
          const token = removeDuplicatedToken(res.data.tokens);
          setData(token[0]);
          let allProject = JSON.parse(token[0].projects);
          if (allProject.length > 0) {
            allProject.forEach((e) => {
              fetchProjectTokens(e);
            });
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [tokenSymbol]);

  return (
    <>
      {loading && (
        <div>
          <LinearProgress sx={{ backgroundColor: 'grey' }} />
        </div>
      )}
      {
        <Box sx={{ padding: { xs: 1, md: '1rem 4rem' } }}>
          <Grid
            width={'100%'}
            container
            spacing={1}
            padding={{ xs: 0, md: 0 }}
            mb={1}
          >
            <Grid item xs={12} md={6}>
              <div>
                <Link
                  to={`/`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >{`Home `}</Link>
                <Link
                  to={`/tokens`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >
                  {' >'} Holding Tokens
                </Link>
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                >
                  {' >'} {tokenSymbol}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '2rem 0 0 1rem',
                  width: 'fit-content'
                }}
              >
                <Avatar
                  className="rounded"
                  src={token?.image}
                  alt={token.tokenSymbol?.slice(1)}
                  style={{
                    backgroundColor: 'orange',
                    height: '25px',
                    width: '25px',
                    fontSize: '18px'
                  }}
                />
                <p
                  style={{
                    color: 'white',
                    marginLeft: '5px',
                    fontSize: '24px',
                    fontWeight: '500',
                    fontFamily: 'Inter var sans-serif'
                  }}
                >
                  {/* <span style={{ textTransform: 'lowercase' }}>
                    {`${token?.tokenSymbol?.slice(0, 1)}`}
                  </span> */}
                  {token?.tokenSymbol}
                </p>
              </div>
              <div
                style={{
                  marginTop: '0.5rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minWidth: '90vw',
                  width: '100%',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    minWidth: '60%',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontWeight: '400',
                      color: 'white',
                      margin: '0.5rem ',
                      cursor: 'pointer',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      style={{
                        fontSize: '20px',
                        fontWeight: 500,
                        marginLeft: '5px'
                      }}
                    >
                      {/* {convertToInternationalCurrencySystem(data?.token_price)} */}
                      {data?.token_price >= 1
                        ? data?.token_price?.toFixed(5)
                        : data?.token_price?.toFixed(5)}
                    </p>
                    <small
                      style={{
                        fontSize: '16px',
                        color: data.priceChange >= 0 ? 'green' : 'red'
                      }}
                    >
                      {data.priceChange >= 0 ? (
                        <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                      ) : (
                        <ArrowDownward sx={{ fontSize: '13px' }} />
                      )}
                      {(data.priceChange / data?.token_price)?.toFixed(5)} %
                    </small>
                  </div>
                  {projectTokens.length &&
                    projectTokens.map((e, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: 'rgb(64, 68, 79)',
                            padding: '4px 6px',
                            borderRadius: '8px',
                            fontWeight: '400',
                            boxSizing: 'border-box',
                            maxWidth: 'fit-content',
                            color: 'white',
                            margin: '1rem ',
                            cursor: 'pointer',
                            minWidth: '200px'
                          }}
                        >
                          <Link
                            to={`/VentureToken/${e?.token_symbol}`}
                            style={{
                              fontSize: '1rem',
                              fontWeight: '500',
                              color: 'white',
                              width: '100%'
                            }}
                            state={{
                              token_price: e?.token_price,
                              symbol: e?.token_symbol
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',

                                width: 'fit-content'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={e?.image}
                                alt={e.tokenSymbol}
                                style={{
                                  backgroundColor: 'orange',
                                  height: '18px',
                                  width: '18px',
                                  fontSize: '18px'
                                }}
                              />
                              <p
                                style={{
                                  color: 'white',
                                  marginLeft: '5px',
                                  fontSize: '18px',
                                  fontWeight: '500',
                                  fontFamily: 'Inter var sans-serif'
                                }}
                              >
                                {e?.token_symbol}
                              </p>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirectino: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  {' '}
                  <IconButton
                    className="dfelxalitemC shadow"
                    sx={{
                      backgroundColor: 'rgb(64, 68, 79)',
                      color: 'rgb(195, 197, 203)',
                      // padding: "8px 14px",
                      borderRadius: '12px',
                      cursor: 'pointer',
                      // width: "170px",
                      marginRight: '1rem',
                      fontWeight: '800',
                      maxHeight: '40px',
                      fontSize: '18px'
                    }}
                  >
                    <GetAppIcon />
                    {/* <p>Add Liquidity</p> */}
                  </IconButton>
                  {/* buy token modal */}
                  <BuyToken each={token} pecuCoins={pecuCoins} user={user} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end'
                }}
              ></div>
            </Grid>
            <Grid item xs={12} md={4} mt={3}>
              <div
                style={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  borderRadius: '20px',
                  height: '100%',
                  padding: '1.5rem',
                  maxWidth: '350px'
                }}
                className="shadowGrey"
              >
                <div style={{ marginBottom: '1rem' }}>
                  <p className="token-page-t1 mb-1">TVL</p>
                  <p className="token-page-t2 mb-1">
                    {' '}
                    $
                    {convertToInternationalCurrencySystem(
                      data?.amount_issued * data?.token_price
                    )}
                  </p>
                </div>
                <div style={{ marginBottom: '1rem' }}>
                  <p className="token-page-t1 mb-1">24h Trading Vol</p>
                  <p className="token-page-t2 mb-1">
                    {convertToInternationalCurrencySystem(0)}
                  </p>
                </div>
                <div style={{ marginBottom: '1rem' }}>
                  <p className="token-page-t1 mb-1">7d Trading Vol</p>
                  <p className="token-page-t2 mb-1">
                    {convertToInternationalCurrencySystem(0)}
                    {/* {convertToInternationalCurrencySystem(
                      (data?.token_price - data?.launch_price) /
                        data?.token_price
                    )} */}
                    {/* {convertToInternationalCurrencySystem(data?.initialFinal)} */}
                  </p>
                </div>

                <div style={{ marginBottom: '1rem' }}>
                  <p className="token-page-t1 mb-1">24h Fees</p>
                  <p
                    className="token-page-t2 mb-1"
                    style={{
                      marginTop: '0.5rem',
                      fontSize: '24px'
                    }}
                  >
                    {/* $
                      {convertToInternationalCurrencySystem(
                        cryptoData?.price?.toFixed(5)
                      )} */}
                    0.00%
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8} sx={{ marginTop: { xs: 8, md: 3 } }}>
              <Box sx={{ height: '100%' }}>
                <div
                  style={{
                    backgroundColor: 'rgb(25, 27, 31)',
                    height: '100%',
                    borderRadius: '20px',
                    padding: '1.5rem'
                  }}
                  className="shadowGrey"
                >
                  {/* <TokenGraph
                    id={token?.id}
                    setTokenPrice={setTokenPrice}
                    symbol={token?.tokenSymbol}
                  /> */}
                  <CoinInfo coin={{ id: null, symbol: token?.tokenSymbol }} />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} mt={5}>
              <div className="token_card-box" style={{ minWidth: '300px' }}>
                <img src={`${token?.image}`} width={'100px'} />
                <br></br>
                <p>Symbol : {token?.token_symbol}</p>
                <br></br>

                <p>Name : {token?.token_name}</p>

                <p style={{ fontSize: '12px', lineHeight: '25px' }}>
                  <h3>Description</h3>
                  {token?.token_desc}
                </p>
                <br></br>
                <p style={{ fontSize: '12px' }}>
                  Price: {token?.launch_price?.toFixed(5)} USD
                </p>
                <br></br>
                <p style={{ fontSize: '14px' }}>
                  Amount: {token?.amount_issued}
                </p>
                <br></br>
                <p style={{ fontSize: '12px' }}>
                  Created on :{' '}
                  {new Date(token?.date_time)?.toLocaleDateString()}{' '}
                  {new Date(token?.date_time)?.toLocaleTimeString()}
                </p>
                <a
                  href={
                    token?.soapboxLink
                      ? token.soapboxLink
                      : `https://www.megahoot.net/explore`
                  }
                  target="_blank"
                >
                  <img
                    src={soapbox_btn}
                    style={{
                      width: '400px',
                      maxWidth: '80%',
                      cursor: 'pointer'
                    }}
                  />
                </a>
              </div>
              <Transactions />
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
              <Alert
                variant="outlined"
                severity={alert.type}
                sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
              >
                {alert.msg}
              </Alert>
            </Collapse>
          </div>
        </Box>
      }
    </>
  );
}
