import React, { useState } from 'react';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  convertToInternationalCurrencySystem,
  fetchHedges,
  logoWrapper
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDownward,
  ArrowUpward,
  StarOutline,
  ArrowForward,
  FiberManualRecord
} from '@mui/icons-material';
// import { MenuData } from '../../components/Tables/menuData';
import { useEffect } from 'react';
import * as actionTypes from '../../store/actionTypes';
import EditToken from '../../screens/editToken';
import PecuGraph from '../../components/Graphs/PecuGraph';
import CommonMenu from '../../components/commonMenu/commonMenu';
import Transactions from '../Transactions/transaction.table';
import TokenChart from '../../components/coinInfo/tokenChart';
import GenRevenue from '../../components/GenRevenue/GenRevenue';
import TokenTable from '../../components/Tables/TokenTable/token.table';
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);

  timestamp.setHours(timestamp.getHours());
  return timestamp;
}
const RedSwapBtn = {
  display: 'flex',
  width: '119px',
  height: '36px',
  padding: '10px 19px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '30px',
  border: '1px solid #F93C3C',
  flexShrink: 0,
  color: '#F93C3C',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '100%',
  marginTop: '-20px'
};
const NormalSwapBtn = {
  display: 'flex',
  width: '119px',
  height: '36px',
  padding: '10px 19px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '30px',
  border: '1px solid #76C8A8',
  flexShrink: 0,
  color: '#76C8A8',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '100%',
  marginTop: '-20px'
};
const HedgeTable = () => {
  const { hedges, loading, isAdmin, PecuPrice } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blinkUpList, setBlinkUpList] = useState([]);
  const [blinkDownList, setBlinkDownList] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState({});
  const [priceList, setPriceList] = useState([]);

  const [searchDerivatives, setSearchDerivatives] = useState('');
  // const [derivativesPage, setDerivativesPage] = useState(0);
  const [page, setPage] = useState(1);
  const disabled = ['USD/JPY'];
  const filterderivatives = (searchDerivatives) => {
    if (searchDerivatives && searchDerivatives !== '') {
      return hedges.tokens?.filter(
        (e) =>
          !disabled.includes(e.symbol) &&
          (e.symbol.toLowerCase().includes(searchDerivatives.toLowerCase()) ||
            e.name.toLowerCase().includes(searchDerivatives.toLowerCase()))
      );
    } else {
      return hedges.tokens?.filter((e) => !disabled.includes(e.symbol));
    }
  };

  useEffect(() => {
    fetchHedges(dispatch, actionTypes.FETCH_HEDGES, page, 10);
    setTimeout(() => {
      setPriceList([]);
    }, 2000);
    const intervalId = setInterval(() => {
      fetchHedges(dispatch, actionTypes.FETCH_HEDGES, page, 10);
    }, 1500);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [page]); // Include 'page' in the dependency array

  useEffect(() => {
    // Compare current prices with the previous prices
    if (priceList?.length > 0) {
      const updatedPriceList = hedges.tokens
        .filter(({ identitySymbol, currentPrice }) => {
          const prevPrice = priceList.find(
            (item) => item.identitySymbol === identitySymbol
          );
          return prevPrice && prevPrice.currentPrice !== currentPrice;
        })
        .map(({ identitySymbol, currentPrice }) => ({
          identitySymbol,
          currentPrice
        }));
      if (updatedPriceList.length > 0) {
        const newBlinkDownList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.identitySymbol === item.identitySymbol
          );
          return prevPrice && item.currentPrice < prevPrice.currentPrice;
        });
        const newBlinkUpList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.identitySymbol === item.identitySymbol
          );
          return prevPrice && item.currentPrice > prevPrice.currentPrice;
        });

        setPriceList((prevPriceList) => [
          ...prevPriceList.filter(
            (item) =>
              !updatedPriceList.some(
                (updatedItem) =>
                  updatedItem.identitySymbol === item.identitySymbol
              )
          ),
          ...updatedPriceList
        ]);

        setBlinkUpList(newBlinkUpList.map((item) => item.identitySymbol));
        setBlinkDownList(newBlinkDownList.map((item) => item.identitySymbol));

        setTimeout(() => {
          setBlinkUpList([]);
          setBlinkDownList([]);
        }, 500);
      }
    } else {
      setPriceList(
        hedges.tokens?.map(({ identitySymbol, currentPrice }) => ({
          identitySymbol,
          currentPrice
        }))
      );
    }
  }, [hedges, priceList]);
  // console.log(derivatives, 'Derivatives');
  return (
    <div
      className="screen"
      style={{
        zIndex: '100',
        position: 'sticky',
        width: '100%',
        marginTop: '4rem',
        marginBottom: '1rem'
      }}
    >
      <PecuGraph />

      <div
        style={{
          width: '95%',
          minWidth: '340px'
        }}
        className="res-token"
      >
        <GenRevenue />
        <CommonMenu label={'HedgeTokens'} setSearch={setSearchDerivatives} />
        <TokenTable
          loading={loading}
          token={filterderivatives(searchDerivatives)}
          blinkUpList={blinkUpList}
          blinkDownList={blinkDownList}
          href={'hedgeTokens'}
          PecuPrice={PecuPrice}
          swappable={true}
          getFormattedTime={getFormattedTime}
          showPecu={false}
        />
        <div className="nextprevcontainer">
          showing
          {`${(page - 1) * 10}-${page * 10}/${hedges.totalPages * 10}`}
          <button
            onClick={() => {
              setPage((prev) => (prev >= 1 ? prev - 1 : prev));
              // setDerivativesPage((prev) => (prev >= 10 ? prev - 10 : prev));
            }}
          >{`<`}</button>
          <button
            onClick={() => {
              setPage((prev) =>
                prev < Math.abs(hedges.totalPages * 10 - 10) ? prev + 1 : prev
              );
              // setDerivativesPage((prev) =>
              //   prev < derivatives.length - 10 ? prev + 10 : prev
              // );
            }}
          >{`>`}</button>
        </div>
        <Transactions />
      </div>
      <EditToken token={selectedToken} open={open} setOpen={setOpen} />
    </div>
  );
};

export default React.memo(HedgeTable);
