import { Edit } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { api as axios } from '../api';
import React, { useEffect, useState } from 'react';
import url from '../serverUrl';
import { logoWrapper } from '../utils';
import Switch from '@mui/material/Switch';
function EditToken({ token, setOpen, open }) {
  const [updatedToken, setUpdatedToken] = useState(token);
  const [logo, setLogo] = useState(updatedToken?.logo);
  const [checked, setChecked] = useState(token?.status);
  useEffect(() => {
    setUpdatedToken(token);
    setLogo(token.logo);
  }, [token]);

  const handleClose = () => {
    setOpen(false);
  };

  const saveToken = () => {
    const bodyData = {
      id: updatedToken.id,
      name: updatedToken.name,
      description: updatedToken.description,
      link: updatedToken.link,
      type: updatedToken.type,
      logo: updatedToken.logo,
      currentPrice: updatedToken.currentPrice,
      status: checked,
      sort: updatedToken.sort,
      symbol: updatedToken.symbol,
      volume: updatedToken.volume
    };

    axios
      .put(`${url}/crypto/updateToken`, bodyData)
      .then((res) => {
        alert(JSON.stringify(res.data));
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });
  };
  const saveFileDerivatives = (e) => {
    const formData = new FormData();
    const pic = e.target.files[0];
    setLogo(URL.createObjectURL(pic));
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setUpdatedToken({
          ...updatedToken,
          logo: `${url}/hootdex/images/${res.data.fileName}`
        });
      }
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setUpdatedToken({ ...updatedToken, [name]: value });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: '100%'
        }
      }}
      sx={{ zIndex: 2002 }}
    >
      <div
        style={{
          backgroundColor: 'whitesmoke',
          padding: '5rem'
        }}
      >
        <div style={{ position: 'relative' }}>
          <img src={logoWrapper(logo, token)} width={150} alt={token?.name} />
          <input
            id="fileInput"
            className="input"
            onChange={saveFileDerivatives}
            type="file"
            placeholder="Enter"
            hidden={true}
          ></input>

          <label
            for="fileInput"
            style={{
              position: 'absolute',
              bottom: '20px',
              cursor: 'pointer'
            }}
          >
            <Edit />
          </label>
        </div>
        <p>
          Status {checked ? 'Active' : 'Inactive'}:
          <Switch
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </p>
        <input
          className="input"
          name={'name'}
          value={updatedToken.name}
          label="Edit"
          onChange={handleChange}
        />
        <input
          className="input"
          name={'symbol'}
          value={updatedToken.symbol}
          onChange={handleChange}
        />
        {token.type === 'STNTHETICS' && (
          <input
            className="input"
            name={'volume'}
            value={updatedToken.volume}
            onChange={handleChange}
          />
        )}
        <input
          className="input"
          name={'identitySymbol'}
          value={updatedToken.identitySymbol}
          disabled
        />
        <input
          className="input"
          name={'link'}
          value={updatedToken.link}
          onChange={handleChange}
          placeholder="link"
        />

        {token.type === 'hedge' && (
          <input
            className="input"
            name={'currentPrice'}
            value={updatedToken.currentPrice}
            onChange={handleChange}
            placeholder="currentPrice"
          />
        )}
        <textarea
          className="input"
          style={{ height: '200px' }}
          name={'description'}
          value={updatedToken.description}
          onChange={handleChange}
        />
        <input
          className="input"
          name={'sort'}
          value={updatedToken.sort}
          onChange={handleChange}
          placeholder="sort"
        />
        <button
          style={{
            backgroundColor: 'darkgreen',
            width: '300px',
            padding: '1rem',
            color: 'white',
            margin: '2rem',
            cursor: 'pointer'
          }}
          onClick={() => {
            saveToken();
          }}
        >
          Save
        </button>
      </div>
    </Dialog>
  );
}

export default EditToken;
