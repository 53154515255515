import React, { useEffect } from 'react';

const RedirectPage = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');

    if (source) {
      const walletData = localStorage.getItem('hootdex_secretcookie_wallet');

      const base64DataWallet = btoa(walletData);
      // const base64DataProfile = btoa(profileData);
      const redirectUrl = `https://app.hootdex.net/auth?validator=${encodeURIComponent(
        base64DataWallet
      )}`;
      window.location.href = redirectUrl;
      // window.location.href = redirectUrl;
    }
  }, []);

  return (
    <div
      style={{
        minHeight: '90vh',
        minWidth: '80vw',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h1>Please wait , Authenticating ...</h1>
    </div>
  );
};

export default RedirectPage;
