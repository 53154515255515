import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CreateAccountForm from './CreateAccountForm';
import FillWalletInfo from './FillWalletInfo';
import CreateToken from './CreateToken';
import Token from './Token';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import url from '../../serverUrl';
import { useSelector } from 'react-redux';

export default function CreateDAT() {
  const { PecuPrice } = useSelector((state) => state);
  const [activeStep, setActiveStep] = useState(0); // Set the initial active step
  const navigate = useNavigate();
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const [account, setAccount] = useState({
    baseInvestor: '',
    description: '',
    email: '',
    firstName: '',
    lastName: '',
    link: '',
    logoRaw: '',
    logo: '',
    password: '',
    pecuAmount: '',
    pecuValue: '',
    price: '',
    symbol: '',
    tokenAmount: '',
    tokenName: ''
  });
  useEffect(() => {
    setAccount((prev) => ({
      ...prev,
      pecuValue: prev.pecuAmount * PecuPrice,
      price: (prev.pecuAmount * PecuPrice) / prev.tokenAmount
    }));
  }, [PecuPrice, account.pecuAmount, account.tokenAmount]);

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setAccount((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const saveLogo = (e) => {
    const formData = new FormData();
    const pic = e.target.files[0];
    let name = 'logoRaw';
    setAccount((prev) => ({
      ...prev,
      [name]: URL.createObjectURL(pic)
    }));
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setAccount((prev) => ({
          ...prev,
          logo: `${url}/hootdex/images/${res.data.fileName}`
        }));
      }
    });
  };

  const submitHandler = () => {
    const payLoad = {
      ...account
    };
    console.log(payLoad);

    axios
      .post(`${url}/dat/create`, payLoad)
      .then((res) => {
        if (res.status === 200) {
          const pdfLink = `${url}/report/` + res.data.pdf;
          alert('DAT created successfully');
          window.open(pdfLink, '_blank');
          navigate('/');
        }
      })
      .catch((err) => console.error(err));
  };

  const steps = [
    {
      title: 'Trading Account Information',
      description: 'Trading Account Information',
      content: (
        <CreateAccountForm
          setActiveStep={setActiveStep}
          onChangeHandler={changeHandler}
          account={account}
          saveLogo={saveLogo}
        />
      )
    },
    {
      title: 'Fill PECU ',
      description: 'A few details Fill PECU',
      content: (
        <FillWalletInfo
          setActiveStep={setActiveStep}
          onChangeHandler={changeHandler}
          account={account}
        />
      )
    },
    {
      title: 'Token Information',
      description: 'A few details based on token information',
      content: (
        <CreateToken
          setActiveStep={setActiveStep}
          onChangeHandler={changeHandler}
          account={account}
          submitHandler={submitHandler}
        />
      )
      // You can render something else for this step if needed.
    }
  ];

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'whitesmoke',
        minHeight: '100vh',
        padding: '1rem',
        marginTop: '1rem'
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel style={{ color: 'white' }}>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps[activeStep].content} {/* Display the content of the active step */}
    </Box>
  );
}
