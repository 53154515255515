import { Avatar, Button, Grid, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Transactions from '../Transactions/transaction.table';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../../components/coinInfo/coinInfo';
import { logoWrapper, priceWrapper } from '../../utils';
import SwapDiv from '../../components/SwapDiv';
import { Helmet } from 'react-helmet-async';
import url from '../../serverUrl';
export default function ForexPage() {
  const { identitySymbol } = useParams() || {};
  // const tokenSymbol = identitySymbol;
  const [token, setToken] = useState({});
  const {
    symbol,
    currentPrice,
    price1hour,
    price24hour,
    // description,
    logo
    // name
  } = token || {};

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (identitySymbol) {
      setLoading(true);
      axios
        .get(`/crypto/get_forex?symbol=${identitySymbol}`)
        .then((res) => {
          const { status, stock } = res.data || { status: false, stock: [] };
          if (status) {
            setToken(stock[0]);
          }
        })
        .catch((err) => {
          console.warn(err);
        })
        .finally(() => setLoading(false));
    }
  }, [identitySymbol]);

  if (loading) {
    return (
      <div>
        <LinearProgress sx={{ backgroundColor: 'grey' }} />
      </div>
    );
  }

  return (
    <>
      {' '}
      <Helmet>
        <title>{`${identitySymbol} ${currentPrice?.toFixed(5) ?? ''}`}</title>
        <link rel="icon" href={logoWrapper(logo)} type="image" />
      </Helmet>
      <Box sx={{ padding: { xs: 1, md: '1rem 4rem' }, marginLeft: { xs: 4 } }}>
        <Grid
          sx={{ marginLeft: '-55px' }}
          container
          spacing={5}
          // padding={{ xs: 0, md: 0 }}
          // mb={1}
        >
          <SwapDiv token={identitySymbol} />
          {/* <Grid item xs={12} md={6}> */}
          {/* <div>
            <Link
              to={`/`}
              style={{ fontSize: '1rem', fontWeight: '500' }}
            >{`Home `}</Link>
            <Link to={`/forex`} style={{ fontSize: '1rem', fontWeight: '500' }}>
              {' >'} FxPairs
            </Link>
            <span
              style={{
                fontSize: '1rem',
                fontWeight: '500',
                color: 'white'
              }}
            >
              {' >'} {tokenSymbol}
            </span>
          </div> */}
          {/* </Grid> */}

          {/* <Grid item xs={12} md={6}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end'
            }}
          ></div>
        </Grid> */}

          <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
            <div
              style={{
                backgroundColor: 'rgb(25, 27, 31)',
                borderRadius: '20px',
                height: '100%',
                padding: '1rem',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  // alignItems: 'center',
                  // margin: '0 0 0 1rem',
                  width: 'fit-content'
                }}
              >
                <Avatar
                  className="rounded"
                  src={logo?.replace('api.hootdex.net', 'dev.hootdex.org')}
                  alt={symbol}
                  style={{
                    height: '45px',
                    width: '45px',
                    fontSize: '18px',
                    alignSelf: 'center'
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10
                  }}
                >
                  <p
                    style={{
                      color: 'white',
                      // marginLeft: '5px',
                      fontSize: '16px',
                      fontWeight: '500'
                      // fontFamily: 'Inter var sans-serif'
                    }}
                  >
                    {identitySymbol}
                  </p>
                  <p style={{ fontSize: '1rem', color: 'grey' }}>
                    {token?.name}
                  </p>
                </div>
              </div>

              <a
                href="https://pecuscan.com/"
                style={{ textDecoration: 'none' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="outlined"
                  color="success"
                  style={{ borderRadius: 50, width: '100%' }}
                >
                  Pecuscan
                </Button>
              </a>
              <br />

              <p style={{ fontSize: '1rem', color: 'grey' }}>
                {token?.identitySymbol} price:
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minWidth: '90vw',
                  width: '100%',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    minWidth: '60%',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontWeight: '400',
                      color: 'white',
                      cursor: 'pointer',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      style={{
                        fontSize: '24px',
                        fontWeight: 500
                      }}
                    >
                      {priceWrapper(currentPrice)}
                    </p>

                    <small
                      style={{ fontSize: '16px', color: 'green' }}
                      className={
                        currentPrice - price1hour > 0
                          ? `twhite green`
                          : `twhite red`
                      }
                      align="left"
                    >
                      {currentPrice - price1hour > 0 ? (
                        <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                      ) : (
                        <ArrowDownward sx={{ fontSize: '13px' }} />
                      )}
                      {(
                        Math.abs((currentPrice - price1hour) / currentPrice) *
                        100
                      ).toFixed(5)}{' '}
                      %
                    </small>
                  </div>
                </div>
              </div>
              {/* <img
              src={logo?.replace('api.hootdex.net', 'dev.hootdex.org')}
              width={'100px'}
              alt={identitySymbol}
            /> */}
              <br />

              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '25px',
                  color: 'grey'
                }}
              >
                <div style={{ color: 'white' }}>Description</div>
                {token?.description}
              </p>
              <br />

              <p style={{ fontSize: '14px' }}>
                Price: {currentPrice?.toLocaleString('en-US')} USD
              </p>

              <p style={{ fontSize: '14px' }}>
                Price 1h: {price1hour?.toLocaleString('en-US')} USD
              </p>

              <p style={{ fontSize: '14px' }}>
                Price 24h: {price24hour?.toLocaleString('en-US')} USD
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sx={{ marginTop: { xs: 4, md: 4 }, order: { xs: 0, md: 1 } }}
          >
            <Box sx={{ height: '100%' }}>
              <Box
                sx={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  height: '100%',
                  borderRadius: '20px',
                  padding: { xs: '0 1rem 0 0', md: '1.5rem' }
                }}
                className="shadowGrey"
              >
                <CoinInfo
                  coin={{
                    id: '',
                    symbol: symbol
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
            <Transactions />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
