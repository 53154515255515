import { api as axios } from '../../api';
import { useEffect, useState } from 'react';
import { HistoricalChart } from './api';
import { Line } from 'react-chartjs-2';
import { CircularProgress } from '@material-ui/core';
import SelectButton from './selectedButton';
import { chartDays, chartDays2 } from './chartDays';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
ChartJS.register(...registerables);
const url = 'https://api.pecunovus.net';
const TokenChart = ({ symbol }) => {
  const [historicData, setHistoricData] = useState([]);

  const [days, setDays] = useState(1);
  const currency = 'USD';
  const [flag, setflag] = useState(false);
  const [loading, setLoading] = useState(false);

  const getChatData = (target) => {
    if (!symbol) {
      return;
    }
    setLoading(true);
    setHistoricData([]);
    const chartUrl =
      symbol === 'PECU'
        ? `${url}/wallet/get_change_chart_coin_hourly`
        : `${url}/wallet/get_change_token_${target}?symbol=${symbol}`;
    axios
      .get(chartUrl)
      .then((res) => {
        setflag(true);
        let hoursData = timeMappers();
        // if (target === 'hourly') {
        //   res.data.forEach((e, i) => {
        //     setHistoricData((old) => [
        //       ...old,
        //       { ...e, chart_date: hoursData[i] }
        //     ]);
        //   });
        // } else {
        //   res.data.forEach((e, i) => {
        //     setHistoricData((old) => [...old, e]);
        //   });
        // }
        if (symbol === 'PECU') {
          res.data.forEach((e, i) => {
            setHistoricData((old) => [
              ...old,
              { chart_date: e.chart_date, Price: e.today_value }
            ]);
          });
        } else {
          setHistoricData(res.data.sort((a, b) => a.chart_date - b.chart_date));
        }
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally((e) => setLoading(false));
  };

  // useEffect(() => {
  //   let hoursData = timeMappers();
  //   if (firstData && firstData.length > 0) {
  //     firstData.forEach((e, i) => {
  //       setHistoricData((old) => [...old, { ...e, chart_date: hoursData[i] }]);
  //     });
  //   }
  // }, [firstData]);

  useEffect(() => {
    getChatData('hourly');
  }, [symbol]);

  const updateChart = (value) => {
    if (value == 1) {
      getChatData('hourly');
    } else if (value == 7) {
      getChatData('weekly');
    } else if (value == 30) {
      getChatData('monthly');
    } else if (value == 90) {
      getChatData('quaterly');
    } else if (value == 365) {
      getChatData('yearly');
    }
  };

  const timeMappers = () => {
    let hoursData = [
      12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23
    ];

    let currentHour = new Date().getHours() + 1;
    let thisD = [];
    for (let i = 0; i < currentHour; i++) {
      thisD.push(hoursData[i]);
    }
    for (let i = 23; i > currentHour; i--) {
      thisD = [hoursData[i], ...thisD];
    }

    return thisD;
    //new data =[-12,-11,-10,-9,-8,-7,6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9]
  };
  function tConvert(time) {
    if (time) {
      time = parseInt(time);
      // If time format correct
      // Remove full string match value

      time =
        time < 12
          ? time + ' : 00 ' + 'AM'
          : time > 12
          ? (time % 12) + ' : 00 ' + 'PM'
          : 12 + ' : 00  '; // Set AM/PM

      //time = time % 12 || 12; // Adjust hours
    }
    return time; // return adjusted time or original string
  }

  return (
    <>
      <ResponsiveContainer
        width={150}
        // height={window.screen.availHeight / 2.2}
        aspect={2.5}
      >
        <AreaChart
          data={historicData}
          style={{ width: '100%' }}
          // margin={{ top: 10, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={1.8}
              />
              <stop
                offset="95%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="chart_date"
            axisLine={false} // Hide the axis line
            tickLine={false} // Hide ticks
            tick={false}
          />
          <YAxis
            hide={true}
            unit="$"
            domain={['auto', 'auto']}
            tickCount={7}
            interval={0}
            tickFormatter={(value) => `${value}`}
          />

          {historicData
            .filter(
              (entry, index) =>
                index % Math.floor(historicData.length / 7) === 0
            )
            .map((entry, index) => (
              <ReferenceLine
                key={`ref-line-${index}`}
                y={entry.volume}
                stroke="#25272C"
                strokeWidth={1}
                strokeDasharray="3 3"
                alwaysShow={true}
              />
            ))}

          <Area
            type="linear"
            dataKey="Price"
            stroke="rgb(118, 200, 168)"
            fillOpacity={1}
            fill="url(#colorUv)"
            style={{
              position: 'absolute',
              zIndex: '100',
              width: '100%',
              fill: 'linear-gradient(180deg, #76C8A8 -21.34%, rgba(31, 32, 35, 0.00) 100%)'
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default TokenChart;
