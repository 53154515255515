import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  adminList,
  convertToInternationalCurrencySystem,
  fetchForex,
  filterTokenListBySearchStr
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDownward,
  ArrowUpward,
  BlindsClosed,
  StarOutline
} from '@mui/icons-material';
import { MenuData } from '../../components/Tables/menuData';
import * as actionTypes from '../../store/actionTypes';
import EditToken from '../../screens/editToken';
import { getFormattedTime } from '../../utils';
import { headerConstants } from './forex.constants';
import SearchIcon from '@mui/icons-material/Search';
import PecuGraph from '../../components/Graphs/PecuGraph';
import CommonMenu from '../../components/commonMenu/commonMenu';
import TokenTable from '../../components/Tables/TokenTable/token.table';

const ForexTable = () => {
  const loading = false;
  const navigate = useNavigate();
  const { forex, UserInfo, PecuPrice } = useSelector((state) => state);
  const [searchForex, setSearchForex] = useState('');
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [isAdmin, setAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState({});

  useEffect(() => {
    if (UserInfo) {
      setAdmin(adminList.includes(UserInfo.email));
    }
  }, [UserInfo]);

  useEffect(() => {
    fetchForex(dispatch, actionTypes.FETCH_FOREX, page, 10);
    const intervalId = setInterval(() => {
      fetchForex(dispatch, actionTypes.FETCH_FOREX, page, 10);
    }, 1500);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, page]);

  return (
    <div
      className="screen"
      style={{
        zIndex: '100',
        position: 'sticky',
        width: '100%',
        marginTop: '4rem'
      }}
    >
      {' '}
      <PecuGraph />
      <div
        style={{
          width: '95%',
          minWidth: '340px'
        }}
        className="res-token"
      >
        <CommonMenu label={'FxPairs'} setSearch={setSearchForex} />

        <TokenTable
          loading={loading}
          token={filterTokenListBySearchStr(forex?.tokens, searchForex)}
          blinkUpList={[]}
          blinkDownList={[]}
          href={'fxPairs'}
          PecuPrice={PecuPrice}
          swappable={false}
          getFormattedTime={getFormattedTime}
          showPecu={false}
        />
        <div
          className="nextprevcontainer"
          style={{
            backgroundColor: '#1C1D20'
          }}
        >
          {' '}
          showing {`${(page - 1) * 10}-${page * 10}/${forex.totalPages * 10}`}
          <button
            onClick={() => {
              setPage((prev) => (prev >= 1 ? prev - 1 : prev));
            }}
          >{`<`}</button>
          <button
            onClick={() => {
              setPage((prev) =>
                prev < Math.abs(forex.totalPages * 10 - 10) ? prev + 1 : prev
              );
            }}
          >{`>`}</button>
        </div>
      </div>
      <EditToken token={selectedToken} open={open} setOpen={setOpen} />
    </div>
  );
};

export default React.memo(ForexTable);
