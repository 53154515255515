export const headerConstants = {
  tableCellNamings: [
    'FAV',
    'NAME',
    'PRICE (USD)',
    '1h %',
    '24h %',
    'VOLUME 24h',
    'TVL',
    'DATE & TIME',
    'MARKET STATS'
  ]
};
