import { api as axios } from '../../api';
import { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
ChartJS.register(...registerables);
const url = 'https://api.pecunovus.net';
const FeeChart = ({ interval }) => {
  const [historicData, setHistoricData] = useState([]);

  const getChatData = () => {
    setHistoricData([]);
    const chartUrl = `${url}/crypto/feesHootdex?interval=${interval}`;
    axios
      .get(chartUrl)
      .then((res) => {
        // console.log(res.data);
        // res.data.forEach((e, i) => {
        //   setHistoricData((old) => [...old, e]);
        // });
        setHistoricData(res.data.fee);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getChatData();
  }, []);

  return (
    <>
      <ResponsiveContainer
        width={150}
        // height={window.screen.availHeight / 2.2}
        aspect={1.5}
      >
        <AreaChart
          data={historicData}
          style={{ width: '100%' }}
          // margin={{ top: 10, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={1.8}
              />
              <stop
                offset="95%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="rgb(118, 200, 168)"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="time"
            axisLine={false} // Hide the axis line
            tickLine={false} // Hide ticks
            tick={false}
          />
          <YAxis
            hide={true}
            unit="$"
            domain={['auto', 'auto']}
            tickCount={7}
            interval={0}
            tickFormatter={(value) => `${value}`}
          />

          {historicData
            ?.filter(
              (entry, index) =>
                index % Math.floor(historicData.length / 7) === 0
            )
            .map((entry, index) => (
              <ReferenceLine
                key={`ref-line-${index}`}
                y={entry.fees}
                stroke="#25272C"
                strokeWidth={1}
                strokeDasharray="3 3"
                alwaysShow={true}
              />
            ))}

          <Area
            type="linear"
            dataKey="fees"
            stroke="rgb(118, 200, 168)"
            fillOpacity={1}
            fill="url(#colorUv)"
            style={{
              position: 'absolute',
              zIndex: '100',
              width: '100%',
              fill: 'linear-gradient(180deg, #76C8A8 -21.34%, rgba(31, 32, 35, 0.00) 100%)'
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default FeeChart;
