import React, { useEffect, useState } from 'react';
import { fetchSynthetics, fetchSyntheticsPrice } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { api as axios } from '../../api';
import * as actionTypes from '../../store/actionTypes';
import TokenTable from '../../components/Tables/TokenTable/token.table';
import EthWallet from '../../components/nav/web3wallet';
const SyntheticTable = ({ searchToken }) => {
  const dispatch = useDispatch();
  const loading = false;
  const { PecuPrice, synthetics, syntheticsPrice, UserInfo, isAdmin } =
    useSelector((state) => state);

  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState({});
  const [tvl, setTvl] = useState(84513557);
  const [priceList, setPriceList] = useState([]);
  const [blinkUpList, setBlinkUpList] = useState([]);
  const [blinkDownList, setBlinkDownList] = useState([]);
  const [pecuPriceTick, setPecuPriceTick] = useState(null);
  const [syntheticsPriceTick, setSyntheticsPrice] = useState(syntheticsPrice);
  const [trendHourly, setTrendHourly] = useState({});
  const [coinVolume, setCoinVolume] = useState(114826.00453596);
  const [trend, setTrend] = useState({});
  const [priceDown, setPriceDown] = useState(false);
  const filterToken = (searchToken) => {
    if (searchToken && searchToken !== '') {
      return synthetics.filter(
        (e) =>
          e.symbol.toLowerCase().includes(searchToken.toLowerCase()) ||
          e.name.toLowerCase().includes(searchToken.toLowerCase())
      );
    } else {
      return synthetics;
    }
  };

  const get_change_index_coin_hourly = () => {
    axios.get(`/wallet/get_change_index_coin_hourly`).then((res) => {
      setTrendHourly(res.data);
    });
  };
  const calculate_daily_volume = () => {
    axios.get(`/wallet/calculate_daily_volume`).then((res) => {
      setCoinVolume(res.data.total_volume);
    });
  };
  const get_tvl = () => {
    axios.get(`/wallet/tvl`).then((res) => {
      setTvl(res.data.tvl);
    });
  };
  const get_change_index_coin = () => {
    axios.get(`/wallet/get_change_index_coin`).then((res) => {
      setTrend(res.data);
    });
  };

  const [pecuBlinkUp, setPecuBlinkUp] = useState(false);
  const [pecuBlinkDown, setPecuBlinkDown] = useState(false);

  useEffect(() => {
    if (pecuPriceTick) {
      if (pecuPriceTick > PecuPrice) {
        setPecuBlinkUp(true);
        setPecuBlinkDown(false);
      }

      if (pecuPriceTick < PecuPrice) {
        setPecuBlinkDown(true);
        setPecuBlinkUp(false);
      }

      if (pecuPriceTick == PecuPrice) {
        setPecuBlinkUp(false);
        setPecuBlinkDown(false);
      }
    }
    setPecuPriceTick(PecuPrice);
    setTimeout(() => {
      setPecuBlinkUp(false);
      setPecuBlinkDown(false);
    }, 500);
  }, [PecuPrice]);

  useEffect(() => {
    get_change_index_coin_hourly();
    get_change_index_coin();
    calculate_daily_volume();
    get_tvl();
  }, []);

  useEffect(() => {
    // Compare current prices with the previous prices
    if (priceList.length > 0) {
      const updatedPriceList = Object.entries(syntheticsPriceTick)
        .filter(([token, price]) => {
          const prevPrice = priceList.find((item) => item.token === token);
          return prevPrice && prevPrice.price !== price;
        })
        .map(([token, price]) => ({ token, price }));

      if (updatedPriceList.length > 0) {
        const newBlinkUpList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.token === item.token
          );
          return prevPrice && item.price > prevPrice.price;
        });

        const newBlinkDownList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.token === item.token
          );
          return prevPrice && item.price < prevPrice.price;
        });

        setPriceList((prevPriceList) => [
          ...prevPriceList.filter(
            (item) =>
              !updatedPriceList.some(
                (updatedItem) => updatedItem.token === item.token
              )
          ),
          ...updatedPriceList
        ]);

        setBlinkUpList(newBlinkUpList.map((item) => item.token));
        setBlinkDownList(newBlinkDownList.map((item) => item.token));

        setTimeout(() => {
          setBlinkUpList([]);
          setBlinkDownList([]);
        }, 500);
      }
    } else {
      setPriceList(
        Object.entries(syntheticsPrice).map(([token, price]) => ({
          token,
          price
        }))
      );
    }
  }, [syntheticsPriceTick, priceList]);

  useEffect(() => {
    fetchSynthetics(dispatch, actionTypes.FETCH_SYNTHETICS);
    fetchSyntheticsPrice(dispatch, actionTypes.FETCH_SYNTHETICS_PRICE);

    const intervalId = setInterval(() => {
      calculate_daily_volume();
      get_change_index_coin();
      get_change_index_coin_hourly();
      fetchSyntheticsPrice(dispatch, actionTypes.FETCH_SYNTHETICS_PRICE);
      // fetchSynthetics(dispatch, actionTypes.FETCH_SYNTHETICS);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setSyntheticsPrice(syntheticsPrice);
  }, [syntheticsPrice, syntheticsPriceTick]);

  function getFormattedTime(timestampString) {
    const timestamp = new Date(timestampString);
    timestamp.setHours(timestamp.getHours());
    return timestamp;
  }

  return (
    <>
      <TokenTable
        loading={loading}
        token={synthetics?.sort((a, b) => a.sort - b.sort)}
        blinkUpList={blinkUpList}
        blinkDownList={blinkDownList}
        href={'SynthCryptos'}
        PecuPrice={PecuPrice}
        swappable={true}
        getFormattedTime={getFormattedTime}
        showPecu={true}
        priceData={syntheticsPrice}
        pecuBlinkUp={pecuBlinkUp}
        pecuBlinkDown={pecuBlinkDown}
      />
    </>
  );
};

export default React.memo(SyntheticTable);
