/* eslint-disable no-useless-concat */
import React, { useEffect } from 'react';
import {
  Avatar,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  ArrowForward,
  FiberManualRecord,
  StarOutline,
  Star
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertToInternationalCurrencySystem,
  fetchPools,
  fetchPoolsStableCoin,
  logoWrapper
} from '../../utils';
import Transactions from '../Transactions/transaction.table';
// import { MenuData } from '../../components/Tables/menuData';
import { useState } from 'react';
import * as actionTypes from '../../store/actionTypes';
import PecuGraph from '../../components/Graphs/PecuGraph';
import CommonMenu from '../../components/commonMenu/commonMenu';
import InfiniteScroll from 'react-infinite-scroll-component';

function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);

  timestamp.setHours(timestamp.getHours() - 1);
  return timestamp;
}
const PoolsTable = () => {
  const loading = false;
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { liquidityPools, liquidityPoolsStableCoin, PecuPrice } = useSelector(
    (state) => state
  );

  const [page, setPage] = useState(1);
  const [searchPools, setSearchPools] = useState('');
  // const [poolsPage, setPoolsPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  const [isSeeAll, setIsSeeAll] = useState(false);

  const filterPools = (searchPools) => {
    if (searchPools && searchPools !== '') {
      return liquidityPools.data.filter(
        (e) =>
          e.project_name?.toLowerCase().includes(searchPools?.toLowerCase()) ||
          e.project_token_symbol
            ?.toLowerCase()
            .includes(searchPools?.toLowerCase()) ||
          e.pecu_symbol?.toLowerCase().includes(searchPools?.toLowerCase())
      );
    } else {
      return liquidityPools.data;
    }
  };

  useEffect(() => {
    fetchPools(dispatch, actionTypes.FETCH_LIQUIDITY_POOLS, page, itemsPerPage);
    fetchPoolsStableCoin(
      dispatch,
      actionTypes.FETCH_LIQUIDITY_POOLS_STABLE_COIN,
      page,
      20
    );
    const intervalId = setInterval(() => {
      fetchPools(
        dispatch,
        actionTypes.FETCH_LIQUIDITY_POOLS,
        page,
        itemsPerPage
      );
      fetchPoolsStableCoin(
        dispatch,
        actionTypes.FETCH_LIQUIDITY_POOLS_STABLE_COIN,
        page,
        20
      );
    }, 10000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [
    // page,
    itemsPerPage
  ]);

  // fetch more fx for infinite scroll
  const fetchMoreData = async () => {
    setItemsPerPage(itemsPerPage + 4);
  };

  return (
    <>
      <div
        className="screen"
        style={{
          zIndex: '100',
          position: 'sticky',
          width: '100%',
          marginBottom: '1rem',
          marginTop: '4rem'
        }}
      >
        <PecuGraph />
        <div
          style={{
            width: '95%',
            minWidth: '340px'
          }}
          className="res-token"
        >
          <CommonMenu label={'Pools'} setSearch={setSearchPools} />

          <TableContainer
            sx={{
              backgroundColor: '#1C1D20',
              mt: 2,
              borderRadius: '0.5rem',
              minHeight: '80vh'
            }}
            component={Paper}
          >
            {loading && <LinearProgress color="inherit" />}
            {liquidityPoolsStableCoin?.length > 0 && (
              <div
                id="scrollableDiv"
                style={{
                  height: 'fit-content',
                  overflow: 'auto',
                  width: '100%'
                }}
              >
                {/* <InfiniteScroll
                  dataLength={liquidityPoolsStableCoin.length}
                  next={fetchMoreData}
                  hasMore={true}
                  loader={
                    <LinearProgress color="inherit" style={{ padding: 10 }} />
                  }
                  endMessage={
                    <p style={{ textAlign: 'center', color: 'grey' }}>
                      <b>You have seen all tokens</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                > */}

                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: ' 1px solid #1e2128'
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <TableHead>
                    <TableRow>
                      {/* table headers  */}
                      {[
                        'FAV',
                        'NAME',
                        'VOLUME 24h',
                        'TVL(USD)',
                        'DATE & TIME'
                      ].map((header, index) => {
                        return (
                          <TableCell style={{ color: 'white' }} key={index}>
                            {header}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {liquidityPoolsStableCoin?.map((each, index) => {
                      const {
                        id,
                        stable_coin_symbol,
                        stable_coin_amount,
                        pledge_coins,
                        created_by,
                        name,
                        tokens,
                        tvl
                      } = each || {};

                      return (
                        <TableRow key={id}>
                          <TableCell
                            style={{ color: 'grey', display: 'flex' }}
                            align="left"
                          >
                            <StarOutline fontSize="small" />
                          </TableCell>

                          <TableCell style={{ color: 'grey' }} align="left">
                            <Link
                              to={`/pools/stablecoin/${id}`}
                              pecuCoins={PecuPrice}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {/* {holding_token_image && (
                                    <Avatar
                                      className="rounded"
                                      src={logoWrapper(holding_token_image)}
                                      alt="token logo"
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )}
                                  {project_token_symbol && (
                                    <Avatar
                                      className="rounded"
                                      src={`https://dev.hootdex.org/${img}`}
                                      alt="token logo"
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )}

                                  {each?.wrapToken_img && (
                                    <Avatar
                                      className="rounded"
                                      src={logoWrapper(wrapToken_img)}
                                      alt={wrap_token_symbol}
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )} */}

                                <Avatar
                                  className="rounded"
                                  src={`https://api.pecunovus.net/hootdex/images/image-1719842511599.png`}
                                  alt="token logo"
                                  style={{ width: '20px', height: '20px' }}
                                />
                                {tokens?.map(
                                  ({ logo, identitySymbol, symbol }, i) => (
                                    <Avatar
                                      key={i}
                                      className="rounded"
                                      src={logo}
                                      alt={identitySymbol}
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )
                                )}
                                <span
                                  style={{
                                    marginLeft: '1rem',
                                    fontSize: '13px',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {name}
                                  <small style={{ color: '#696c75' }}>
                                    {` (${stable_coin_symbol}${pledge_coins?.map(
                                      (e) => {
                                        return `/${e.symbol}`;
                                      }
                                    )})`}
                                  </small>
                                </span>
                              </div>
                            </Link>
                          </TableCell>

                          <TableCell style={{ color: 'grey' }} align="left">
                            {'0.00'}
                          </TableCell>

                          <TableCell style={{ color: 'grey' }} align="left">
                            $ {convertToInternationalCurrencySystem(tvl)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              color: 'grey'
                            }}
                          >
                            <p style={{ fontSize: '12px', fontWeight: 300 }}>
                              {getFormattedTime(each.created_at).toUTCString()}
                            </p>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {/* </InfiniteScroll> */}
              </div>
            )}
            {liquidityPools?.data?.length ? (
              <div
                id="scrollableDiv"
                style={{
                  height: 500,
                  overflow: 'auto',
                  width: '100%'
                }}
              >
                <InfiniteScroll
                  dataLength={liquidityPools.data.length}
                  next={fetchMoreData}
                  hasMore={true}
                  loader={
                    <LinearProgress color="inherit" style={{ padding: 10 }} />
                  }
                  endMessage={
                    <p style={{ textAlign: 'center', color: 'grey' }}>
                      <b>You have seen all tokens</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: ' 1px solid #1e2128'
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <TableHead>
                      <TableRow>
                        {/* table headers  */}
                        {[
                          'FAV',
                          'NAME',
                          'VOLUME 24h',
                          'TVL(USD)',
                          'DATE & TIME'
                        ].map((header, index) => {
                          return (
                            <TableCell style={{ color: 'white' }} key={index}>
                              {header}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filterPools(searchPools)?.map((each, index) => {
                        const {
                          id,
                          img,
                          public_key,
                          holding_token_image,
                          holding_token_symbol,
                          wrapToken_img,
                          wrap_token_symbol,
                          project_name,
                          project_token_symbol,
                          volume,
                          pecuCoin,
                          tvl,
                          timestamp
                        } = each || {};

                        // const holding_token_symbol_logo =
                        //   holding_token_image.split('/');
                        // const holding_token_logo =
                        //   holding_token_symbol_logo[
                        //     holding_token_symbol_logo.length - 1
                        //   ];
                        return (
                          <TableRow key={id}>
                            <TableCell
                              style={{ color: 'grey', display: 'flex' }}
                              align="left"
                            >
                              <StarOutline fontSize="small" />
                            </TableCell>

                            <TableCell style={{ color: 'grey' }} align="left">
                              <Link
                                to={`/pools/${public_key}`}
                                pecuCoins={PecuPrice}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {holding_token_image && (
                                    <Avatar
                                      className="rounded"
                                      src={logoWrapper(holding_token_image)}
                                      alt="token logo"
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )}
                                  {project_token_symbol && (
                                    <Avatar
                                      className="rounded"
                                      src={`https://dev.hootdex.org/${img}`}
                                      alt="token logo"
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )}

                                  {each?.wrapToken_img && (
                                    <Avatar
                                      className="rounded"
                                      src={logoWrapper(wrapToken_img)}
                                      alt={wrap_token_symbol}
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  )}
                                  <Avatar
                                    className="rounded"
                                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                                    alt="token logo"
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                  <span
                                    style={{
                                      marginLeft: '1rem',
                                      fontSize: '13px',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {project_name}
                                    <small style={{ color: '#696c75' }}>
                                      (
                                      {`${
                                        holding_token_symbol
                                          ? holding_token_symbol
                                          : ''
                                      }${
                                        project_token_symbol !== null &&
                                        project_token_symbol !== ''
                                          ? '/' + project_token_symbol
                                          : ''
                                      }${
                                        wrap_token_symbol !== null &&
                                        project_token_symbol !== ''
                                          ? `/${wrap_token_symbol}`
                                          : ''
                                      }/PECU`}
                                      )
                                    </small>
                                  </span>
                                </div>
                              </Link>
                            </TableCell>

                            <TableCell style={{ color: 'grey' }} align="left">
                              {volume
                                ? convertToInternationalCurrencySystem(
                                    (volume / pecuCoin) * PecuPrice
                                  )
                                : '0.00'}
                            </TableCell>

                            <TableCell style={{ color: 'grey' }} align="left">
                              $ {convertToInternationalCurrencySystem(tvl)}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: 'grey'
                              }}
                            >
                              <p style={{ fontSize: '12px', fontWeight: 300 }}>
                                {getFormattedTime(timestamp).toUTCString()}
                              </p>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </div>
            ) : (
              <Skeleton
                sx={{ bgcolor: '#21242b', mt: 1, mb: 4 }}
                variant="rectangular"
                margin="1rem"
                height={100}
              />
            )}
          </TableContainer>
          {/* <div className="nextprevcontainer">
            
            showing
            {`${(page - 1) * 10}-${page * 10}/${liquidityPools.totalPages * 10
              }`}
            <button
              onClick={() => {
                setPage((prev) => (prev >= 1 ? prev - 1 : prev));
                setPoolsPage((prev) => (prev >= 10 ? prev - 10 : prev));
              }}
            >{`<`}</button>
            <button
              onClick={() => {
                setPage((prev) =>
                  prev < Math.abs(liquidityPools.totalPages * 10 - 10)
                    ? prev + 1
                    : prev
                );
                setPoolsPage((prev) =>
                  prev < liquidityPools.length - 10 ? prev + 10 : prev
                );
              }}
            >{`>`}</button>
          </div> */}

          {/* see all token button */}
          <div
            style={{
              justifyContent: 'center',
              color: '#76C8A8',
              fontWeight: 'bold',
              display: 'flex',
              marginBottom: '6rem',
              cursor: 'pointer'
            }}
            onClick={() => {
              fetchMoreData();
              setIsSeeAll(true);
            }}
          >
            {isSeeAll ? null : (
              <>
                <span style={{ textDecoration: 'underline', paddingRight: 10 }}>
                  See All Tokens
                </span>
                <FiberManualRecord
                  fontSize="1rem"
                  style={{ alignSelf: 'center', marginRight: '-4px' }}
                />
                <ArrowForward />
              </>
            )}
          </div>

          <Transactions />
        </div>
      </div>
    </>
  );
};

export default PoolsTable;
