import React, { useEffect, useState } from 'react';
import {
  Avatar,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  convertToInternationalCurrencySystem,
  fetchWrapTokens,
  logoWrapper
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownward, ArrowUpward, StarOutline } from '@mui/icons-material';
import { api as axios } from '../../api';
// import { MenuData } from '../../components/Tables/menuData';
import url from '../../serverUrl';
import * as actionTypes from '../../store/actionTypes';
import PecuGraph from '../../components/Graphs/PecuGraph';
import CommonMenu from '../../components/commonMenu/commonMenu';
import Transactions from '../Transactions/transaction.table';
import TokenTable from '../../components/Tables/TokenTable/token.table';
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);
  timestamp.setHours(timestamp.getHours() - 1);
  return timestamp;
}
const WrapTable = () => {
  const loading = false;
  const { wrapTokens, PecuPrice } = useSelector((state) => state);
  const [searchWraps, setSearchWraps] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    fetchWrapTokens(dispatch, actionTypes.FETCH_WRAP_TOKENS);
    const intervalId = setInterval(() => {
      fetchWrapTokens(dispatch, actionTypes.FETCH_WRAP_TOKENS);
    }, 1500);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const [trendHourly, setTrendHourly] = useState({});
  const [coinVolume, setCoinVolume] = useState(114826.00453596);
  const [trend, setTrend] = useState({});
  // const [tokensPage, setTokensPage] = useState(0);
  const filterToken = (searchToken) => {
    if (searchToken && searchToken !== '') {
      return wrapTokens.filter(
        (e) =>
          e.symbol.toLowerCase().includes(searchToken.toLowerCase()) ||
          e.name.toLowerCase().includes(searchToken.toLowerCase())
      );
    } else {
      return wrapTokens;
    }
  };
  // const navigate = useNavigate();

  const get_change_index_coin_hourly = () => {
    axios.get(`${url}/wallet/get_change_index_coin_hourly`).then((res) => {
      setTrendHourly(res.data);
    });
  };
  const get_change_index_coin = () => {
    axios.get(`${url}/wallet/get_change_index_coin`).then((res) => {
      setTrend(res.data);
    });
  };

  const calculate_daily_volume = () => {
    axios.get(`${url}/wallet/calculate_daily_volume`).then((res) => {
      setCoinVolume(res.data.total_volume);
    });
  };
  useEffect(() => {
    get_change_index_coin_hourly();
    get_change_index_coin();
    calculate_daily_volume();
  }, []);
  return (
    <>
      <div
        className="screen"
        style={{
          zIndex: '100',
          position: 'sticky',
          width: '100%',
          marginBottom: '1rem',
          marginTop: '4rem'
        }}
      >
        <PecuGraph />
        <div
          style={{
            width: '95%',
            minWidth: '340px'
          }}
          className="res-token"
        >
          <CommonMenu label={'Wraps'} setSearch={setSearchWraps} />

          <TokenTable
            loading={loading}
            token={wrapTokens}
            blinkUpList={[]}
            blinkDownList={[]}
            href={'tokens'}
            PecuPrice={PecuPrice}
            swappable={false}
            getFormattedTime={getFormattedTime}
            showPecu={false}
          />
          {/* <div className="nextprevcontainer">
        {' '}
        showing {`${tokensPage}-${tokensPage + 10}/${synthetics.length}`}
        <button
          onClick={() => {
            setTokensPage((prev) => (prev >= 10 ? prev - 10 : prev));
          }}
        >{`<`}</button>
        <button
          onClick={() => {
            setTokensPage((prev) =>
              prev < synthetics.length - 10 ? prev + 10 : prev
            );
          }}
        >{`>`}</button>
      </div> */}

          <Transactions />
        </div>
      </div>
    </>
  );
};

export default React.memo(WrapTable);
