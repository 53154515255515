import './App.css';
import Home from './screens/home';
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './screens/login';
import { useCallback, useEffect, useState } from 'react';
import DashboardIndex from './screens/DashboardIndex';
import PoolPage from './modules/Pools/pools.page';
import { api as axios } from './api';
import url from './serverUrl';
import CreatePool from './screens/CreatePool';
import ProjectToken from './modules/Projects/project.page';
import HoldingTokenPage from './screens/holdingTokenPage';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from './store/actionTypes';
import {
  fetchProjectTokens,
  fetchWrapTokens,
  fecthHoldingTokens,
  fetchCommodity,
  fetchDerivatives,
  fetchSynthetics,
  fetchTokenList,
  verifyToken
} from './utils';
import StockPage from './modules/Stocks/stocks.page';
import Derivatives from './components/Tables/Exchange/Derivatives';
import ForexPage from './modules/Forex/forex.page';
import News from './screens/news';
import Commodity from './components/Tables/commodity';
import CommodityPage from './screens/commodityPAge';
import SyntheticPage from './modules/Synthetics/synthetics.page';
import PoolTokensUpdate from './components/Tables/editpools';
import DelistedToken from './components/Tables/DelistedToken';
import ExchangeNav from './components/nav/exchangeNav';
import RedirectPage from './authExchange';
import AuthReader from './authReader';
import PoolsTable from './modules/Pools/pools.table';
import ForexTable from './modules/Forex/forex.table';
import CreateDAT from './modules/DAT/createDAT';
import DatTable from './modules/DAT/dat.table';
import DatPage from './modules/DAT/dat.page';
import StocksTable from './modules/Stocks/stocks.table';
import ProjectTable from './modules/Projects/project.table';
import CryptopairTable from './modules/CryptoPair/cryptopair.table';
import CryptoPairPage from './modules/CryptoPair/cryptopair.page';
import WrapTable from './modules/Wraps/wrap.table';
import WrapPage from './modules/Wraps/wrap.page';
import PecuPage from './modules/Pecu/pecu.page';
import { HelmetProvider } from 'react-helmet-async';
import CreateHedge from './modules/hedge/createHedge';
import HedgeTable from './modules/hedge/hedgeTable';
import HedgePage from './modules/hedge/hedge.page';
import FanTable from './modules/Projects/fan.table';
import FanToken from './modules/Projects/fan.page';
import StablecoinsTable from './modules/stablecoins/stablecoins.table';
import CreateTokensv1 from './screens/createTokensv1';
import StableCoinPage from './modules/stablecoins/stablecoin.page';
import StableCoinPoolPage from './modules/Pools/pools.page.stable.coin';
function App() {
  const { UserInfo, WalletInfo, isAdmin } = useSelector((state) => state);
  const [pecuCoins, setPecuCoins] = useState({});
  const dispatch = useDispatch();

  const fetchWallet = useCallback(async () => {
    if (WalletInfo && WalletInfo.userFound) {
      try {
        const response = await axios.get(
          `${url}/hootdex/getMycoins/${WalletInfo.uid}`
        );
        setPecuCoins(response.data[0]);
      } catch (error) {
        console.error('Error fetching wallet:', error);
      }
    }
  }, [WalletInfo]);

  useEffect(() => {
    if (WalletInfo && WalletInfo.userFound) {
      fetchWallet();
    }
  }, [WalletInfo, fetchWallet]);

  const PecuPriceHttpRequest = () => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        const price = res.data[0].value;
        dispatch({ type: actionTypes.PECU_PRICE, price: price });
      })
      .catch((err) => console.log(err));
  };

  const get_crypto_Data = useCallback(() => {
    axios.get(`${url}/crypto/index`).then((res) => {
      dispatch({ type: actionTypes.CRYPTO_DATA, cryptoData: res.data });
    });
  }, [dispatch]);

  useEffect(() => {
    PecuPriceHttpRequest();
    get_crypto_Data();
    const intervalId = setInterval(() => {
      PecuPriceHttpRequest();
      get_crypto_Data();
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const isExhange = process.env.REACT_APP_IS_EXCHANGE === 'true';
  const isHootdex = !isExhange;

  useEffect(() => {
    if (isExhange) {
      // fetchWrapTokens(dispatch, actionTypes.FETCH_WRAP_TOKENS);
      // fetchCommodity(dispatch, actionTypes.FETCH_COMMODITY);
      // fetchSynthetics(dispatch, actionTypes.FETCH_SYNTHETICS);
      // fetchDerivatives(dispatch, actionTypes.FETCH_DERIVATIVES, 1, 160);
      // fetchProjectTokens(dispatch, actionTypes.FETCH_PROJECT_TOKENS);
      // fecthHoldingTokens(dispatch, actionTypes.FETCH_HOLDING_TOKENS);
      fetchTokenList(dispatch, actionTypes.FETCH_TOKEN_LIST);
    }
  }, [dispatch, isExhange]);
  useEffect(() => {
    const walletExist = localStorage.getItem('hootdex_secretcookie_wallet');
    const userExist = localStorage.getItem('hootdex_secretcookie');
    if (walletExist && walletExist !== null && walletExist !== 'null') {
      if (verifyToken()) {
        const walletData = JSON.parse(walletExist);
        dispatch({ type: actionTypes.WALLET_INFO, WalletInfo: walletData });
      } else {
        alert(
          'Your authorization token has expired ,please reconnect your wallet'
        );
        localStorage.removeItem('hootdex_secretcookie_wallet');
      }
    }

    if (userExist && userExist !== null && userExist !== 'null') {
      const userData = JSON.parse(userExist);
      const admin = userData.tier === 0;
      dispatch({ type: actionTypes.USER_INFO, UserInfo: userData });
      dispatch({ type: actionTypes.IS_ADMIN, isAdmin: admin });
    }
  }, []);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Nav wallet={WalletInfo} fetchWallet={fetchWallet} />
        <Routes>
          {isAdmin && <Route path="/create-pool" element={<CreatePool />} />}
          {isAdmin && <Route path="/createDBT" element={<CreateDAT />} />}
          {isAdmin && (
            <Route path="/createTokensv1" element={<CreateTokensv1 />} />
          )}
          <Route
            path="/Z3JlYXRlcnllc3RlcmRheXRoZWVzdG9wc3RhdGlvbnRyaWVkZm9nZXZpZGVuY2VzbWU=/createTokensv1"
            element={<CreateTokensv1 />}
          />
          {isAdmin && <Route path="/createHedge" element={<CreateHedge />} />}

          <Route path="/" element={<Home />} />

          <Route path="/Tokens" element={<Home />} />
          <Route path="/Pecu" element={<PecuPage />} />
          <Route path="/AllPools" element={<PoolsTable />} />

          <Route
            path="/pools/:id"
            element={<PoolPage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/pools/stablecoin/:id"
            element={
              <StableCoinPoolPage user={UserInfo} pecuCoins={pecuCoins} />
            }
          />

          <Route
            path="updatePoolsAdmin"
            element={<PoolTokensUpdate user={UserInfo} pecuCoins={pecuCoins} />}
          />

          <Route
            path="/tokens/:tokenSymbol"
            element={<WrapPage user={UserInfo} pecuCoins={pecuCoins} />}
          />

          <Route path="/VentureToken" element={<ProjectTable />} />
          <Route path="/FanTokens" element={<FanTable />} />

          <Route
            path="/DelistedToken"
            element={<DelistedToken user={UserInfo} pecuCoins={pecuCoins} />}
          />

          {/* <Route
              path="/stock/:symbol"
              element={<StockPage user={UserInfo} pecuCoins={pecuCoins} />}
            /> */}
          <Route
            path="/fxPairs/:identitySymbol"
            element={<ForexPage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/ccpairs/:symbol/:pair"
            element={<CommodityPage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/holding-token/:tokenSymbol"
            element={<HoldingTokenPage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/VentureToken/:tokenSymbol"
            element={<ProjectToken user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/FanToken/:tokenSymbol"
            element={<FanToken user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/SynthCryptos/:identitySymbol"
            element={<SyntheticPage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/StableCoin/:identitySymbol"
            element={<StableCoinPage user={UserInfo} pecuCoins={pecuCoins} />}
          />

          <Route
            path="/cryptoPairs/:identitySymbol"
            element={<CryptoPairPage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route
            path="/dbt/:identitySymbol"
            element={<DatPage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          {/* <Route
              path="/Stocks"
              element={<StocksTable user={UserInfo} pecuCoins={pecuCoins} />}
            /> */}
          <Route path="/News" element={<News />} />
          <Route path="/fxPairs" element={<ForexTable />} />

          <Route path="/cryptoPairs" element={<CryptopairTable />} />
          <Route path="/stableCoins" element={<StablecoinsTable />} />

          <Route path="/dbt" element={<DatTable />} />
          <Route path="/hedgeTokens" element={<HedgeTable />} />
          <Route
            path="/hedgeTokens/:identitySymbol"
            element={<HedgePage user={UserInfo} pecuCoins={pecuCoins} />}
          />
          <Route path="/ccpairs" element={<Commodity />} />
          <Route path="/pools" element={<PoolsTable />} />
          <Route path="/wrapTokens" element={<WrapTable />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              UserInfo && UserInfo.loggedIn ? (
                <DashboardIndex pecuCoins={pecuCoins} />
              ) : (
                <Login />
              )
            }
          />
          <Route path="/RedirectAuth" element={<RedirectPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
