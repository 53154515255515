export const navTabsList = [
  // {
  //   label: 'Get Started',
  //   value: 'https://www.hootdex.com/getting-started/'
  // },
  {
    label: 'Traders',
    value: '#',
    child: [
      {
        label: 'Pro Traders',
        value: 'https://www.hootdex.com/professional-retail-traders/'
      },
      {
        label: 'Institutions',
        value: 'https://www.hootdex.com/institutional-digital-asset-traders/'
      },
      {
        label: 'OTC Desk',
        value: 'https://www.hootdex.com/hootdex-otc-swapping-desk/'
      }
    ]
  },

  {
    label: 'Support',
    value: '#',
    child: [
      {
        label: 'Support',
        value: 'https://support.hootdex.com/'
      },
      {
        label: 'Tokens',
        value: 'https://www.hootdex.com/tokens/'
      }
    ]
  },
  {
    label: 'Education',
    value: '#',
    child: [
      {
        label: 'Education Center',
        value: 'https://education.hootdex.com/'
      }
    ]
  },
  {
    label: 'Create Pecu Wallet',
    value: 'https://www.pecunovus.net/signup'
  }

  // {
  //   label: 'MetaMask',
  //   value: '#',
  //   metamask: true,
  //   logo: 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg'
  // }
];
