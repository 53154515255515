import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { convertToInternationalCurrencySystem } from '../../utils';
import { useSelector } from 'react-redux';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { MenuData } from './menuData';
const Commodity = () => {
  const loading = false;
  const { commodities } = useSelector((state) => state);
  const navigate = useNavigate();
  const [searchStr, setSearchstr] = useState('');
  const [searchIndex, setSearchIndex] = useState(0);
  const [indices, setIndices] = useState([]);
  const indicesSymbol = [
    'DJI',
    'SPX',
    'NDX',
    'RUT',
    'SP600',
    'VIX',
    'FTSE',
    'FCHI',
    'GDAXI',
    'HSI',
    'N225'
  ];
  const searchStock = (searchStr) => {
    if (searchStr && searchStr !== '') {
      return commodities.filter(
        (e) =>
          e.symbol.toLowerCase().includes(searchStr.toLowerCase()) ||
          e.name.toLowerCase().includes(searchStr.toLowerCase()) ||
          e.description.toLowerCase().includes(searchStr.toLowerCase())
      );
    } else {
      return commodities.filter((e) => !indicesSymbol.includes(e.symbol));
    }
  };

  useEffect(() => {
    if (indices.length === 0) {
      setIndices(
        commodities.filter((e) => indicesSymbol.includes(e.symbol)).reverse()
      );
    }
  }, [commodities]);

  const moveNext = (indices) => {
    let localData = JSON.parse(JSON.stringify(indices));
    localData.unshift(localData.pop());
    setIndices(localData);

    //setIndices(localIndices);
  };
  const movePrev = (indices) => {
    let localData = JSON.parse(JSON.stringify(indices));
    localData.push(localData.shift());
    setIndices(localData);

    //setIndices(localIndices);
  };

  return (
    <>
      <div
        className="screen"
        style={{
          zIndex: '100',
          position: 'sticky',
          width: '100%',
          marginBottom: '1rem'
        }}
      >
        <div
          style={{
            width: '95%',
            minWidth: '340px'
          }}
        >
          {' '}
          <TableContainer
            sx={{
              backgroundColor: '#1a1b1f',
              mt: 5,
              borderRadius: '1rem',
              minHeight: '80vh',
              width: '100%'
            }}
            component={Paper}
          >
            <div
              style={{
                width: '100%',
                backgroundColor: '#1a1b1f',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div className="pool-btn-container">
                {MenuData.filter((e) => e.label !== 'COMMODITY').map((e, i) => (
                  <Button
                    key={i}
                    className={`${'header-link chart-button pool-btn'}`}
                    onClick={() => {
                      navigate(e.path);
                    }}
                  >
                    {e.label}
                  </Button>
                ))}
              </div>
              <div className="pool-btn-container">
                <input
                  onChange={(e) => setSearchstr(e.target.value)}
                  placeholder="Search"
                  type={'search'}
                />
              </div>
            </div>
            <p
              style={{
                color: 'rgb(195, 197, 203)',
                fontSize: '15px',
                fontWeight: 'bold',
                textAlign: 'left',
                padding: '1rem',
                backgroundColor: '#21242b',
                left: '0px',
                position: 'sticky'
              }}
            >
              CC PAIRS
            </p>
            {loading && <LinearProgress color="inherit" />}
            {!loading ? (
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: ' 1px solid #1e2128'
                  }
                }}
              >
                <TableHead className="">
                  <TableRow className="">
                    <TableCell className="twhite">Symbol</TableCell>
                    <TableCell className="twhite" align="left">
                      Price (USD)
                    </TableCell>

                    <TableCell className="twhite" align="left">
                      Last Change
                    </TableCell>
                    <TableCell className="twhite" align="left">
                      24H Change
                    </TableCell>

                    {/* <TableCell className="twhite" align="left">
                  Volume 24H
                </TableCell>

                <TableCell className="twhite" align="left">
                  TVL
                </TableCell> */}
                    {/* <TableCell className="twhite" align="left">
                      Volume
                    </TableCell> */}
                    <TableCell className="twhite" align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchStock(searchStr)
                    ?.slice(searchIndex, searchIndex + 10)
                    .map((each, index) => (
                      <TableRow key={each.id}>
                        <TableCell className="twhite" align="left">
                          <Link to={`/ccpairs/${each.symbol}`} state={each}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={`${each?.logo.replace(
                                  'api.hootdex.net',
                                  'dev.hootdex.org'
                                )}`}
                                alt={each.symbol}
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  fontSize: '18px'
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: '1rem',
                                  fontSize: '14px',
                                  color: 'grey',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <small
                                  style={{ fontSize: '13px', color: 'orange' }}
                                >
                                  {each.symbol}
                                </small>
                                <small
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '4px',
                                    fontWeight: 400
                                  }}
                                >
                                  {each.name}
                                </small>
                              </span>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell className="twhite" align="left">
                          {' '}
                          {each?.currentPrice >= 1
                            ? (each?.currentPrice)
                                .toFixed(5)
                                .toLocaleString('en-US')
                            : (each?.currentPrice).toFixed(5)}
                        </TableCell>
                        <TableCell
                          className={
                            each.currentPrice - each.price1hour > 0
                              ? `twhite green`
                              : each.currentPrice - each.price1hour < 0
                              ? `twhite red`
                              : 'twhite'
                          }
                          align="left"
                        >
                          {each.currentPrice - each.price1hour > 0 ? (
                            <ArrowUpward sx={{ fontSize: '13px' }} />
                          ) : each.currentPrice - each.price1hour < 0 ? (
                            <ArrowDownward sx={{ fontSize: '13px' }} />
                          ) : null}
                          {(each.currentPrice - each.price1hour)
                            .toFixed(5)
                            .toLocaleString('en-US')}{' '}
                        </TableCell>

                        <TableCell
                          className={
                            each.currentPrice - each.price24hour > 0
                              ? `twhite green`
                              : each.currentPrice - each.price24hour < 0
                              ? `twhite red`
                              : 'twhite'
                          }
                          align="left"
                        >
                          {each.currentPrice - each.price24hour > 0 ? (
                            <ArrowUpward sx={{ fontSize: '13px' }} />
                          ) : each.currentPrice - each.price24hour < 0 ? (
                            <ArrowDownward sx={{ fontSize: '13px' }} />
                          ) : null}
                          {(
                            ((each.currentPrice - each.price24hour) /
                              each.currentPrice) *
                            100
                          )
                            .toFixed(5)
                            .toLocaleString('en-US')}{' '}
                          %
                        </TableCell>
                        {/* <TableCell className="twhite" align="left">
                          {each?.volume?.toLocaleString('en-US')}
                        </TableCell> */}
                        <TableCell
                          className={'twhite'}
                          align="left"
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <p style={{ fontSize: '12px', fontWeight: 300 }}>
                            {new Date(each.timestamp).toLocaleDateString()}
                          </p>
                          <p style={{ fontSize: '12px', fontWeight: 300 }}>
                            {new Date(each.timestamp).toLocaleTimeString()}
                          </p>
                        </TableCell>
                        {/* <TableCell className="twhite pink" align="left">
                    {convertToInternationalCurrencySystem()}
                  </TableCell>
                  <TableCell className="twhite blue" align="left">
                    ${' '}
                  </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <Skeleton
                sx={{ bgcolor: '#21242b', mt: 1 }}
                variant="rectangular"
                margin={'1rem'}
                height={100}
              />
            )}
          </TableContainer>
          <div className="nextprevcontainer">
            {' '}
            showing {`${searchIndex}-${searchIndex + 10}/${commodities.length}`}
            <button
              onClick={() => {
                setSearchIndex((prev) => (prev > 10 ? prev - 10 : prev));
              }}
            >{`<`}</button>
            <button
              onClick={() => {
                setSearchIndex((prev) =>
                  prev < commodities.length - 10 ? prev + 10 : prev
                );
              }}
            >{`>`}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Commodity);
