export const PECU_PRICE = 'PECU_PRICE';
export const CRYPTO_DATA = 'CRYPTO_DATA';
export const FETCH_HOLDING_TOKENS = 'FETCH_HOLDING_TOKENS';
export const FETCH_PROJECT_TOKENS = 'FETCH_PROJECT_TOKENS';
export const FETCH_WRAP_TOKENS = 'FETCH_WRAP_TOKENS';
export const FETCH_LIQUIDITY_POOLS = 'FETCH_LIQUIDITY_POOLS';
export const FETCH_LIQUIDITY_POOLS_STABLE_COIN =
  'FETCH_LIQUIDITY_POOLS_STABLE_COIN';
export const FETCH_STOCKS = `FETCH_STOCKS`;
export const FETCH_FOREX = `FETCH_FOREX`;
export const FETCH_DERIVATIVES = `FETCH_DERIVATIVES`;
export const FETCH_DAT = `FETCH_DAT`;
export const FETCH_COMMODITY = `FETCH_COMMODITY`;
export const FETCH_SYNTHETICS = `FETCH_SYNTHETICS`;
export const FETCH_DELISTED_TOKENS = `FETCH_DELISTED_TOKENS`;
export const FETCH_USER_INFO = `FETCH_USER_INFO`;
export const FETCH_SYNTHETICS_PRICE = `FETCH_SYNTHETICS_PRICE`;
export const WALLET_INFO = `WALLET_INFO`;
export const USER_INFO = `USER_INFO`;
export const IS_ADMIN = `IS_ADMIN`;
export const FETCH_TOKEN_LIST = `FETCH_TOKEN_LIST`;
export const FETCH_HEDGES = `FETCH_HEDGES`;
export const FETCH_FAN_TOKENS = `FETCH_FAN_TOKENS`;
export const FETCH_STABLE_COIN = `FETCH_STABLE_COIN`;
export const FETCH_STABLE_COIN_PRICE = `FETCH_STABLE_COIN_PRICE`;
export const FETCH_DERIVATIVES_PRICE = `FETCH_DERIVATIVES_PRICE`;
