import { Edit } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React, { useState } from 'react';

function CreateAccountForm({
  setActiveStep,
  onChangeHandler,
  account,
  saveLogo
}) {
  const formStyle = {
    width: '600px', // Adjust the width as needed
    margin: '50px auto',
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    minHeight: '500px'
  };

  const inputStyle = {
    marginBottom: '30px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '3px'
  };

  return (
    <div>
      <form style={formStyle} onSubmit={(e) => e.preventDefault()}>
        <h3 style={{ color: 'black', textAlign: 'center' }}>
          Setup Trading Account
        </h3>
        <div
          style={{
            position: 'relative',

            width: '100px'
          }}
        >
          <Avatar
            src={account?.logoRaw}
            style={{ width: '100px', height: '100px' }}
          />
          <input
            id="fileInput"
            className="input"
            onChange={saveLogo}
            type="file"
            placeholder="Enter"
            hidden={true}
          ></input>

          <label
            for="fileInput"
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '0px',
              cursor: 'pointer'
            }}
          >
            <Edit color="black" />
          </label>
        </div>

        <input
          type="text"
          placeholder="First Name"
          name="firstName"
          value={account?.firstName}
          onChange={onChangeHandler}
          style={inputStyle}
        />
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          onChange={onChangeHandler}
          value={account?.lastName}
          style={inputStyle}
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          onChange={onChangeHandler}
          value={account?.email}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={account?.password}
          onChange={onChangeHandler}
          style={inputStyle}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'flex-end'
          }}
        >
          {' '}
          <button
            type="submit"
            style={{
              width: '130px',
              border: '1px solid grey',
              color: 'black',
              padding: '10px',
              margin: '5px',
              borderRadius: '3px',
              cursor: 'pointer'
            }}
            onClick={() => {
              setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
            }}
          >
            Back
          </button>
          <button
            type="submit"
            style={{
              width: '130px',
              backgroundColor: 'orange',
              color: 'white',
              padding: '10px',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer',
              margin: '5px'
            }}
            onClick={() => {
              setActiveStep((prev) => prev + 1);
            }}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateAccountForm;
