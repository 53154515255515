import * as React from 'react';
import Box from '@mui/material/Box';

import CreateToken from './CreateToken';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import url from '../../serverUrl';
import { useSelector } from 'react-redux';

export default function CreateHedge() {
  const { PecuPrice } = useSelector((state) => state);
  const [activeStep, setActiveStep] = useState(0); // Set the initial active step
  const navigate = useNavigate();
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const [account, setAccount] = useState({});

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setAccount((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const saveLogo = (e) => {
    const formData = new FormData();
    const pic = e.target.files[0];
    let name = 'logoRaw';
    setAccount((prev) => ({
      ...prev,
      [name]: URL.createObjectURL(pic)
    }));
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setAccount((prev) => ({
          ...prev,
          logo: `${url}/hootdex/images/${res.data.fileName}`
        }));
      }
    });
  };

  const submitHandler = () => {
    const payLoad = {
      ...account
    };
    console.log(payLoad);

    axios
      .post(`${url}/hedge/create`, payLoad)
      .then((res) => {
        if (res.status === 200) {
          alert('hedge token created successfully');

          navigate('/hedgeTokens');
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'whitesmoke',
        minHeight: '100vh',
        padding: '1rem',
        marginTop: '1rem'
      }}
    >
      {/* Display the content of the active step */}
      <CreateToken
        onChangeHandler={changeHandler}
        account={account}
        saveLogo={saveLogo}
        submitHandler={submitHandler}
      />
    </Box>
  );
}
