/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import {
  Alert,
  Avatar,
  Collapse,
  Grid,
  IconButton,
  LinearProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BuyToken from '../components/Modal/BuyToken';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../serverUrl';
import Transactions from '../modules/Transactions/transaction.table';
import TokenGraph from '../components/Graphs/TokenGraph.js';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../components/coinInfo/coinInfo';
function convertToInternationalCurrencySystem(labelValue) {
  return labelValue > 1 ? labelValue?.toFixed(5) : labelValue?.toFixed(5);
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(5) + 'b'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(5) + 'm'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(5) + 'k'
    : Math.abs(Number(labelValue))
    ? Math.abs(Number(labelValue))?.toFixed(5)
    : '0.00';
}
const removeDuplicatedToken = (allData) => {
  for (let i = 0; i < allData.length; i++) {
    for (let j = i + 1; j < allData.length; j++) {
      if (allData[i].symbol == allData[j].symbol) {
        allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
        allData[i].initialFinal =
          allData[j].initialFinal + allData[i].initialFinal;
        allData = allData.filter((e) => e !== allData[j]);
      }
    }
  }

  for (let i = 0; i < allData.length; i++) {
    for (let j = i + 1; j < allData.length; j++) {
      if (allData[i].symbol == allData[j].symbol) {
        return removeDuplicatedToken(allData);
      }
    }
  }

  return allData;
};
export default function CommodityPage({ pecuCoins, user }) {
  const [data, setData] = useState({});
  const tokenSymbol = useParams().symbol + '/' + useParams().pair;

  const [pools, SetPoolInfo] = useState([]);
  const token = {
    tokenName: data?.baseToken,
    timestamp: data?.date_time,
    firstPrice: data?.firstPrice,
    id: data?.id,
    initialFinal: data?.initialFinal,
    pecuInvestement: data?.pecuInvestement,
    pecuValue: data?.pecuValue,
    public_key: data?.public_key,
    tokenSymbol: data?.symbol,
    image: data?.image,
    ...data
  };
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });
  const [tokenPrice, setTokenPrice] = useState([
    {
      currentPrice: null,
      previousPrice: null
    }
  ]);

  // const get_crypto_Data = () => {
  //   let symbol = tokenSymbol?.slice(1);
  //   axios.get(`${url}/crypto/coin?symbol=${symbol}`).then((res) => {
  //     setCryptoData(res.data.token);
  //   });
  // };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${url}/crypto/get_commodity?symbol=${tokenSymbol}`)
      .then((res) => {
        if (res.data.status) {
          const token = res.data.stock;

          setData(token[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [tokenSymbol]);

  return (
    <>
      {loading && (
        <div>
          <LinearProgress sx={{ backgroundColor: 'grey' }} />
        </div>
      )}
      {!loading && (
        <Box sx={{ padding: { xs: 1, md: '1rem 4rem' } }}>
          <Grid
            width={'100%'}
            container
            spacing={1}
            padding={{ xs: 0, md: 0 }}
            mb={1}
          >
            <Grid item xs={12} md={6}>
              <div>
                <Link
                  to={`/`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >{`Home `}</Link>
                <Link
                  to={`/forex`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >
                  {' >'} Forex
                </Link>
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                >
                  {' >'} {tokenSymbol}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '2rem 0 0 1rem',
                  width: 'fit-content'
                }}
              >
                <Avatar
                  className="rounded"
                  src={`${token?.logo?.replace(
                    'api.hootdex.net',
                    'dev.hootdex.org'
                  )}`}
                  alt={token.symbol}
                  style={{
                    height: '45px',
                    width: '45px',
                    fontSize: '18px'
                  }}
                />
                <p
                  style={{
                    color: 'white',
                    marginLeft: '5px',
                    fontSize: '24px',
                    fontWeight: '500',
                    fontFamily: 'Inter var sans-serif'
                  }}
                >
                  {token?.symbol}
                </p>
              </div>
              <div
                style={{
                  marginTop: '0.5rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minWidth: '90vw',
                  width: '100%',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    minWidth: '60%',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontWeight: '400',
                      color: 'white',
                      margin: '0.5rem ',
                      cursor: 'pointer',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      style={{
                        fontSize: '24px',
                        fontWeight: 500,
                        marginLeft: '5px'
                      }}
                    >
                      $
                      {convertToInternationalCurrencySystem(data?.currentPrice)}
                    </p>

                    <small
                      style={{ fontSize: '16px', color: 'green' }}
                      className={
                        data.currentPrice - data.price1hour > 0
                          ? `twhite green`
                          : `twhite red`
                      }
                      align="left"
                    >
                      {data.currentPrice - data.price1hour > 0 ? (
                        <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                      ) : (
                        <ArrowDownward sx={{ fontSize: '13px' }} />
                      )}
                      {(
                        Math.abs(
                          (data.price1hour - data.currentPrice) /
                            data.price1hour
                        ) * 100
                      ).toFixed(5)}{' '}
                      %
                    </small>
                  </div>
                  {/* {token?.otherToken && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              backgroundColor: 'rgb(64, 68, 79)',
                              padding: '4px 6px',
                              borderRadius: '8px',
                              fontWeight: '400',
                              boxSizing: 'border-box',
                              maxWidth: 'fit-content',
                              color: 'white',
                              margin: '1rem ',
                              cursor: 'pointer',
                              minWidth: '200px'
                            }}
                          >
                            <Avatar
                              className="rounded"
                              src={`hfj`}
                              alt={token?.otherToken}
                              style={{
                                width: '22px',
                                height: '22px',
                                color: 'rgb(86, 90, 105)',
                                backgroundColor: 'orange'
                              }}
                            />
                            <p
                              style={{
                                fontSize: '16px',
                                fontWeight: 500,
                                marginLeft: '5px'
                              }}
                            >
                              1 {token?.otherToken} ={' '}
                              {convertToInternationalCurrencySystem(
                                cryptoData?.price
                              )}{' '}
                              USD
                            </p>
                          </div>
                        )} */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end'
                }}
              ></div>
            </Grid>
            <Grid item xs={12} md={4} mt={3}>
              <div
                style={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  borderRadius: '20px',
                  height: '100%',
                  padding: '1.5rem',
                  maxWidth: '350px',
                  color: 'white'
                }}
              >
                <div>
                  <img
                    src={`${`${token?.logo.replace(
                      'api.hootdex.net',
                      'dev.hootdex.org'
                    )}`}`}
                    width={'100px'}
                  />
                  <br></br>
                  <p>Symbol : {token?.symbol}</p>
                  <br></br>

                  <p>Name : {token?.name}</p>

                  <p style={{ fontSize: '12px', lineHeight: '25px' }}>
                    <h3>Description</h3>
                    {token?.description}
                  </p>
                  <br></br>
                  <p style={{ fontSize: '14px' }}>
                    Price: {token?.currentPrice?.toLocaleString('en-US')} USD
                  </p>
                  <br></br>
                  <p style={{ fontSize: '14px' }}>
                    Price 1h: {token?.price1hour?.toLocaleString('en-US')} USD
                  </p>
                  <br></br>
                  <p style={{ fontSize: '14px' }}>
                    Price 24h: {token?.price24hour?.toLocaleString('en-US')} USD
                  </p>
                  <br></br>
                  <p style={{ fontSize: '14px' }}>
                    Price 7d: {token?.price7days?.toLocaleString('en-US')} USD
                  </p>
                  <br></br>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8} sx={{ marginTop: { xs: 8, md: 3 } }}>
              <Box sx={{ height: '100%' }}>
                <div
                  style={{
                    backgroundColor: 'rgb(25, 27, 31)',
                    height: '100%',
                    borderRadius: '20px',
                    padding: '1.5rem'
                  }}
                  className="shadowGrey"
                >
                  <CoinInfo
                    coin={{
                      id:
                        token.cryptoSymbol === 'BTC'
                          ? 'bitcoin'
                          : token?.cryptoSymbol == 'ETH'
                          ? 'ethereum'
                          : token?.cryptoSymbol == 'USDT'
                          ? 'tether'
                          : '',
                      symbol: token?.symbol
                    }}
                  />
                  {console.log(token)}
                  {/* <TokenGraph id={token?.id} setTokenPrice={setTokenPrice} /> */}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} mt={5}>
              {/* <TopToken /> */}
              <Transactions />
            </Grid>
          </Grid>
          {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
                <Alert
                  variant="outlined"
                  severity={alert.type}
                  sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
                >
                  {alert.msg}
                </Alert>
              </Collapse>
            </div> */}
        </Box>
      )}
    </>
  );
}
