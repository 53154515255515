export const ToolTipStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingTop: '20px',
  width: '200px',
  height: '250px',
  color: 'green',
  backgroundRepeat: 'no-repeat',
  backgroundImage:
    "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='199' height='145' viewBox='0 0 199 145' fill='none'><path d='M12.2578 14.5664C12.2578 11.2527 14.9441 8.56641 18.2578 8.56641H180.886C184.2 8.56641 186.886 11.2527 186.886 14.5664V110.011C186.886 113.325 184.2 116.011 180.886 116.011H111.928C110.341 116.011 108.851 116.776 107.926 118.066L101.116 127.566C100.331 128.662 98.7004 128.662 97.9147 127.566L91.1051 118.066C90.1803 116.776 88.6903 116.011 87.103 116.011H18.2578C14.9441 116.011 12.2578 113.325 12.2578 110.011V14.5664Z' fill='white'/></svg>\")"
};

export const GraphTop = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '20px 20px'
};

export const ConstGraphBtn = {
  color: '#686F7E',
  textAlign: 'center',
  fontFeatureSettings: "'salt' on, 'liga' off",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  textTransform: 'none',
  width: '50px'
};

export const ConstGraphActiveBtn = {
  color: 'black',
  width: '50px',
  'border-radius': '40px',
  background: '#76C8A8',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '16px',
  textTransform: 'none',
  fontFeatureSettings: "'salt' on, 'liga' off"
};
