import React, { useEffect, useState } from 'react';
import './style.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import DashBoardIcon from '../../assets/images/Home-simple-door-mobile-dashboard.png';
import TraderIcon from '../../assets/images/traders-mobile.png';
import SettingIcon from '../../assets/images/Settings.png';
import WalletIcon from '../../assets/images/wallet.png';
import Educenter from '../../assets/images/educenter.png';
import Support from '../../assets/images/support.png';
import LogoutIcon from '../../assets/images/logout.png';

import {
  DashboardBtnMobile,
  NavBtnCustomStyle,
  NavDashBtn,
  NavIcon,
  NavConnectBtn,
  NavGetStartedBtn,
  NavGetStartedBtnMobile,
  NavIconMobile,
  SideBarList
} from './nav-styles';
import ConnectWallet from '../Modal/ConnectWallet';
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { api as axios } from '../../api';
import url from '../../serverUrl';
import { ethers } from 'ethers';
import HeaderTab from '../Tables/Exchange/HeaderTab';
import { navTabsList } from './navTabsList';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, clearAllCookies } from '../../utils';
import { MenuData } from '../Tables/menuData';
import { makeStyles } from '@mui/styles';
import EthWallet from './web3wallet';
const useStyles = makeStyles((theme) => ({
  indicator: {
    display: 'none' // Hide the indicator (active color)
  }
}));

const NavElement = ({ e, i, requestAccount }) => {
  const classes = useStyles();
  const { value, label, child, metamask } = e;
  const [showChild, setShowChild] = useState(false);

  if (child && child.length > 0) {
    return (
      <div
        onMouseEnter={() => setShowChild(true)}
        onMouseLeave={() => setShowChild(false)}
      >
        {/* <Tab
          label={label}
          key={i}
          className="headerTab"
          disableRipple={true}
          disableFocusRipple={true}
        /> */}
        <p
          className="headerTab"
          style={{
            margin: '0.5rem 1rem 0.4rem 1rem',
            cursor: 'pointer'
          }}
        >
          {label}
        </p>
        {showChild && (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 'fit-content',
              zIndex: 999,
              backgroundColor: 'rgb(19 19 21)',
              width: 'fit-content',
              borderBottomRightRadius: '15px',
              borderBottomLeftRadius: '15px',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {child.map((elem, i) => (
              <a href={elem.value} target={'_blank'} rel="noreferrer">
                {/* <p style={{ fontSize: '10px !important', fontWeight: '300' }}>
                  {elem.label}
                </p> */}
                <p
                  className="headerTab"
                  style={{
                    cursor: 'pointer',
                    fontSize: '12px',
                    margin: '1rem 0 1rem 0'
                  }}
                >
                  {elem.label}
                </p>
              </a>
            ))}
          </div>
        )}
      </div>
    );
  }
  if (metamask) {
    return (
      <a href={value}>
        {' '}
        <Tab
          key={i}
          className="headerTab"
          onClick={() => requestAccount()}
          icon={<img src={e.logo} alt={label} width={30} />}
        />
      </a>
    );
  }
  return (
    <a href={value} target={'_blank'} rel="noreferrer">
      {' '}
      <p
        className="headerTab"
        style={{
          margin: '0.5rem 1rem 0.4rem 1rem',
          cursor: 'pointer'
        }}
      >
        {label}
      </p>
    </a>
  );
};
export default function Nav({ fetchWallet, wallet }) {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const { UserInfo, WalletInfo, isAdmin } = useSelector((state) => state);

  const handleOpen = () => {
    setOpen(true);
  };

  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleClose = () => setOpen(false);

  const [showSugesstion, setShowSugesstion] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const findUser = async () => {
    if (UserInfo) {
      setUser(UserInfo);
    }
  };
  const get_current_index_coin = () => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        setCurrentValue(res.data[0].value);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const get_latest_block = () => {
    axios
      .get(`${url}/wallet/latestSyncedBlock`)
      .then((res) => {
        setBlock(res.data.block_length);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const location = useLocation();
  useEffect(() => {
    setUser(null);
    findUser();
    get_current_index_coin();
  }, [location]);

  useEffect(() => {
    findUser();
  }, []);
  const [searchKey, setSearchKey] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (searchKey) {
    //   fetchToken(searchKey);
    // } else fetchToken("all");
  };
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [block, setBlock] = useState('');
  const fetchToken = (target) => {
    if (target === 'all') {
      setLoading(true);
      axios
        .get(`${url}/hootdex/available-tokens`)
        .then((res) => {
          setTokens(res.data);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      setTokens(tokens.filter((each) => each.symbol === target));
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchToken('all');
    get_latest_block();
  }, []);
  const [showMore, setShowMore] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);
  const [connButtonText, setConnButtonText] = useState('Connect Pecu Wallet');
  const [selectedTab, setSelectedTab] = useState(0);
  const [openSwapEth, setOpenSwapEth] = useState(false);
  const requestAccount = () => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: 'eth_requestAccounts'
        })
        .then((result) => {
          if (result && result[0]) {
            alert(JSON.stringify(`MetaMask is connected`));
            accountChangeHandler(result[0]);
          }
        });
    } else {
      setErrorMessage('Install MetaMask');
    }
  };
  const accountChangeHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    getUserBalance(newAccount);
    getPrivateKey(newAccount);
    // console.log(newAccount, 'newAccount');
  };
  const getUserBalance = (address) => {
    window.ethereum
      .request({
        method: 'eth_getBalance',
        params: [address.toString(), 'latest']
      })
      .then((balance) => {
        setUserBalance(ethers.formatEther(balance));
        // console.log(balance, 'balance');
      });
  };
  const getPrivateKey = (key) => {
    window.ethereum
      .request({
        method: 'eth_privateKeyToAccount',
        params: [key, 'latest']
      })
      .then((keys) => {
        setPrivateKey(keys);
        // console.log(keys, 'keys');
      });
  };

  const [time, setTime] = useState(new Date().toLocaleTimeString());
  useEffect(() => {
    setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
  }, []);

  const HandleShow = () => {
    if (showMenu) {
      setShowMenu((old) => !old);
    }
  };
  const closeMenu = () => {
    setShowMenu((old) => !old);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const menu = document.getElementById('your-menu-id');
      if (menu && !menu.contains(event.target) && showMenu) {
        closeMenu();
      }
    };
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [showMenu]);

  const LastSyncBlockDiv = {
    width: '100vw',
    height: '160px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px'
  };
  const BlockSync = {};
  const PecuPrice = {};
  const ContainerWrapper = {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '20px',
    bordeRadius: '40px',
    background: '#1C2B24',
    borderRadius: '40px',
    background: '#1C2B24',
    padding: '20px',
    fontSize: { xs: '14px', sm: '15px', md: '17px', lg: '20px' },
    flexWrap: 'wrap'
  };
  const NavDash = {
    color: '#AEB3BC',
    fontFeatureSettings: 'salt on, liga off',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px'
  };
  return (
    <>
      <EthWallet
        open={openSwapEth}
        setOpen={setOpenSwapEth}
        requestAccount={requestAccount}
        fetchWallet={fetchWallet}
      />
      {screenSize > 700 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { md: 'center' },
              borderBottom: '1px solid #25272c',

              padding: '10px',
              height: '60px',
              alignItems: 'center'
            }}
          >
            <Link to="/">
              <img
                src={require('../Tables/Exchange/hootdex.png')}
                style={{ width: '150px' }}
                alt="logo"
              />
            </Link>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginLeft: { md: '11px', lg: '61px' },
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                maxHeight: '46px'
              }}
            >
              <Link to="/dashboard">
                <Box
                  style={NavDashBtn}
                  sx={{ fontSize: { md: '10px', lg: '16px' } }}
                >
                  Dashboard
                </Box>
              </Link>
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '20px'
                }}
              >
                {navTabsList.map((e, i) => (
                  <NavElement
                    key={i}
                    e={e}
                    i={i}
                    requestAccount={requestAccount}
                    style={NavBtnCustomStyle}
                  />
                ))}
              </Tabs>
            </Box>
            {/* Time and Chat live  */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                marginLeft: '31px',
                borderLeft: '1px solid #25272c',
                borderRight: '1px solid #25272c',
                padding: '0 20px',
                height: '50px'
              }}
            >
              <Box
                sx={{
                  ...NavIcon,
                  fontSize: '12px',
                  width: '200px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 300
                }}
              >
                <img
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '-6px'
                  }}
                  src={require('../../assets/images/clock-icon.png')}
                  alt="HootDex"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}
                >
                  <p style={{ fontSize: '12px' }}>
                    {new Date().toDateString()}
                  </p>
                  <p style={{ fontSize: '12px' }}>
                    {new Date()?.toUTCString()?.split(' ')[4]} GMT
                  </p>
                </div>
                {/* <p style={{ fontSize: '12px', fontWeight: 300 }}>
                  {new Date()?.toUTCString()?.split(' ')[4]} GMT
                </p> */}
              </Box>
              <Box sx={{ ...NavIcon, cursor: 'pointer' }}>
                <img
                  style={{
                    width: '15px',
                    height: '15px',
                    cursor: 'pointer',
                    marginRight: '-6px'
                  }}
                  src={require('../../assets/images/chat-live-icon.png')}
                  alt="ChatHive"
                />
                ChatHive
              </Box>
            </Box>
            {/* Right Most Section */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '21px',
                gap: '20px'
              }}
            >
              <p
                onClick={() => setOpenSwapEth(true)}
                style={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  fontWeight: '400'
                }}
              >
                Get PECU
              </p>

              {/* <img
                style={{ width: '32px', height: '32px' }}
                src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg"
                alt="metamask"
                onClick={() => setOpenSwapEth(true)}
              /> */}
              {WalletInfo ? (
                <Button
                  style={NavConnectBtn}
                  className="nav-btn"
                  onClick={() => {
                    clearAllCookies();
                  }}
                >
                  Disconnect Wallet
                </Button>
              ) : (
                <Button style={NavConnectBtn} onClick={handleOpen}>
                  Connect Pecu Wallet
                </Button>
              )}
              {/* <a
                href="https://www.hootdex.com/getting-started/"
                target="_blank"
              >
                {' '}
                <Button style={NavGetStartedBtn}>Get Started</Button>
              </a> */}
            </Box>
          </Box>
        </>
      ) : (
        //Movbile View
        <>
          <Box
            onClick={HandleShow}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
              height: '60px',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                setShowMenu((old) => !old);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22.9565 10.9526H1.04346C0.467168 10.9526 0 11.4198 0 11.9961C0 12.5724 0.467168 13.0396 1.04346 13.0396H22.9565C23.5328 13.0396 23.9999 12.5724 23.9999 11.9961C24 11.4198 23.5328 10.9526 22.9565 10.9526Z"
                  fill="white"
                />
                <path
                  d="M1.04346 5.73512H22.9565C23.5328 5.73512 23.9999 5.26795 23.9999 4.69165C23.9999 4.11536 23.5328 3.64819 22.9565 3.64819H1.04346C0.467168 3.64819 0 4.11536 0 4.69165C0 5.26795 0.467168 5.73512 1.04346 5.73512Z"
                  fill="white"
                />
                <path
                  d="M22.9565 18.2568H1.04346C0.467168 18.2568 0 18.7241 0 19.3003C0 19.8766 0.467168 20.3438 1.04346 20.3438H22.9565C23.5328 20.3438 23.9999 19.8766 23.9999 19.3003C24 18.7241 23.5328 18.2568 22.9565 18.2568Z"
                  fill="white"
                />
              </svg>
            </Button>
            <Link to="/">
              <img
                src={require('../Tables/Exchange/hootdex.png')}
                style={{ width: '100px' }}
                alt="logo"
              />
            </Link>
            <Box
              style={{
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                style={{ width: '15px', height: '15px' }}
                src={require('../../assets/images/chat-live-icon.png')}
              />
              ChatHive
            </Box>
          </Box>
        </>
      )}
      {showMenu ? (
        <div className="menu" id="your-menu-id">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
              padding: '0 10px'
            }}
          >
            <Link to="/">
              <img
                src={require('../Tables/Exchange/hootdex.png')}
                style={{ width: '100px', marginTop: '9px' }}
                alt="logo"
              />
            </Link>
            <Box sx={NavIconMobile} style={{ gap: '5px' }}>
              <img
                style={{ width: '13px', height: '13px', marginTop: '2px' }}
                src={require('../../assets/images/clock-icon.png')}
              />
              {time}
              <img
                style={{ width: '22px', height: '22px' }}
                src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '21px',
              gap: '20px',
              width: '100%'
            }}
          >
            {/* <a
              href="https://www.hootdex.com/getting-started/"
              target="_blank"
              style={{ width: '82%', marginRight: '20px' }}
            >
              {' '}
              <Button style={NavGetStartedBtnMobile}>Get Started</Button>
            </a> */}

            {WalletInfo ? (
              <Button
                style={NavConnectBtn}
                className="nav-btn"
                onClick={() => {
                  clearAllCookies();
                }}
                sx={{ width: '82%', marginRight: '20px' }}
              >
                Disconnect Wallet
              </Button>
            ) : (
              <Button
                sx={{ width: '82%', marginRight: '20px' }}
                style={NavConnectBtn}
                onClick={handleOpen}
              >
                Connect Pecu Wallet
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',

              alignItems: 'left',
              gap: '20px',
              marginLeft: '31px',
              borderTop: '1px solid #25272c',
              borderBottom: '1px solid #25272c',
              padding: '0 20px',
              height: 'auto',
              width: '90%',
              flexDirection: 'column'
            }}
          >
            Main
            <Link to="/dashboard">
              <Button sx={DashboardBtnMobile}>
                <img src={DashBoardIcon} />
                Dashboard
              </Button>
            </Link>
            <Box>
              <Button sx={SideBarList}>
                <img src={TraderIcon} />
                Traders
              </Button>
              <a
                href="https://www.pecunovus.com/get-your-wallet/"
                target="_blank"
              >
                {' '}
                <Button sx={SideBarList}>
                  <img src={WalletIcon} />
                  Pecu Wallet
                </Button>
              </a>
              <a href="https://www.hootdex.com/education/" target="_blank">
                <Button sx={SideBarList}>
                  <img src={Educenter} />
                  Educenter
                </Button>
              </a>
              <a href="https://www.hootdex.com/support/" target="_blank">
                {' '}
                <Button sx={SideBarList}>
                  <img src={Support} />
                  Support
                </Button>
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'left',
              gap: '20px',
              marginLeft: '31px',
              padding: '0 20px',
              height: 'auto',
              width: '90%',
              flexDirection: 'column'
            }}
          >
            Settings
            <Button sx={SideBarList}>
              <img src={SettingIcon} />
              Settings
            </Button>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              bottom: '10%',
              width: '90%',
              display: 'flex',
              alignItems: 'left'
            }}
          >
            <Link to={user ? '/' : '/login'}>
              <Button
                sx={{
                  color: 'red',
                  width: '90%',
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'left',
                  justifyContent: 'flex-start',
                  paddingLeft: '40px'
                }}
              >
                <img
                  style={{ marginRight: '14px' }}
                  src={LogoutIcon}
                  alt="Logout Icon"
                />
                {user ? 'Logout' : 'Login'}
              </Button>
            </Link>
          </Box>
        </div>
      ) : null}
      <Box sx={LastSyncBlockDiv}>
        <Box sx={ContainerWrapper}>
          <Box sx={BlockSync}>
            Latest synced block:{' '}
            <span style={{ color: '#22BD86' }}>{block}</span>
          </Box>
          <Box sx={PecuPrice}>
            {' '}
            PECU PRICE :
            <span style={{ color: '#22BD86' }}>
              {' '}
              {currentValue?.toFixed(5).toLocaleString('en-US')} USD
            </span>{' '}
          </Box>
        </Box>

        <Box>
          <Box sx={NavDash}>
            <img
              src={require('../../assets/images/Home-simple-door-mobile-dashboard.png')}
              alt="HootDex"
            />
            <span>{`Dashboard > `}</span>
            <span
              style={{ color: '#76C8A8', fontSize: '19px', fontWeight: 'bold' }}
            >
              {MenuData?.find(
                (e) => e.path === location?.pathname
              )?.label?.replace('/', '>') ||
                location?.pathname
                  ?.split('')
                  ?.map((e, i) => (i > 0 ? e?.replace('/', ' > ') : ''))}
            </span>
          </Box>
        </Box>
      </Box>
      <ConnectWallet
        setOpen={setOpen}
        open={open}
        fetchWallet={fetchWallet}
        wallet={WalletInfo}
        setWalletAddress={setWalletAddress}
        requestAccount={requestAccount}
        walletAddress={walletAddress}
        privateKey={privateKey}
      />
    </>
  );
}
