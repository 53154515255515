import Transactions from '../modules/Transactions/transaction.table';
import PecuGraph from '../components/Graphs/PecuGraph';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MenuData } from '../components/Tables/menuData';
import { useEffect, useState } from 'react';
import { api as axios } from '../api';
import url from '../serverUrl';
import { convertToInternationalCurrencySystem } from '../utils';
import SyntheticsTable from '../modules/Synthetics/synthetics.table';
import CommonMenu from '../components/commonMenu/commonMenu';
import GenRevenue from '../components/GenRevenue/GenRevenue';
import { Helmet } from 'react-helmet-async';
export default function Home() {
  const navigate = useNavigate();
  const [searchToken, setSearchToken] = useState('');

  return (
    <>
      {' '}
      <Helmet>
        <title>{`HootDex : A Decentralized Digital Asset Swapping System`}</title>
        <link rel="icon" href={'/favicon.png'} type="image" />
      </Helmet>
      <div
        className="screen"
        style={{
          zIndex: '100',
          position: 'sticky',
          width: '100%',
          marginBottom: '1rem'
        }}
      >
        <PecuGraph />

        <div
          style={{
            width: '95%',
            minWidth: '340px'
          }}
          className="res-token"
        >
          <GenRevenue />
          <CommonMenu label={'SynthCryptos'} setSearch={setSearchToken} />
          <SyntheticsTable searchToken={searchToken} />
          <Transactions />
        </div>
      </div>
    </>
  );
}
