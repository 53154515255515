/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import {
  // Alert,
  Avatar,
  Button,
  // Collapse,
  Grid,
  // IconButton,
  LinearProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../../components/coinInfo/coinInfo';
import { logoWrapper } from '../../utils';
import SwapDiv from '../../components/SwapDiv';
import { Helmet } from 'react-helmet-async';
import symbols from '../../symbolsMapper';
function convertToInternationalCurrencySystem(labelValue) {
  return labelValue > 1 ? labelValue?.toFixed(5) : labelValue?.toFixed(5);
  // Nine Zeroes for Billions
  // return Math.abs(Number(labelValue)) >= 1.0e9
  //   ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(5) + 'b'
  //   : // Six Zeroes for Millions
  //   Math.abs(Number(labelValue)) >= 1.0e6
  //   ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(5) + 'm'
  //   : // Three Zeroes for Thousands
  //   Math.abs(Number(labelValue)) >= 1.0e3
  //   ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(5) + 'k'
  //   : Math.abs(Number(labelValue))
  //   ? Math.abs(Number(labelValue))?.toFixed(5)
  //   : '0.00';
}

export default function DatPage() {
  const [data, setData] = useState({});
  const { identitySymbol } = useParams();
  const tokenSymbol = identitySymbol;
  const [assets, setAssets] = useState([{ pecu: 0, tokens: [] }]);
  const [token, setToken] = useState({
    tokenName: data?.baseToken,
    timestamp: data?.date_time,
    firstPrice: data?.firstPrice,
    id: data?.id,
    initialFinal: data?.initialFinal,
    pecuInvestement: data?.pecuInvestement,
    pecuValue: data?.pecuValue,
    public_key: data?.public_key,
    tokenSymbol: data?.symbol,
    image: data?.image,
    ...data
  });
  useEffect(() => {
    if (data && data.id) {
      setToken({
        tokenName: data?.baseToken,
        timestamp: data?.date_time,
        firstPrice: data?.firstPrice,
        id: data?.id,
        initialFinal: data?.initialFinal,
        pecuInvestement: data?.pecuInvestement,
        pecuValue: data?.pecuValue,
        public_key: data?.public_key,
        tokenSymbol: data?.symbol,
        image: data?.image,
        ...data
      });
    }
  }, [data]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (tokenSymbol) {
      setLoading(true);
      axios
        .get(`${url}/dat/get_dat?symbol=${tokenSymbol}`)
        .then((res) => {
          if (res.data.status) {
            const token = res.data.stock;

            setData(token[0]);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });

      axios
        .get(`${url}/dat/balance/${tokenSymbol}`)
        .then((res) => {
          if (res.data) {
            setAssets(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [tokenSymbol]);

  return (
    <>
      {' '}
      <Helmet>
        <title>{`${identitySymbol} ${
          data?.currentPrice?.toFixed(5) ?? ''
        }`}</title>
        {/* Other meta tags or head elements */}
      </Helmet>
      {loading && (
        <div>
          <LinearProgress sx={{ backgroundColor: 'grey' }} />
        </div>
      )}
      {!loading && (
        <Box
          sx={{ padding: { xs: 1, md: '1rem 4rem' }, marginLeft: { xs: 4 } }}
        >
          <Grid
            sx={{ marginLeft: '-55px' }}
            container
            spacing={5}
            // padding={{ xs: 0, md: 0 }}
            // mb={1}
          >
            <SwapDiv token={tokenSymbol} />

            <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
              <div
                style={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  borderRadius: '20px',
                  height: '100%',
                  padding: '1rem',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    // alignItems: 'center',
                    // margin: '0 0 0 1rem',
                    width: 'fit-content'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={logoWrapper(token?.logo, token)}
                    alt={token.symbol}
                    style={{
                      height: '45px',
                      width: '45px',
                      fontSize: '18px',
                      alignSelf: 'center'
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 10
                    }}
                  >
                    <p
                      style={{
                        color: 'white',
                        // marginLeft: '5px',
                        fontSize: '16px',
                        fontWeight: '500'
                        // fontFamily: 'Inter var sans-serif'
                      }}
                    >
                      {token?.identitySymbol}
                    </p>
                    <p style={{ fontSize: '1rem', color: 'grey' }}>
                      {token?.name}
                    </p>
                  </div>
                </div>

                <a
                  href="https://pecuscan.com/"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant="outlined"
                    color="success"
                    style={{ borderRadius: 50, width: '100%' }}
                  >
                    Pecuscan
                  </Button>
                </a>
                <br />

                <p style={{ fontSize: '1rem', color: 'grey' }}>
                  {token?.identitySymbol} price:
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    minWidth: '90vw',
                    width: '100%',
                    flexWrap: 'wrap'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      minWidth: '60%',
                      flexDirection: 'row',
                      flexWrap: 'wrap'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontWeight: '400',
                        color: 'white',
                        cursor: 'pointer',
                        alignItems: 'center'
                      }}
                    >
                      <p
                        style={{
                          fontSize: '24px',
                          fontWeight: 500
                        }}
                      >
                        $
                        {convertToInternationalCurrencySystem(
                          data?.currentPrice
                        )}
                      </p>

                      <small
                        style={{ fontSize: '16px', color: 'green' }}
                        className={
                          data.currentPrice - data.price1hour > 0
                            ? `twhite green`
                            : `twhite red`
                        }
                        align="left"
                      >
                        {data.currentPrice - data.price1hour > 0 ? (
                          <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                        ) : (
                          <ArrowDownward sx={{ fontSize: '13px' }} />
                        )}
                        {(
                          Math.abs(
                            (data.currentPrice - data.price1hour) /
                              data.currentPrice
                          ) * 100
                        ).toFixed(5)}
                        %
                      </small>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    fontWeight: '400',
                    color: 'white',
                    cursor: 'pointer',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: '1px solid #22BD86',
                    borderRadius: 8,
                    padding: '8px'
                  }}
                >
                  {' '}
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 500,
                      margin: '5px'
                    }}
                  >
                    Holdings
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <Avatar
                      className="rounded"
                      src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                      alt="token logo"
                      style={{
                        height: '20px',
                        width: '20px',
                        fontSize: '20px'
                      }}
                    />{' '}
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        margin: '5px'
                      }}
                    >
                      Pecu : {assets?.pecu}
                    </p>
                  </div>
                  {assets?.tokens?.map((e, i) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <Avatar
                        className="rounded"
                        src={`${url}/logo/tokenlogo/${
                          symbols[e.symbol] ?? e.symbol
                        }`}
                        alt="token logo"
                        style={{
                          height: '20px',
                          width: '20px',
                          fontSize: '20px'
                        }}
                      />{' '}
                      <p
                        key={i}
                        style={{
                          fontSize: '14px',
                          fontWeight: 400,
                          margin: '5px'
                        }}
                      >
                        {e.symbol} : {e.amount}
                      </p>
                    </div>
                  ))}
                </div>
                {/* <img src={logoWrapper(token?.logo)} width={'100px'} />
                <br></br>
                <p>Symbol : {token?.identitySymbol}</p>
                <br></br>

                <p>Name : {token?.name}</p> */}

                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '25px',
                    color: 'grey'
                  }}
                >
                  <div style={{ color: 'white' }}>Description</div>
                  {token?.description}
                </p>
                <br />

                {token?.link && (
                  <Button
                    variant="contained"
                    color="success"
                    style={{ borderRadius: 50, backgroundColor: '#76C8A8' }}
                  >
                    <a
                      // className='learnmore'
                      style={{ fontSize: '10px', padding: 5, color: 'black' }}
                      href={token?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </Button>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              sx={{ marginTop: { xs: 4, md: 4 }, order: { xs: 0, md: 1 } }}
            >
              <Box sx={{ height: '100%' }}>
                <div
                  style={{
                    backgroundColor: 'rgb(25, 27, 31)',
                    height: '100%',
                    borderRadius: '20px',
                    padding: '1.5rem'
                  }}
                  className="shadowGrey"
                >
                  <CoinInfo
                    coin={{
                      symbol: token?.symbol
                    }}
                  />

                  {/* <TokenGraph id={token?.id} setTokenPrice={setTokenPrice} /> */}
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
              {/* <TopToken /> */}
              <Transactions />
            </Grid>
          </Grid>
          {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
                    <Alert
                      variant="outlined"
                      severity={alert.type}
                      sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
                    >
                      {alert.msg}
                    </Alert>
                  </Collapse>
                </div> */}
        </Box>
      )}
    </>
  );
}
