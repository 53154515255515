/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import { Avatar, Button, Divider, Grid, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import { ArrowDownward } from '@mui/icons-material';
import SwapDiv from '../../components/SwapDiv';
import CoinInfo from '../../components/coinInfo/coinInfo';
import { Helmet } from 'react-helmet-async';
function convertToInternationalCurrencySystem(labelValue) {
  return labelValue > 1 ? labelValue?.toFixed(5) : labelValue?.toFixed(5);
  // Nine Zeroes for Billions
}

export default function CryptoPairPage() {
  const [data, setData] = useState({});
  const { identitySymbol } = useParams();
  const tokenSymbol = identitySymbol;
  const [token, setToken] = useState({
    tokenName: data?.baseToken,
    timestamp: data?.date_time,
    firstPrice: data?.firstPrice,
    id: data?.id,
    initialFinal: data?.initialFinal,
    pecuInvestement: data?.pecuInvestement,
    pecuValue: data?.pecuValue,
    public_key: data?.public_key,
    tokenSymbol: data?.symbol,
    image: data?.image,
    ...data
  });
  useEffect(() => {
    if (data && data.id) {
      setToken({
        tokenName: data?.baseToken,
        timestamp: data?.date_time,
        firstPrice: data?.firstPrice,
        id: data?.id,
        initialFinal: data?.initialFinal,
        pecuInvestement: data?.pecuInvestement,
        pecuValue: data?.pecuValue,
        public_key: data?.public_key,
        tokenSymbol: data?.symbol,
        image: data?.image,
        ...data
      });
    }
  }, [data]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (tokenSymbol) {
      setLoading(true);
      axios
        .get(`${url}/crypto/get_derivatives?symbol=${tokenSymbol}`)
        .then((res) => {
          if (res.data.status) {
            const token = res.data.stock;

            setData(token[0]);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [tokenSymbol]);

  useEffect(() => {
    if (tokenSymbol) {
      const intervalId = setInterval(() => {
        axios
          .get(`${url}/crypto/get_derivatives?symbol=${tokenSymbol}`)
          .then((res) => {
            if (res.data.status) {
              const token = res.data.stock;

              setData(token[0]);
            }
          })
          .catch((err) => {});
      }, 1000);

      // Cleanup function to clear the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [tokenSymbol]);
  return (
    <>
      <Helmet>
        <title>{`${identitySymbol} ${
          data?.currentPrice?.toFixed(5) ?? ''
        }`}</title>
        <link
          rel="icon"
          href={`${url}/logo/tokenlogo/${identitySymbol}`}
          type="image"
        />
      </Helmet>
      {loading && (
        <div>
          <LinearProgress sx={{ backgroundColor: 'grey' }} />
        </div>
      )}
      {!loading && (
        <Box
          sx={{ padding: { xs: 1, md: '1rem 4rem' }, marginLeft: { xs: 4 } }}
        >
          <Grid
            sx={{ marginLeft: '-55px' }}
            container
            spacing={5}
            // padding={{ xs: 0, md: 0 }}
            // mb={1}
          >
            {/* <Grid item xs={12} md={6} width={'100%'} > */}
            {/* <div>
                <Link
                  to={`/`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >Home</Link>
                <Link
                  to={`/cryptoPairs`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >
                  {' >'} cryptoPairs
                </Link>
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                >
                  {' >'} {tokenSymbol}
                </span>
              </div> */}
            {/* </Grid> */}

            {/* <Grid item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end'
                }}
              ></div>
            </Grid> */}
            <SwapDiv token={tokenSymbol} />
            <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
              <div
                style={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  borderRadius: '20px',
                  height: '100%',
                  padding: '1rem',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                {/* <img
                  src={token?.logo?.replace(
                    'api.hootdex.net',
                    'dev.hootdex.org'
                  )}
                  width={'100px'}
                /> */}
                {/* <br></br>
                <p>Symbol : {token?.identitySymbol}</p> */}
                <div
                  style={{
                    display: 'flex',
                    // alignItems: 'center',
                    // margin: '0 0 0 1rem',
                    width: 'fit-content'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={token?.logo?.replace(
                      'api.hootdex.net',
                      'dev.hootdex.org'
                    )}
                    alt={token.symbol}
                    style={{
                      height: '45px',
                      width: '45px',
                      fontSize: '18px',
                      alignSelf: 'center'
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 10
                    }}
                  >
                    <p
                      style={{
                        color: 'white',
                        // marginLeft: '5px',
                        fontSize: '16px',
                        fontWeight: '500'
                        // fontFamily: 'Inter var sans-serif'
                      }}
                    >
                      {token?.identitySymbol}
                    </p>

                    <p style={{ fontSize: '1rem', color: 'grey' }}>
                      {token?.name}
                    </p>
                  </div>
                </div>

                <a
                  href="https://pecuscan.com/"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant="outlined"
                    color="success"
                    style={{ borderRadius: 50, width: '100%' }}
                  >
                    Pecuscan
                  </Button>
                </a>
                <br />

                <p style={{ fontSize: '1rem', color: 'grey' }}>
                  {token?.identitySymbol} price:
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    minWidth: '90vw',
                    width: '100%',
                    flexWrap: 'wrap'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      minWidth: '60%',
                      flexDirection: 'row',
                      flexWrap: 'wrap'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontWeight: '400',
                        color: 'white',
                        cursor: 'pointer',
                        alignItems: 'center'
                      }}
                    >
                      <p
                        style={{
                          fontSize: '24px',
                          fontWeight: 500
                        }}
                      >
                        $
                        {convertToInternationalCurrencySystem(
                          data?.currentPrice
                        )}
                      </p>

                      <small
                        style={{ fontSize: '16px', color: 'green' }}
                        className={
                          data.currentPrice - data.price1hour > 0
                            ? `twhite green`
                            : `twhite red`
                        }
                        align="left"
                      >
                        {data.currentPrice - data.price1hour > 0 ? (
                          <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                        ) : (
                          <ArrowDownward sx={{ fontSize: '13px' }} />
                        )}
                        {(
                          Math.abs(
                            (data.currentPrice - data.price1hour) /
                              data.currentPrice
                          ) * 100
                        ).toFixed(5)}
                        %
                      </small>
                    </div>
                  </div>
                </div>
                <br />

                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '25px',
                    color: 'grey'
                  }}
                >
                  <div style={{ color: 'white' }}>Description</div>
                  {token?.description}
                </p>
                <br />

                {token?.link && (
                  <Button
                    variant="contained"
                    color="success"
                    style={{ borderRadius: 50, backgroundColor: '#76C8A8' }}
                  >
                    <a
                      // className='learnmore'
                      style={{ fontSize: '10px', padding: 5, color: 'black' }}
                      href={token?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </Button>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              sx={{ marginTop: { xs: 4, md: 4 }, order: { xs: 0, md: 1 } }}
            >
              <Box sx={{ height: '100%' }}>
                <Box
                  sx={{
                    backgroundColor: 'rgb(25, 27, 31)',
                    height: '100%',
                    borderRadius: '20px',
                    padding: { xs: '0 1rem 0 0', md: '1.5rem' }
                  }}
                  className="shadowGrey"
                >
                  <CoinInfo
                    coin={{
                      id:
                        token.cryptoSymbol === 'BTC'
                          ? 'bitcoin'
                          : token?.cryptoSymbol === 'ETH'
                          ? 'ethereum'
                          : token?.cryptoSymbol === 'USDT'
                          ? 'tether'
                          : '',
                      symbol: token?.symbol
                    }}
                  />

                  {/* <TokenGraph id={token?.id} setTokenPrice={setTokenPrice} /> */}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
              {/* <TopToken /> */}
              <Transactions />
            </Grid>
          </Grid>
          {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
                    <Alert
                      variant="outlined"
                      severity={alert.type}
                      sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
                    >
                      {alert.msg}
                    </Alert>
                  </Collapse>
                </div> */}
        </Box>
      )}
    </>
  );
}
