import { Avatar, Button, Grid, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../../components/coinInfo/coinInfo';
import { logoWrapper, priceWrapper } from '../../utils';
import SwapDiv from '../../components/SwapDiv';
import { Helmet } from 'react-helmet-async';
export default function StableCoinPage() {
  const { identitySymbol } = useParams() || {};
  const tokenSymbol = identitySymbol;
  const [token, setToken] = useState({});
  const { symbol, currentPrice, price1hour, description, logo, name, link } =
    token || {};
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tokenSymbol) {
      setLoading(true);
      axios
        .get(`/crypto/get_stable_coin?symbol=${tokenSymbol}`)
        .then((res) => {
          const { status, stock } = res.data || { status: false, stock: [] };
          if (status) {
            setToken(stock[0]);
          }
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  }, [tokenSymbol]);

  useEffect(() => {
    if (tokenSymbol) {
      const intervalId = setInterval(() => {
        axios
          .get(`/crypto/get_stable_coin?symbol=${tokenSymbol}`)
          .then((res) => {
            const { status, stock } = res.data || { status: false, stock: [] };
            if (status) {
              setToken(stock[0]);
            }
          })
          .catch((err) => {});
      }, 1000);

      // Cleanup function to clear the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [tokenSymbol]);
  if (loading) {
    return (
      <Box sx={{ padding: { xs: 1, md: '1rem 4rem' } }}>
        <LinearProgress sx={{ backgroundColor: 'grey' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: 1, md: '1rem 4rem' }, marginLeft: { xs: 4 } }}>
      <Helmet>
        <title>{`${identitySymbol} ${currentPrice?.toFixed(5) ?? ''}`}</title>
        <link
          rel="icon"
          href={`${url}/logo/tokenlogo/${identitySymbol}`}
          type="image"
        />
      </Helmet>
      <Grid sx={{ marginLeft: '-55px' }} container spacing={5}>
        {window?.screen?.availWidth > 600 ? (
          <SwapDiv token={tokenSymbol} />
        ) : (
          <Grid item xs={12} md={6}>
            <a
              href={`http://app.hootdex.net/${identitySymbol}`}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="outlined"
                color="success"
                style={{
                  borderRadius: 50,
                  width: '100%',
                  marginTop: 10,
                  backgroundColor: '#76C8A8',
                  color: 'black',
                  padding: 10,
                  fontWeight: 'bold'
                }}
              >
                Swap
              </Button>
            </a>
          </Grid>
        )}
        <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
          <div
            style={{
              backgroundColor: 'rgb(25, 27, 31)',
              borderRadius: '20px',
              height: '100%',
              padding: '1rem',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                width: 'fit-content'
              }}
            >
              <Avatar
                className="rounded"
                src={logoWrapper(logo)}
                alt={tokenSymbol}
                style={{
                  height: '45px',
                  width: '45px',
                  fontSize: '18px',
                  alignSelf: 'center'
                }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 10
                }}
              >
                <p
                  style={{
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: '500'
                  }}
                >
                  {tokenSymbol}
                </p>
                <p style={{ fontSize: '1rem', color: 'grey' }}>{token?.name}</p>
              </div>
            </div>

            <a
              href="https://pecuscan.com/"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="outlined"
                color="success"
                style={{ borderRadius: 50, width: '100%' }}
              >
                Pecuscan
              </Button>
            </a>
            <br />

            <p style={{ fontSize: '1rem', color: 'grey' }}>
              {token?.identitySymbol} price:
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minWidth: '90vw',
                width: '100%',
                flexWrap: 'wrap'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  minWidth: '60%',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontWeight: '400',
                    color: 'white',
                    cursor: 'pointer',
                    alignItems: 'center'
                  }}
                >
                  <p
                    style={{
                      fontSize: '24px',
                      fontWeight: 500
                    }}
                  >
                    ${priceWrapper(currentPrice)}
                  </p>

                  <small
                    style={{ fontSize: '16px', color: 'green' }}
                    className={
                      currentPrice - price1hour > 0
                        ? `twhite green`
                        : `twhite red`
                    }
                    align="left"
                  >
                    {currentPrice - price1hour > 0 ? (
                      <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                    ) : (
                      <ArrowDownward sx={{ fontSize: '13px' }} />
                    )}
                    {(
                      Math.abs((price1hour - currentPrice) / price1hour) * 100
                    ).toFixed(5)}{' '}
                    %
                  </small>
                </div>
              </div>
            </div>
            <br />

            <p style={{ fontSize: '14px', lineHeight: '25px', color: 'grey' }}>
              <div style={{ color: 'white' }}>Description</div>
              {token?.description}
            </p>
            <br />

            {link && (
              <Button
                variant="contained"
                color="success"
                style={{ borderRadius: 50, backgroundColor: '#76C8A8' }}
              >
                <a
                  // className='learnmore'
                  style={{ fontSize: '10px', padding: 5, color: 'black' }}
                  href={token?.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </Button>
            )}
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{ marginTop: { xs: 4, md: 4 }, order: { xs: 0, md: 1 } }}
        >
          <Box sx={{ height: '100%' }}>
            <Box
              sx={{
                backgroundColor: 'rgb(25, 27, 31)',
                height: '100%',
                borderRadius: '20px',
                padding: { xs: '0 1rem 0 0', md: '1.5rem' }
              }}
              className="shadowGrey"
            >
              <CoinInfo
                coin={{
                  id: '',
                  symbol: symbol
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
          <Transactions />
        </Grid>
      </Grid>
    </Box>
  );
}
