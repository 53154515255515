import React, { useEffect } from 'react';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { MenuData } from './menuData';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fecthDelistedTokens, logoWrapper } from '../../utils';
import * as actionTypes from '../../store/actionTypes';
import CommonMenu from '../../components/commonMenu/commonMenu';
import PecuGraph from '../../components/Graphs/PecuGraph';
import { StarOutline } from '@mui/icons-material';
import Transactions from '../../modules/Transactions/transaction.table';

const DelistedToken = () => {
  // const navigate = useNavigate();
  const loading = false;
  const dispatch = useDispatch();

  useEffect(() => {
    fecthDelistedTokens(dispatch, actionTypes.FETCH_DELISTED_TOKENS);
    const intervalId = setInterval(() => {
      fecthDelistedTokens(dispatch, actionTypes.FETCH_DELISTED_TOKENS);
    }, 1500);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const { deListed } = useSelector((state) => state);

  return (
    <>
      <div
        className="screen"
        style={{
          zIndex: '100',
          position: 'sticky',
          width: '100%',
          marginTop: '4rem',
          marginBottom: '1rem'
        }}
      >
        <PecuGraph />

        <div
          style={{
            width: '95%',
            minWidth: '340px'
          }}
          className="res-token"
        >
          <CommonMenu label={'Delisted'} />

          <TableContainer
            sx={{
              backgroundColor: '#1C1D20',
              mt: 2,
              borderRadius: '0.5rem',
              minHeight: '80vh'
            }}
            component={Paper}
          >
            {loading && <LinearProgress color="inherit" />}
            {!loading ? (
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: ' 1px solid #1e2128'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    {/* table headers  */}
                    {['FAV', 'NAME', '', ''].map((header, index) => {
                      return (
                        <TableCell style={{ color: 'white' }} key={index}>
                          {header}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {deListed &&
                    deListed.length > 0 &&
                    deListed[0]?.map((each, index) => (
                      <TableRow key={'0' + index}>
                        <TableCell
                          style={{ color: 'grey', display: 'flex' }}
                          align="left"
                        >
                          <StarOutline fontSize="small" />
                        </TableCell>

                        <TableCell style={{ color: 'grey' }} align="left">
                          <Link to={`#`} state={each}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={logoWrapper(each?.image)}
                                alt="token logo"
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  fontSize: '18px'
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: '1rem',
                                  fontSize: '14px',
                                  color: 'grey',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <small
                                  style={{
                                    fontSize: '13px',
                                    color: 'orange'
                                  }}
                                >
                                  {each?.token_symbol}
                                </small>
                                <small
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '4px',
                                    fontWeight: 400
                                  }}
                                >
                                  {each.token_name}
                                </small>
                              </span>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell style={{ padding: '0 8rem' }}></TableCell>
                        <TableCell
                          className={
                            each.priceChange >= 0 ? `twhite ` : `twhite `
                          }
                          align="left"
                        >
                          <Avatar
                            src={require('../../assets/images/redskull100.png')}
                            width={100}
                            height={100}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  {deListed &&
                    deListed.length > 1 &&
                    deListed[1]?.map((each, index) => (
                      <TableRow key={'1' + index}>
                        <TableCell
                          style={{ color: 'grey', display: 'flex' }}
                          align="left"
                        >
                          <StarOutline fontSize="small" />
                        </TableCell>

                        <TableCell style={{ color: 'grey' }} align="left">
                          <Link to={`#`} state={each}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={logoWrapper(each?.image)}
                                alt="token logo"
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  fontSize: '18px'
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: '1rem',
                                  fontSize: '14px',
                                  color: 'grey',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <small
                                  style={{
                                    fontSize: '13px',
                                    color: 'orange'
                                  }}
                                >
                                  {each?.token_symbol}
                                </small>
                                <small
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '4px',
                                    fontWeight: 400
                                  }}
                                >
                                  {each.token_name}
                                </small>
                              </span>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell style={{ padding: '0 8rem' }}></TableCell>
                        <TableCell
                          className={
                            each.priceChange >= 0 ? `twhite ` : `twhite `
                          }
                          align="left"
                        >
                          <Avatar
                            src={require('../../assets/images/redskull100.png')}
                            width={100}
                            height={100}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  {deListed &&
                    deListed.length > 2 &&
                    deListed[2]?.map((each, index) => (
                      <TableRow key={`2` + index}>
                        <TableCell
                          style={{ color: 'grey', display: 'flex' }}
                          align="left"
                        >
                          <StarOutline fontSize="small" />
                        </TableCell>

                        <TableCell style={{ color: 'grey' }} align="left">
                          <Link to={`#`} state={each}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={logoWrapper(each?.image)}
                                alt="token logo"
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  fontSize: '18px'
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: '1rem',
                                  fontSize: '14px',
                                  color: 'grey',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <small
                                  style={{
                                    fontSize: '13px',
                                    color: 'orange'
                                  }}
                                >
                                  {each?.token_symbol}
                                </small>
                                <small
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '4px',
                                    fontWeight: 400
                                  }}
                                >
                                  {each.token_name}
                                </small>
                              </span>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell style={{ padding: '0 8rem' }}></TableCell>
                        <TableCell
                          className={
                            each.priceChange >= 0 ? `twhite ` : `twhite `
                          }
                          align="left"
                        >
                          <Avatar
                            src={require('../../assets/images/redskull100.png')}
                            width={100}
                            height={100}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  {deListed &&
                    deListed.length > 3 &&
                    deListed[3]?.map((each, index) => (
                      <TableRow key={'0' + index}>
                        <TableCell
                          style={{ color: 'grey', display: 'flex' }}
                          align="left"
                        >
                          <StarOutline fontSize="small" />
                        </TableCell>

                        <TableCell style={{ color: 'grey' }} align="left">
                          <Link to={`#`} state={each}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className="rounded"
                                src={logoWrapper(each?.logo)}
                                alt="token logo"
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  fontSize: '18px'
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: '1rem',
                                  fontSize: '14px',
                                  color: 'grey',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <small
                                  style={{
                                    fontSize: '13px',
                                    color: 'orange'
                                  }}
                                >
                                  {each?.identitySymbol}
                                </small>
                                <small
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '4px',
                                    fontWeight: 400
                                  }}
                                >
                                  {each.name}
                                </small>
                              </span>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell style={{ padding: '0 8rem' }}></TableCell>
                        <TableCell
                          className={
                            each.priceChange >= 0 ? `twhite ` : `twhite `
                          }
                          align="left"
                        >
                          <Avatar
                            src={require('../../assets/images/redskull100.png')}
                            width={100}
                            height={100}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <Skeleton
                sx={{ bgcolor: '#21242b', mt: 1 }}
                variant="rectangular"
                margin={'1rem'}
                height={100}
              />
            )}
          </TableContainer>

          <Transactions />
        </div>
      </div>
    </>
  );
};
export default React.memo(DelistedToken);
