import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Grid,
  IconButton,
  LinearProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import soapbox_btn from '../../assets/images/soapbox_btn.svg';
import BuyProjectToken from '../../components/Modal/BuyProjectToken';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../../components/coinInfo/coinInfo';
import SwapDiv from '../../components/SwapDiv';
import { logoWrapper } from '../../utils';
function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return labelValue > 1 ? labelValue?.toFixed(5) : labelValue?.toFixed(5);
}
export default function FanToken({ user }) {
  const token_symbol = useParams().tokenSymbol;
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [firstChart, setFirstChart] = useState(null);
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });

  const fetchFanToken = () => {
    setLoading(true);
    axios
      .get(`${url}/wallet/get_tokens_fan_by_symbol?symbol=${token_symbol}`)
      .then((res) => {
        setToken(res.data.token[0]);

        // setFirstChart(res.data.chart);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          msg: 'There was an error',
          type: 'error',
          show: true
        });
        setTimeout(() => {
          setAlert({
            msg: 'There was an error',
            type: 'error',
            show: false
          });
        }, 3000);
        console?.log(err);
      });
  };

  useEffect(() => {
    fetchFanToken();
  }, []);

  return (
    <>
      {loading && (
        <div>
          <LinearProgress sx={{ backgroundColor: 'grey' }} />
        </div>
      )}
      {!loading && (
        <Box
          sx={{ padding: { xs: 1, md: '1rem 4rem' }, marginLeft: { xs: 4 } }}
        >
          <Grid
            sx={{ marginLeft: '-55px' }}
            container
            spacing={5}
            // padding={{ xs: 0, md: 0 }}
          >
            <Grid item xs={12} md={6}></Grid>

            <Grid item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginBottom: -40
                }}
              >
                <a
                  href={`http://app.hootdex.net/${token_symbol}`}
                  target="_blank"
                >
                  {' '}
                  <Box
                    sx={{
                      backgroundColor: ' #76C8A8;',
                      color: 'black',
                      padding: '1rem 1.5rem',
                      borderRadius: 50,
                      cursor: 'pointer',
                      maxHeight: '40px',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                    // className="shadow"
                  >
                    <p>Swap</p>
                  </Box>
                </a>
              </div>
            </Grid>

            <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
              <div
                style={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  borderRadius: '20px',
                  height: '100%',
                  padding: '1rem',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start'
                }}
                className="shadowGrey"
              >
                <div
                  style={{
                    display: 'flex',
                    // alignItems: 'center',
                    margin: '0 0 1rem 0',
                    width: 'fit-content'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={token?.image}
                    alt={token_symbol?.slice(1)}
                    style={{
                      backgroundColor: 'orange',
                      height: '45px',
                      width: '45px',
                      fontSize: '18px',
                      alignSelf: 'center'
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 10
                    }}
                  >
                    <p
                      style={{
                        color: 'white',
                        // marginLeft: '5px',
                        fontSize: '16px',
                        fontWeight: '500'
                        // fontFamily: 'Inter var sans-serif'
                      }}
                    >
                      <span>{token_symbol}</span>
                    </p>
                    <p style={{ fontSize: '1rem', color: 'grey' }}>
                      {token?.token_name}
                    </p>
                  </div>
                </div>
                <div>
                  <a
                    href="https://pecuscan.com/"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="outlined"
                      color="success"
                      style={{ borderRadius: 50, width: '100%' }}
                    >
                      Pecuscan
                    </Button>
                  </a>
                  <br />

                  <br></br>
                  <p>
                    {token?.token_symbol} Price : $
                    {convertToInternationalCurrencySystem(token?.token_price)}{' '}
                    <small
                      style={{
                        fontSize: '16px',
                        color:
                          token?.price1hour - token?.token_price > 0
                            ? 'green'
                            : 'red'
                      }}
                    >
                      {token?.price1hour - token?.token_price > 0 ? (
                        <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                      ) : (
                        <ArrowDownward sx={{ fontSize: '13px' }} />
                      )}
                      {token?.price1hour
                        ? (
                            Math.abs(
                              (token?.price1hour - token?.token_price) /
                                token?.price1hour
                            ) * 100
                          ).toFixed(5)
                        : '0.00'}{' '}
                      %
                    </small>
                  </p>
                  <br></br>

                  <p>Name : {token?.token_name}</p>
                  <br></br>
                  <p>
                    TVL : $
                    {convertToInternationalCurrencySystem(
                      token?.amount_issued * token?.token_price
                    )}
                  </p>

                  <p style={{ fontSize: '12px', lineHeight: '25px' }}>
                    <h3>Description</h3>
                    {token?.token_desc}
                  </p>
                  <br></br>

                  <p style={{ fontSize: '14px' }}>
                    Amount: {token?.amount_issued}
                  </p>
                  <br></br>
                  <p style={{ fontSize: '12px' }}>
                    Created on :
                    {new Date(token?.date_time)?.toLocaleDateString()}
                    {new Date(token?.date_time)?.toLocaleTimeString()}
                  </p>
                  <br></br>
                  <Button
                    variant="contained"
                    color="success"
                    style={{ borderRadius: 50, backgroundColor: '#76C8A8' }}
                  >
                    <a
                      // className='learnmore'
                      style={{ fontSize: '10px', padding: 5, color: 'black' }}
                      href={token?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                    </a>
                  </Button>
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              sx={{ marginTop: { xs: 2, md: 3 }, order: { xs: 0, md: 1 } }}
            >
              <Box sx={{ height: '100%' }}>
                <Box
                  sx={{
                    backgroundColor: 'rgb(25, 27, 31)',
                    height: '100%',
                    borderRadius: '20px',
                    padding: { xs: '0 1rem 0 0', md: '1.5rem' }
                  }}
                  className="shadowGrey"
                >
                  <CoinInfo
                    coin={{
                      id: '',
                      symbol: token_symbol
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
              <Transactions />
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
              <Alert
                variant="outlined"
                severity={alert.type}
                sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
              >
                {alert.msg}
              </Alert>
            </Collapse>
          </div>
        </Box>
      )}
    </>
  );
}
