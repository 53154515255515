/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import url from '../../serverUrl';
import { useSelector } from 'react-redux';
import { convertToInternationalCurrencySystem, logoWrapper } from '../../utils';
import Transactions from '../../modules/Transactions/transaction.table';
import { MenuData } from './menuData';
import { api as axios } from '../../api';

const PoolTokensUpdate = () => {
  const loading = false;
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState([]);
  const { PecuPrice, liquidityPools } = useSelector((state) => state);
  const [user, setUser] = useState('');
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });
  const [currentValue, setCurrentValue] = useState(null);
  const [inputData, setInputData] = useState({});
  const [wrapTokenPrice, setWrapTokenPrice] = useState('');
  const [cryptoData, setCryptoData] = useState([]);

  const [tokens, setTokens] = useState([]);
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
          allData[i].initialFinal =
            allData[j].initialFinal + allData[i].initialFinal;
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };

  const handleDelist = (token) => {
    // console.log(token);
    if (token.isHoldingTLP) {
      let updateToken = token.holding_token_symbol;
      axios
        .post(`${url}/hootdex/delist`, { token: updateToken, Pool: token })
        .then((res) => {
          window.alert('Successfully Delisted');
          // console.log(res.data);
        })
        .catch((err) => window.alert(JSON.stringify(err)));
    } else {
      let updateToken = token.project_token_symbol;
      axios
        .post(`${url}/hootdex/delist`, { token: updateToken, Pool: token })
        .then((res) => window.alert('Successfully Delisted'))
        .catch((err) => window.alert(JSON.stringify(err)));
    }
  };
  const get_crypto_Data = () => {
    axios.get(`${url}/crypto/index`).then((res) => {
      setCryptoData(res.data);
    });
  };
  const fetchToken = (target) => {
    axios
      .get(`${url}/wallet/get_all_tokens_wrap_new`)
      .then((res) => {
        if (res.data.status) {
          setTokens(res.data.tokens);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const get_current_index_coin = () => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        setCurrentValue(res.data[0].value);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const saveFile = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setInputData({ ...inputData, fileName: res.data.fileName });
      } else {
        setAlert({
          msg: 'Image upload failed',
          type: 'error',
          show: true
        });
        setTimeout(() => {
          setAlert({
            msg: 'Image upload failed',
            type: 'error',
            show: false
          });
        }, 3000);
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${url}/hootdex/update-liquidity-pool-by-id-admin`, inputData)
      .then((res) => {
        if (res.data.status === 'error') {
          setAlert({
            msg: res.data.msg,
            type: 'error',
            show: true
          });
          setModalData([]);
          setInputData({});
          setTimeout(() => {
            setAlert({
              msg: res.data.msg,
              type: 'error',
              show: false
            });
          }, 4000);
        }
        if (res.data.status !== 'error') {
          window.scrollTo(0, 0);
          setInputData({
            createdBy: user.username,
            tokenName: '',
            totalToken: '',
            investementAmount: '',
            pecuCoin: '',
            tokenPrice: '',
            status: 'Pending',
            tokenSymbol: ''
          });
          setAlert({
            msg: 'Pool Created!',
            type: 'success',
            show: true
          });
          setTimeout(() => {
            setAlert({
              msg: 'Pool Created!',
              type: 'success',
              show: false
            });
            setModalData([]);
          }, 3000);
        }
      })
      .catch((err) => {
        setAlert({
          msg: 'There was an error!',
          type: 'error',
          show: true
        });
        setTimeout(() => {
          setAlert({
            msg: 'There was an error!',
            type: 'error',
            show: false
          });
        }, 3000);
      });
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    let changeData = { ...inputData };
    let name = e.target.name;
    let value = e.target.value;

    changeData[name] = value;
    setInputData(changeData);
  };
  useEffect(() => {
    if (modalData.length > 0) {
      let data = {};
      Object.keys(modalData[0]).map((key, index) => {
        data[key] = modalData[0][key];
      });
      // console.log(data);
      setInputData(data);
    }
  }, [modalData]);

  useEffect(() => {
    let pecuRate = currentValue;
    let changeData = { ...inputData };

    // let totalPecuCoin = inputData.investementAmount / pecuRate;
    // let tokenPrice = totalPecuCoin / inputData.totalToken;
    // changeData['pecuCoin'] = totalPecuCoin;
    // changeData['tokenPrice'] = tokenPrice;
    changeData['pecuRate'] = pecuRate;
    setInputData(changeData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(() => {
    let data = localStorage.getItem('hootdex_secretcookie');

    if (data) {
      setUser(JSON.parse(data));
      get_current_index_coin();
      fetchToken();
    }
  }, []);

  useEffect(() => {
    setInputData({ ...inputData, createdBy: user.username });
  }, [user]);

  useEffect(() => {
    get_crypto_Data();
  }, []);

  useEffect(() => {
    if (cryptoData.length > 0 && inputData.otherToken) {
      let wrap_token_price = cryptoData.filter(
        (e, i) => e.symbol == inputData.othertoken?.slice(1)
      );
      if (wrap_token_price && wrap_token_price[0]) {
        setWrapTokenPrice(wrap_token_price[0].price);
      }
    }
  }, [inputData.otherToken, cryptoData]);

  return (
    <div
      style={{
        minHeight: '200vh',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center'
      }}
    >
      {modalData.length > 0 ? (
        <div
          style={{
            minHeight: '80%',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: '100px'
          }}
        >
          {' '}
          {modalData.length > 0 &&
            modalData.map((item, i) => (
              <form
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',

                  width: '95%',
                  padding: '1rem'
                }}
                onSubmit={handleSubmit}
              >
                {Object.keys(item).map((key, index) => {
                  return (
                    item[key] !== '' &&
                    item[key] !== 0 && (
                      <div key={index}>
                        <label
                          className="label"
                          style={{
                            fontSize: '1rem',
                            textTransform: 'capitalize',
                            fontWeight: 'bold'
                          }}
                        >
                          {key.split('_').join(' ')}
                        </label>
                        <br></br>
                        <input
                          className="input"
                          name={key}
                          value={inputData[key]}
                          onChange={handleChange}
                          placeholder="Enter"
                          style={{ margin: '1rem' }}
                          required
                        ></input>
                      </div>
                    )
                  );
                })}

                {/* <label className="label">
                  Investement equivalent Pecu Coins. You have ({pecuCoins?.coin})
                  available
                </label>
                <input
                  className="input"
                  value={inputData.pecuCoin}
                  
                  type={"number"}
                  placeholder="Enter"
                  required
                ></input> */}
                <span>
                  {' '}
                  <button type="submit" className="submit-btn button">
                    Submit Request
                  </button>
                  <button
                    onClick={() => setModalData([])}
                    className="submit-btn button"
                    style={{ backgroundColor: 'red', color: 'white' }}
                  >
                    Cancel
                  </button>
                </span>
              </form>
            ))}
        </div>
      ) : (
        <div
          className="screen"
          style={{
            zIndex: '100',
            position: 'sticky',
            width: '100%',
            marginBottom: '1rem'
          }}
        >
          <div
            style={{
              width: '95%',
              minWidth: '340px'
            }}
          >
            <TableContainer
              sx={{
                backgroundColor: '#1a1b1f',
                mt: 5,
                borderRadius: '1rem'
              }}
              component={Paper}
            >
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#1a1b1f',
                  borderRadius: '15px'
                }}
              >
                <div className="pool-btn-container">
                  {MenuData.filter((e) => e.label !== 'ALL POOLS').map(
                    (e, i) => (
                      <Button
                        key={i}
                        className={`${'header-link chart-button pool-btn'}`}
                        onClick={() => {
                          navigate(e.path);
                        }}
                      >
                        {e.label}
                      </Button>
                    )
                  )}
                </div>
                <div className="pool-btn-container">
                  <a href="https://pecuscan.com/">
                    <Button
                      className={`${'header-link chart-button pool-btn'}`}
                    >
                      PECUSCAN
                    </Button>
                  </a>
                </div>
              </div>
              <p
                style={{
                  color: 'rgb(195, 197, 203)',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  backgroundColor: '#21242b',
                  left: '0px',
                  position: 'sticky',
                  padding: '1rem'
                }}
              >
                Top Pools
              </p>
              {loading && <LinearProgress color="inherit" />}
              {liquidityPools?.length ? (
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: ' 1px solid #1e2128'
                    }
                  }}
                >
                  <TableHead className="">
                    <TableRow className="">
                      <TableCell className="twhite">Name</TableCell>
                      <TableCell className="twhite" align="left">
                        Volume 24H
                      </TableCell>
                      <TableCell className="twhite" align="left">
                        TVL
                      </TableCell>
                      <TableCell className="twhite" align="left">
                        Edit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {liquidityPools?.length &&
                      liquidityPools?.map((each, index) => (
                        <TableRow key={each.id}>
                          <TableCell className="twhite" align="left">
                            <Link
                              to={`/pools/${each.id}`}
                              pecuCoins={PecuPrice}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {each?.holding_token_image && (
                                  <Avatar
                                    className="rounded"
                                    src={logoWrapper(each?.holding_token_image)}
                                    alt="token logo"
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                )}
                                {each?.project_token_symbol && (
                                  <Avatar
                                    className="rounded"
                                    src={`${url}/hootdex/images/${each?.img}`}
                                    alt="token logo"
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                )}

                                {each?.wrapToken_img && (
                                  <Avatar
                                    className="rounded"
                                    src={logoWrapper(each?.wrapToken_img)}
                                    alt={each?.wrap_token_symbol}
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                )}
                                <Avatar
                                  className="rounded"
                                  src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                                  alt="token logo"
                                  style={{ width: '20px', height: '20px' }}
                                />
                                <span
                                  style={{
                                    marginLeft: '1rem',
                                    fontSize: '14px'
                                  }}
                                >
                                  {each?.project_name}{' '}
                                  <small style={{ color: '#696c75' }}>
                                    (
                                    {`${
                                      each?.holding_token_symbol
                                        ? each?.holding_token_symbol
                                        : ''
                                    }${
                                      each?.project_token_symbol !== null &&
                                      each?.project_token_symbol !== ''
                                        ? '/' + each?.project_token_symbol
                                        : ''
                                    }${
                                      each?.wrap_token_symbol !== null &&
                                      each?.project_token_symbol !== ''
                                        ? `/${each.wrap_token_symbol}`
                                        : ''
                                    }/PECU`}
                                    )
                                  </small>
                                </span>
                              </div>
                            </Link>
                          </TableCell>

                          <TableCell className="twhite pink" align="left">
                            {each.volume
                              ? convertToInternationalCurrencySystem(
                                  (each.volume / each.pecuCoin) * PecuPrice
                                )
                              : '0.00'}
                          </TableCell>

                          <TableCell className="twhite blue" align="left">
                            $ {convertToInternationalCurrencySystem(each?.tvl)}
                          </TableCell>
                          <TableCell className="twhite blue" align="left">
                            {user && (user.tier === 0 || user.tier === 1) && (
                              <>
                                <button
                                  onClick={() => {
                                    setModalData([each]);
                                    setToken(each);
                                    // console.log(each);
                                  }}
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() => {
                                    handleDelist(each);
                                  }}
                                >
                                  DeList
                                </button>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <Skeleton
                  sx={{ bgcolor: '#21242b', mt: 1 }}
                  variant="rectangular"
                  margin={'1rem'}
                  height={100}
                />
              )}
            </TableContainer>
            <Transactions />
          </div>
        </div>
      )}
    </div>
  );
};

export default PoolTokensUpdate;
