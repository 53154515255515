import React, { memo, useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  ReferenceLine,
  CartesianGrid
} from 'recharts';
import {
  ToolTipStyle,
  ConstGraphBtn,
  GraphTop,
  ConstGraphActiveBtn
} from './chart-styles';
import { api as axios } from '../../api';
import { Skeleton, Button, Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  chartDays,
  chartHootdexVolume,
  chartTvlVolume
} from '../coinInfo/chartDays';
import { convertToInternationalCurrencySystem } from '../../utils';
const url = 'https://api.pecunovus.net';
export default memo(function Chart() {
  const [chartData, setChartData] = useState([]);
  const [chartDataHootdex, setChartDataHootdex] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(1);
  const [isActive, setActive] = useState(false);
  const [isActiveTvl, setActiveTvl] = useState(false);
  const getChatData = (filter) => {
    // let localData = [];

    setLoading(true);
    setFilter(filter);
    setChartData([]);
    axios
      .get(`${url}/wallet/get_change_tvl_${filter}`)
      .then((res) => {
        // res.data.forEach((e) => {
        //   setChartData((old) => [...old, e]);
        // });

        setChartData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        getChatData(filter);
        setLoading(false);
      });
  };
  const getChatDataHootdex = (filter) => {
    // let localData = [];

    setChartDataHootdex([]);
    axios
      .get(`${url}/crypto/volumeHootdex?interval=${filter}`)
      .then((res) => {
        // res.data.forEach((e) => {
        //   setChartData((old) => [...old, e]);
        // });
        setChartDataHootdex(res.data.volume);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const timeMappers = () => {
    let hoursData = [
      12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23
    ];

    let currentHour = new Date().getHours() + 1;
    let thisD = [];
    for (let i = 0; i < currentHour; i++) {
      thisD.push(hoursData[i]);
    }
    for (let i = 23; i > currentHour; i--) {
      thisD = [hoursData[i], ...thisD];
    }

    return thisD;
    //new data =[-12,-11,-10,-9,-8,-7,6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9]
  };
  function tConvert(time) {
    if (time) {
      time = parseInt(time);
      // If time format correct
      // Remove full string match value

      time =
        time < 12
          ? time + ' : 00 ' + 'AM'
          : time > 12
          ? (time % 12) + ' : 00 ' + 'PM'
          : 12 + ' : 00  '; // Set AM/PM

      //time = time % 12 || 12; // Adjust hours
    }
    return time; // return adjusted time or original string
  }

  useEffect(() => {
    getChatData('hourly');
    getChatDataHootdex('hour');
  }, []);
  const updateChart = (value) => {
    if (value == 1) {
      getChatData('hourly');
    } else if (value == 7) {
      getChatData('weekly');
    } else if (value == 30) {
      getChatData('monthly');
    } else if (value == 90) {
      getChatData('quaterly');
    } else if (value == 365) {
      getChatData('yearly');
    }
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      const { volume, time } = payload[0]?.payload; // Extracting data from the payload

      return (
        <div className="custom-tooltip" style={ToolTipStyle}>
          <span>{`Volume: ${Math.floor(volume / 1000)}k`}</span>
          <span>
            Timestamp:
            {` ${time}`}
          </span>
        </div>
      );
    }

    return null;
  };
  const CustomTooltipTvl = (props) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length > 0) {
      const { TVL, time } = payload[0]?.payload; // Extracting data from the payload

      return (
        <div className="custom-tooltip" style={ToolTipStyle}>
          <span>{`TVL: ${Math.floor(TVL)} `}</span>
          <span>Timestamp:{` ${label}`}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="graph-container">
      <Box
        sx={{
          backgroundColor: '#1a1b1f',
          borderRadius: '1rem',
          width: '100%',
          textAlign: 'center',
          margin: '5px',
          mt: '1rem'
        }}
      >
        <div style={{ flex: 1 }}>
          <Box sx={GraphTop}>
            <Box sx={{ fontSize: '20px', fontWeight: 'bold' }}>TVA</Box>
            <Box sx={{ display: 'flex', cursor: 'pointer' }}>
              {' '}
              {chartHootdexVolume.map((day) => (
                <Box
                  style={{
                    ...(isActive === day.value
                      ? ConstGraphActiveBtn
                      : ConstGraphBtn)
                  }}
                  key={day.value}
                  sx={{
                    fontSize: { xs: '10px !important', md: '16px !important' }
                  }}
                  onClick={() => {
                    setActive(day.value);
                    getChatDataHootdex(day.value);
                  }}
                >
                  {day.label.split(' ')[0] + day.label.split(' ')[1][0]}
                </Box>
              ))}
            </Box>
          </Box>

          <ResponsiveContainer
            width={'100%'}
            // height={window.screen.availHeight / 2.2}
            aspect={window?.screen?.availWidth > 600 ? 2 : 2}
          >
            <AreaChart
              data={chartDataHootdex}
              style={{ width: '100%' }}
              // margin={{ top: 10, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="rgb(118, 200, 168)"
                    stopOpacity={1.8}
                  />
                  <stop
                    offset="95%"
                    stopColor="rgb(118, 200, 168)"
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="rgb(118, 200, 168)"
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor="rgb(118, 200, 168)"
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <XAxis dataKey="time" />
              <YAxis
                unit="m"
                domain={['auto', 'auto']}
                tickCount={7}
                interval={0}
                tickFormatter={(value) => `${value / 1000000}`}
              />

              {chartDataHootdex
                .filter(
                  (entry, index) =>
                    index % Math.floor(chartDataHootdex.length / 7) === 0
                )
                .map((entry, index) => (
                  <ReferenceLine
                    key={`ref-line-${index}`}
                    y={entry.volume}
                    stroke="#25272C"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    alwaysShow={true}
                  />
                ))}
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="linear"
                dataKey="volume"
                stroke="rgb(118, 200, 168)"
                fillOpacity={1}
                fill="url(#colorUv)"
                style={{
                  position: 'absolute',
                  zIndex: '100',
                  width: '100%',
                  fill: 'linear-gradient(180deg, #76C8A8 -21.34%, rgba(31, 32, 35, 0.00) 100%)'
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Box>
      <Box
        sx={{
          borderRadius: '1rem',

          width: '100%',
          textAlign: 'center',
          margin: '5px',
          mt: '1rem',
          background: 'rgb(26, 27, 31)'
        }}
      >
        <div style={{ flex: 1 }}>
          <Box sx={GraphTop}>
            <Box sx={{ fontSize: '20px', fontWeight: 'bold' }}>TVL</Box>

            <Box sx={{ display: 'flex', cursor: 'pointer' }}>
              {chartHootdexVolume.map((day) => (
                <Box
                  style={{
                    ...(isActiveTvl === day.value
                      ? ConstGraphActiveBtn
                      : ConstGraphBtn)
                  }}
                  sx={{
                    fontSize: { xs: '10px !important', md: '16px !important' }
                  }}
                  key={day.value}
                  onClick={() => {
                    setActiveTvl(day.value);
                    getChatData(day.value);
                  }}
                >
                  {day.label.split(' ')[0] + day.label.split(' ')[1][0]}
                </Box>
              ))}
            </Box>
          </Box>

          <ResponsiveContainer
            width={'100%'}
            // height={window.screen.availHeight / 2.2}
            aspect={window?.screen?.availWidth > 600 ? 2 : 2}
          >
            <AreaChart
              data={chartData}
              style={{ width: '100%' }}
              // margin={{ top: 10, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="green" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="green" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="green" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="green" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="chart_date" />
              <YAxis
                unit="b"
                domain={[0, 10]}
                tickCount={9}
                interval={0}
                tickFormatter={(value) => `${Math.floor(value / 1000000000)}`}
              />
              {/* <YAxis /> */}
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <Tooltip content={<CustomTooltipTvl />} />
              <Area
                type="monotone"
                dataKey="TVL"
                stroke="green"
                fillOpacity={1}
                fill="url(#colorUv)"
                style={{
                  position: 'absolute',
                  zIndex: '100',
                  width: '100%'
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Box>
    </div>
  );
});
