import TokensImage from '../../modules/Forex/assets/tokens.svg';
// import PoolsImage from '../../modules/Forex/assets/'
import WrapsImage from '../../modules/Forex/assets/Group 521.svg';
import StocksImage from '../../modules/Forex/assets/Group 522.svg';
import CryptopairsImage from '../../modules/Forex/assets/Group 523.svg';
import DBTImage from '../../modules/Forex/assets/Group 527.svg';
import ProjectsImage from '../../modules/Forex/assets/Group 530.svg';
import NewsImage from '../../modules/Forex/assets/Group 528.svg';
import DelistedImage from '../../modules/Forex/assets/Group 529.svg';
import FxPairsImage from '../../modules/Forex/assets/Group 524.svg';

export const MenuData = [
  { label: 'SynthCryptos', path: '/', logo: TokensImage },
  { label: 'StableCoins', path: '/stableCoins', logo: TokensImage },
  { label: 'CryptoPairs', path: '/cryptoPairs', logo: CryptopairsImage },
  { label: 'Pools', path: '/AllPools', logo: TokensImage },
  { label: 'Wraps', path: '/WrapTokens', logo: WrapsImage },
  { label: 'HedgeTokens', path: '/hedgeTokens', logo: StocksImage },
  { label: 'FxPairs', path: '/fxPairs', logo: FxPairsImage },
  { label: 'DBTs', path: '/dbt', logo: DBTImage },
  { label: 'VentureTokens', path: '/VentureToken', logo: ProjectsImage },
  { label: 'FanTokens', path: '/FanTokens', logo: ProjectsImage },
  { label: 'News', path: '/News', logo: NewsImage },
  { label: 'Delisted ', path: '/DelistedToken', logo: DelistedImage }
];
