import React, { useEffect, useState } from 'react';
import {
  fetchStableCoin,
  fetchStableCoinPrice,
  fetchSynthetics,
  fetchSyntheticsPrice
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { api as axios } from '../../api';
import * as actionTypes from '../../store/actionTypes';
import TokenTable from '../../components/Tables/TokenTable/token.table';
import EthWallet from '../../components/nav/web3wallet';
import CommonMenu from '../../components/commonMenu/commonMenu';
import GenRevenue from '../../components/GenRevenue/GenRevenue';
import PecuGraph from '../../components/Graphs/PecuGraph';
const StableCoinsTable = () => {
  const dispatch = useDispatch();
  const loading = false;
  const {
    PecuPrice,
    synthetics,
    stableCoin,
    stableCoinPrice,
    UserInfo,
    isAdmin
  } = useSelector((state) => state);

  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState({});
  const [tvl, setTvl] = useState(84513557);
  const [priceList, setPriceList] = useState([]);
  const [blinkUpList, setBlinkUpList] = useState([]);
  const [blinkDownList, setBlinkDownList] = useState([]);
  const [stableCoinPriceTick, setStableCoinsPrice] = useState(stableCoinPrice);
  const [trendHourly, setTrendHourly] = useState({});
  const [coinVolume, setCoinVolume] = useState(114826.00453596);
  const [trend, setTrend] = useState({});
  const [priceDown, setPriceDown] = useState(false);
  const [searchToken, setSearchToken] = useState('');
  const filterToken = (searchToken) => {
    if (searchToken && searchToken !== '') {
      return stableCoin?.filter(
        (e) =>
          e.symbol.toLowerCase().includes(searchToken.toLowerCase()) ||
          e.name.toLowerCase().includes(searchToken.toLowerCase())
      );
    } else {
      return stableCoin;
    }
  };

  const get_change_index_coin_hourly = () => {
    axios.get(`/wallet/get_change_index_coin_hourly`).then((res) => {
      setTrendHourly(res.data);
    });
  };
  const calculate_daily_volume = () => {
    axios.get(`/wallet/calculate_daily_volume`).then((res) => {
      setCoinVolume(res.data.total_volume);
    });
  };
  const get_tvl = () => {
    axios.get(`/wallet/tvl`).then((res) => {
      setTvl(res.data.tvl);
    });
  };
  const get_change_index_coin = () => {
    axios.get(`/wallet/get_change_index_coin`).then((res) => {
      setTrend(res.data);
    });
  };
  function getFormattedTime(timestampString) {
    const timestamp = new Date(timestampString);
    timestamp.setHours(timestamp.getHours());
    return timestamp;
  }

  useEffect(() => {
    get_change_index_coin_hourly();
    get_change_index_coin();
    calculate_daily_volume();
    get_tvl();
  }, []);

  useEffect(() => {
    // Compare current prices with the previous prices
    if (priceList.length > 0) {
      const updatedPriceList = Object.entries(stableCoinPriceTick)
        .filter(([token, price]) => {
          const prevPrice = priceList.find((item) => item.token === token);
          return prevPrice && prevPrice.price !== price;
        })
        .map(([token, price]) => ({ token, price }));

      if (updatedPriceList.length > 0) {
        const newBlinkUpList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.token === item.token
          );
          return prevPrice && item.price > prevPrice.price;
        });

        const newBlinkDownList = updatedPriceList.filter((item) => {
          const prevPrice = priceList.find(
            (prevItem) => prevItem.token === item.token
          );
          return prevPrice && item.price < prevPrice.price;
        });

        setPriceList((prevPriceList) => [
          ...prevPriceList.filter(
            (item) =>
              !updatedPriceList.some(
                (updatedItem) => updatedItem.token === item.token
              )
          ),
          ...updatedPriceList
        ]);

        setBlinkUpList(newBlinkUpList.map((item) => item.token));
        setBlinkDownList(newBlinkDownList.map((item) => item.token));

        setTimeout(() => {
          setBlinkUpList([]);
          setBlinkDownList([]);
        }, 500);
      }
    } else {
      setPriceList(
        Object.entries(stableCoinPrice).map(([token, price]) => ({
          token,
          price
        }))
      );
    }
  }, [stableCoinPriceTick, priceList]);

  useEffect(() => {
    fetchStableCoin(dispatch, actionTypes.FETCH_STABLE_COIN);
    fetchStableCoinPrice(dispatch, actionTypes.FETCH_STABLE_COIN_PRICE);

    const intervalId = setInterval(() => {
      calculate_daily_volume();
      get_change_index_coin();
      get_change_index_coin_hourly();
      fetchStableCoin(dispatch, actionTypes.FETCH_STABLE_COIN);
      fetchStableCoinPrice(dispatch, actionTypes.FETCH_STABLE_COIN_PRICE);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setStableCoinsPrice(stableCoinPrice);
  }, [stableCoinPrice, stableCoinPriceTick]);

  return (
    <div
      className="screen"
      style={{
        zIndex: '100',
        position: 'sticky',
        width: '100%',
        marginTop: '4rem',
        marginBottom: '1rem'
      }}
    >
      <PecuGraph />

      <div
        style={{
          width: '95%',
          minWidth: '340px'
        }}
        className="res-token"
      >
        <GenRevenue />

        <CommonMenu label={'StableCoins'} setSearch={setSearchToken} />
        <TokenTable
          loading={loading}
          token={stableCoin}
          blinkUpList={blinkUpList}
          blinkDownList={blinkDownList}
          href={'StableCoin'}
          PecuPrice={PecuPrice}
          swappable={false}
          getFormattedTime={getFormattedTime}
          showPecu={false}
        />
      </div>
    </div>
  );
};

export default React.memo(StableCoinsTable);
