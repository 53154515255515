import { api as axios } from '../api';
import React, { useEffect, useState } from 'react';
import CommonMenu from '../components/commonMenu/commonMenu';
import url from '../serverUrl';
import './news.css';
import {
  // Avatar,
  // Button,
  // LinearProgress,
  // Paper,
  // Skeleton,
  // Table,
  // TableBody,
  // TableCell,
  // tableCellClasses,
  // TableContainer,
  // TableHead,
  // TableRow,
  Box,
  Skeleton
} from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
// import { MenuData } from '../components/Tables/menuData';
function News() {
  // const loading = false;
  const { stocks } = useSelector((state) => state);
  // const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [indices, setIndices] = useState([]);
  const [loading, setLoading] = useState(true);
  const indicesSymbol = [
    'DJI',
    'SPX',
    'NDX',
    'RUT',
    'SP600',
    'VIX',
    'FTSE',
    'FCHI',
    'GDAXI',
    'HSI',
    'N225',
    'OMX',
    'STOXX50E'
  ];
  const fetchNews = () => {
    setLoading(true);
    axios
      .get(`${url}/crypto/news`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then((res) => {
        setNewsData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchNews();
  }, []);
  useEffect(() => {
    if (indices.length === 0) {
      setIndices(
        stocks.filter((e) => indicesSymbol.includes(e.symbol)).reverse()
      );
    }
  }, [stocks]);

  function convertDateFormat(inputDate) {
    const parsedDate = new Date(inputDate);
    const monthAbbreviations = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const day = parsedDate.getDate();
    const month = monthAbbreviations[parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }

  const topNewsDiv = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
  };

  const NewsHeading = {
    color: '#FFF',

    fontWeight: 600,
    // "lineHeight": "150%",
    marginBottom: '0.5rem'
  };
  const NewsPara = {
    color: '#686F7E',

    fontWeight: 400,

    marginBottom: '0.5rem'
  };

  const NewsUrl = {
    color: '#76C8A8 !important',

    fontSize: '14px',

    fontWeight: 600,
    lineHeight: '100%',
    textDecorationLine: 'underline',
    marginBottom: '0.5rem'
  };
  const Tags = {
    display: 'flex',

    height: '26px',
    padding: '0px 8px',
    alignItems: 'flex-start',
    gap: '4px',

    borderRadius: '4px',
    background: '#1C2B24',
    color: '#76C8A8'
  };
  const DateTime = {
    color: '#686F7E',

    fontWeight: 400
  };
  return (
    <>
      <div
        className="screen"
        style={{
          zIndex: '100',
          position: 'sticky',
          width: '100%',
          marginBottom: '1rem',
          marginTop: '2rem'
        }}
      >
        <div
          style={{
            width: '100%',
            minWidth: '340px'
          }}
        >
          <CommonMenu label={'News'} newsPage={true} />
          {loading && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ marginBottom: '1rem' }}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={120}
                  />
                  <Skeleton animation="wave" height={20} width="80%" />
                  <Skeleton animation="wave" height={20} width="60%" />
                  <Skeleton animation="wave" height={20} width="90%" />
                </Box>
              ))}
            </Box>
          )}
          {!loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row'
                },
                marginTop: '1rem',
                justifyContent: 'center',
                margin: { xs: '0 0', sm: '0 0', md: '0 6%', lg: '0 6%' },
                gap: '40px'
              }}
              className="news_section"
            >
              <div className="news-c1" style={{ flex: ' 0 0 38%' }}>
                <div className="news_wrapper2" style={{ margin: '0 0.5rem' }}>
                  {newsData.slice(0, 18).map((item) => (
                    <div className="news-r1" key={item.id}>
                      <Box sx={topNewsDiv}>
                        <Box sx={Tags}>
                          {item.tags.length > 0
                            ? item?.tags[0]?.split(' ')[0]
                            : 'Buisness'}
                        </Box>
                        <Box sx={DateTime}>
                          {convertDateFormat(item.crawlDate)}
                        </Box>
                      </Box>
                      <Box sx={NewsHeading}>
                        {item.title.length > 65
                          ? `${item.title.slice(0, 65)}...`
                          : item.title}
                      </Box>
                      <Box sx={NewsPara}>
                        {item.description.length > 200
                          ? `${item.description
                              .slice(0, 200)
                              .replace(/^\/PRNewswire\/ -- /, '')}...`
                          : item.description.replace(/^\/PRNewswire\/ -- /, '')}
                      </Box>

                      <Box
                        sx={{
                          borderTop: '1px solid  #46474B;',
                          paddingTop: '1rem'
                        }}
                      >
                        <a
                          className="readMore"
                          style={NewsUrl}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read more{' '}
                          <img
                            alt=""
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '5px'
                            }}
                            src={require('../assets/images/arrow-right.png')}
                          />
                        </a>
                      </Box>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="news-c2"
                style={{ margin: '0 0.5rem', flex: ' 0 0 62%' }}
              >
                <div className="news-r1-news">
                  <Box sx={{ padding: '20px' }}>
                    <iframe
                      className="video_News"
                      src="https://www.youtube.com/embed/9Auq9mYxFEE"
                      title="Watch Sky News live"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      style={{
                        margin: 5,
                        marginLeft: -5,
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    ></iframe>
                  </Box>
                  {newsData.slice(15, 16).map((item, index) => (
                    <Box className="news-r1 " key={item.id}>
                      <Box sx={topNewsDiv}>
                        <Box sx={Tags}>
                          {item.tags.length > 0
                            ? item?.tags[0]?.split(' ')[0]
                            : 'Buisness'}
                        </Box>
                      </Box>
                      <Box sx={NewsHeading}>
                        {item.title.length > 85
                          ? `${item.title.slice(0, 85)}...`
                          : item.title}
                      </Box>
                      <Box sx={NewsPara}>
                        {item.description.length > 200
                          ? `${item.description
                              .slice(0, 200)
                              .replace(/^\/PRNewswire\/ -- /, '')}...`
                          : item.description.replace(/^\/PRNewswire\/ -- /, '')}
                      </Box>

                      <Box sx={DateTime}>
                        {convertDateFormat(item.crawlDate)}
                      </Box>

                      <Box
                        sx={{
                          borderTop: '1px solid  #46474B;',
                          paddingTop: '1rem'
                        }}
                      >
                        <a
                          className="readMore"
                          style={NewsUrl}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read more{' '}
                          <img
                            alt=""
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '5px'
                            }}
                            src={require('../assets/images/arrow-right.png')}
                          />
                        </a>
                      </Box>
                    </Box>
                  ))}
                </div>

                <h2 style={{ margin: '2rem 20px', marginBottom: '1rem' }}>
                  Most Latest News
                </h2>
                <Box
                  className="news_wrapper2"
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'flex !important'
                    },
                    gap: '20px'
                  }}
                >
                  {newsData.slice(12, 14).map((item) => (
                    <Box className="news-r1 " key={item.id} style={{}}>
                      <Box sx={topNewsDiv}>
                        <Box sx={Tags}>
                          {item.tags.length > 0
                            ? item?.tags[0]?.split(' ')[0]
                            : 'Buisness'}
                        </Box>
                        <Box sx={DateTime}>
                          {convertDateFormat(item.crawlDate)}
                        </Box>
                      </Box>
                      <Box sx={NewsHeading}>
                        {item.title.length > 65
                          ? `${item.title.slice(0, 65)}...`
                          : item.title}
                      </Box>
                      <Box sx={NewsPara}>
                        {item.description.length > 200
                          ? `${item.description
                              .slice(0, 200)
                              .replace(/^\/PRNewswire\/ -- /, '')}...`
                          : item.description.replace(/^\/PRNewswire\/ -- /, '')}
                      </Box>

                      <Box
                        sx={{
                          borderTop: '1px solid  #46474B;',
                          paddingTop: '1rem'
                        }}
                      >
                        <a
                          className="readMore"
                          style={NewsUrl}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read more{' '}
                          <img
                            alt=""
                            style={{
                              verticalAlign: 'middle',
                              marginLeft: '5px'
                            }}
                            src={require('../assets/images/arrow-right.png')}
                          />
                        </a>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </div>
            </Box>
          )}
        </div>
      </div>
    </>
  );
}

export default News;
