export const chartDays = [
  {
    label: '24 Hours',
    value: 1
  },
  {
    label: '30 Days',
    value: 30
  },
  {
    label: '3 Months',
    value: 90
  },
  {
    label: '1 Year',
    value: 365
  }
];
export const chartHootdexVolume = [
  {
    label: '1 Hours',
    value: 'hour'
  },
  {
    label: '24 Hours',
    value: 'day'
  },
  {
    label: '7 Days',
    value: 'week'
  },
  {
    label: '1 Months',
    value: 'month'
  },
  {
    label: '1 Year',
    value: 'year'
  }
];
export const chartTvlVolume = [
  {
    label: '1 Hours',
    value: 'hourly'
  },
  {
    label: '7 Days',
    value: 'weekly'
  },
  {
    label: '1 Months',
    value: 'monthly'
  },
  {
    label: '3 Months',
    value: 'quaterly'
  },
  {
    label: '1 Year',
    value: 'yearly'
  }
];
export const chartDays2 = [
  {
    label: '24h',
    value: 1
  },
  {
    label: '7d',
    value: 7
  },
  {
    label: '30d',
    value: 30
  },
  {
    label: '3m',
    value: 90
  },
  {
    label: '1y',
    value: 365
  }
];
