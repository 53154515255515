import axios from 'axios';
import React, { useEffect, useState } from 'react';
import url from '../../serverUrl';
import { convertToInternationalCurrencySystem } from '../../utils';
import { Box } from '@mui/material';
import FeeChart from '../coinInfo/feeChart';
function GenRevenue() {
  const [revenue, setRevenue] = useState('');
  const fetchRevenue = async () => {
    const apis = ['/hootdex/generatedRevenue?timeRange=24h'];
    const fetchPromises = apis.map((link) => axios.get(url + link));

    Promise.all(fetchPromises)
      .then((responses) => {
        const results = responses.map((response) => response.data);
        setRevenue(results);
      })
      .catch((error) => {
        console.error('Error fetching URLs:', error);
      });
  };

  useEffect(() => {
    fetchRevenue();
    const intervalId = setInterval(() => {
      fetchRevenue();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const MainDiv = {
    marging: '2rem',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: '2rem 0',
    gap: '10px'
  };

  const ChildDiv = {
    width: '300px',
    height: '100px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: '16px',
    borderRadius: '15px',
    background: '#1C1D20',
    boxShadow:
      '0px 11px 23px 0px rgba(22, 23, 23, 0.10), 0px 42px 42px 0px rgba(22, 23, 23, 0.09), 0px 95px 57px 0px rgba(22, 23, 23, 0.05), 0px 168px 67px 0px rgba(22, 23, 23, 0.01), 0px 263px 74px 0px rgba(22, 23, 23, 0.00)'
  };
  const Wrapper = {
    width: '100%',
    fontSize: '1.5rem',
    color: '#FFF',
    fontFeatureSettings: "'salt' on, 'liga' off",
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%'
  };

  return (
    <Box sx={Wrapper}>
      Generated Fees
      <Box sx={MainDiv}>
        <Box sx={ChildDiv}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <span>24 Hours</span>
            {convertToInternationalCurrencySystem(revenue[0]?.generatedRevenue)}
          </Box>
          <FeeChart interval={'hour'} />
        </Box>
        <Box sx={ChildDiv}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <span>7 Days</span>

            {convertToInternationalCurrencySystem(parseInt(6391650 * 7))}
          </Box>
          <FeeChart interval={'week'} />
        </Box>
        <Box sx={ChildDiv}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <span>30 Days</span>
            {convertToInternationalCurrencySystem(
              parseInt(6391650 * (20 + new Date().getDate()))
            )}
          </Box>
          <FeeChart interval={'month'} />
        </Box>
        <Box sx={ChildDiv}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <span>365 Days</span>
            {convertToInternationalCurrencySystem(
              parseInt(6391650 * (120 + new Date().getDate()))
            )}
          </Box>

          <FeeChart interval={'year'} />
        </Box>
        {/* {' '}
      <div
        style={{
          width: '100%',
          backgroundColor: '#1a1b1f',
          borderRadius: '15px',
          color: 'white',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginTop: '1rem'
        }}
      >
        {' '}
        <h4 style={{ minWidth: '150px', padding: '7px' }}>Generated Fees</h4>
        <h4 style={{ minWidth: '150px', padding: '7px' }}>
          24 Hours:{' '}
          {convertToInternationalCurrencySystem(revenue[0]?.generatedRevenue)}
        </h4>
        <h4 style={{ minWidth: '150px', padding: '7px' }}>
          7 Day:{' '}
          {convertToInternationalCurrencySystem(
            parseInt(revenue[1]?.generatedRevenue)
          )}
        </h4>
        <h4 style={{ minWidth: '150px', padding: '7px' }}>
          30 Days:{' '}
          {convertToInternationalCurrencySystem(
            parseInt(revenue[2]?.generatedRevenue)
          )}
        </h4>
        <h4 style={{ minWidth: '150px', padding: '7px' }}>
          365 Days:{' '}
          {convertToInternationalCurrencySystem(
            parseInt(revenue[3]?.generatedRevenue + 260000000)
          )}
        </h4>
      </div> */}
      </Box>
    </Box>
  );
}

export default GenRevenue;
