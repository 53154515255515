import { Edit } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React, { useState } from 'react';

function CreateToken({ onChangeHandler, account, saveLogo, submitHandler }) {
  const formStyle = {
    width: '600px', // Adjust the width as needed
    margin: '50px auto',
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    minHeight: '500px'
  };

  const inputStyle = {
    marginBottom: '30px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '3px'
  };

  return (
    <div>
      <form
        style={formStyle}
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <h3 style={{ color: 'black', textAlign: 'center' }}>
          Create Hedge Token
        </h3>
        <div
          style={{
            position: 'relative',

            width: '100px'
          }}
        >
          <Avatar
            src={account?.logoRaw}
            style={{ width: '100px', height: '100px' }}
          />
          <input
            id="fileInput"
            className="input"
            onChange={saveLogo}
            type="file"
            placeholder="Enter"
            hidden={true}
          ></input>

          <label
            for="fileInput"
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '0px',
              cursor: 'pointer'
            }}
          >
            <Edit color="black" />
          </label>
        </div>

        <input
          type="text"
          placeholder="Token Name"
          name="tokenName"
          value={account?.tokenName}
          onChange={onChangeHandler}
          style={inputStyle}
        />
        <input
          type="text"
          placeholder="identity Symbol"
          name="identitySymbol"
          onChange={onChangeHandler}
          value={account?.identitySymbol}
          style={inputStyle}
        />
        <input
          type="text"
          placeholder="Linking Symbol"
          name="linkingSymbol"
          onChange={onChangeHandler}
          value={account?.linkingSymbol}
          style={inputStyle}
        />
        <input
          type="email"
          placeholder="holding account"
          name="owner"
          onChange={onChangeHandler}
          value={account?.owner}
          style={inputStyle}
        />
        <input
          type="email"
          placeholder="minter"
          name="minter"
          onChange={onChangeHandler}
          value={account?.minter}
          style={inputStyle}
        />
        <input
          type="text"
          placeholder="Amount"
          name="tokenAmount"
          onChange={onChangeHandler}
          value={account?.amount}
          style={inputStyle}
        />
        <input
          type="text"
          placeholder="Price"
          name="price"
          onChange={onChangeHandler}
          value={account?.price}
          style={inputStyle}
        />
        <input
          type="text"
          placeholder="Link"
          name="link"
          onChange={onChangeHandler}
          value={account?.link}
          style={inputStyle}
        />
        <textarea
          type="text"
          placeholder="Description"
          name="description"
          onChange={onChangeHandler}
          value={account?.description}
          style={inputStyle}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'flex-end'
          }}
        >
          {' '}
          <button
            type="submit"
            style={{
              width: '130px',
              border: '1px solid grey',
              color: 'black',
              padding: '10px',
              margin: '5px',
              borderRadius: '3px',
              cursor: 'pointer'
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            style={{
              width: '130px',
              backgroundColor: 'orange',
              color: 'white',
              padding: '10px',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer',
              margin: '5px'
            }}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateToken;
