import {
  Box,
  Dialog,
  Alert,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import url from '../../serverUrl';

const EthWallet = ({ open, setOpen, requestAccount, fetchWallet }) => {
  const { WalletInfo } = useSelector((state) => state);
  const receiverAddress = '0x415515d4D19a3AFDBD678f1BeeE81acEAF997004';
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [usdValue, setUsdValue] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    loading: false
  });
  const [email, setEmail] = useState('');
  const handleClose = () => {
    setOpen(false);
    setShowForm(false);
  };

  const swapPecu = async (amount, transaction) => {
    const walletExist = localStorage.getItem('hootdex_secretcookie_wallet');
    const token = JSON.parse(walletExist);
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const payload = {
      ethQuantity: amount,
      transactionHash: transaction.transactionHash,
      transaction: transaction
    };
    const response = await axios.post(`${url}/api/v2/wallet/ethSwap`, payload, {
      headers
    });
    // alert(JSON.stringify(response));
    setOpen(false);
  };

  const transferETH = async () => {
    try {
      // Clear previous errors
      setError(null);

      if (!receiverAddress || !amount) {
        throw new Error('Please enter both receiver address and amount.');
      }

      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);

        // Request account access if needed
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        // Get the selected address from Metamask
        const senderAddress = (await web3.eth.getAccounts())[0];

        // Perform the ETH transfer
        const transaction = await web3.eth.sendTransaction({
          to: receiverAddress,
          from: senderAddress,
          value: web3.utils.toWei(amount, 'ether')
        });

        alert(
          `Transfer successful! Transaction hash: ${transaction.transactionHash}`
        );

        // Fetch ETH to USD rate
        swapPecu(amount, transaction);
        // Calculate equivalent value in USD
      } else {
        throw new Error(
          'Metamask not detected. Please install and unlock Metamask.'
        );
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const availableWallet = [
    {
      name: 'Coinbase Wallet',
      icon: 'https://www.yadawallets.com/wp-content/uploads/2020/11/Coinbase-dapp-wallet-logo.png'
    },
    {
      name: 'WalletConnect',
      icon: 'https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png'
    },
    {
      name: 'Fortmatic',
      icon: 'https://media.glassdoor.com/sqll/3204214/fortmatic-squarelogo-1582267622143.png'
    }
  ];
  const handleSubmit = () => {
    if (email) {
      setLoading(true);
      axios
        .post(`${url}/crypto/connect-wallet`, {
          access_key: email
        })
        .then((res) => {
          setLoading(false);
          if (res.data) {
            console.log(res.data.token);
            const token = JSON.stringify(res.data.token);
            localStorage.setItem(
              'hootdex_secretcookie_wallet',
              JSON.stringify(res.data.token)
            );
            fetchWallet();
            setAlert({
              msg: 'Wallet Connected!',
              type: 'success',
              show: true
            });
            setTimeout(() => {
              handleClose();
              setAlert({
                msg: 'Wallet Connected!',
                type: 'success',
                show: false
              });
              document.cookie = `walletToken=${token}; domain=.hootdex.net; path=/;`;
              window.location.reload();
            }, 1000);
          } else {
            setTimeout(() => {
              setAlert({
                msg: 'No account found with this key!',
                type: 'error',
                show: false
              });
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          setAlert({
            msg: 'No account found with this key!',
            type: 'error',
            show: true
          });
          setTimeout(() => {
            setAlert({
              msg: 'No account found with this key!',
              type: 'error',
              show: false
            });
          }, 3000);
        });
    } else {
      setAlert({
        msg: 'Enter your private key!',
        type: 'error',
        show: true
      });
      setTimeout(() => {
        setAlert({
          msg: 'Enter your private key!',
          type: 'error',
          show: false
        });
      }, 3000);
    }
  };
  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      {WalletInfo ? (
        <div
          style={{
            padding: '1rem',
            height: '300px',
            backgroundColor: '#19191a',
            color: 'white',
            width: '400px',
            height: 'fit-content'
          }}
        >
          <h4>Add ETH to PECU WALLET</h4>

          <label htmlFor="amount">Amount (ETH):</label>
          <input
            type="text"
            id="amount"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            style={{
              padding: '0.4rem',
              fontSize: '1rem',
              width: '95%',
              margin: '1rem 0 1rem 0'
            }}
          />
          <br></br>
          <br></br>
          <br></br>

          <button className="transferSwapBtn" onClick={transferETH}>
            Transfer ETH
          </button>

          {usdValue && (
            <div style={{ marginTop: '10px' }}>
              Equivalent value in USD: ${usdValue}
            </div>
          )}
          {error && (
            <div style={{ color: 'red', marginTop: '10px', width: '300px' }}>
              {error}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            padding: '1rem',
            height: '300px',
            backgroundColor: '#19191a',
            color: 'white',
            width: '400px',
            height: 'fit-content'
          }}
        >
          <Dialog
            open={showForm}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }
            }}
            sx={{ zIndex: 2002 }}
            className="border"
            onClose={() => {
              setShowForm(false);
            }}
          >
            <Box
              sx={{
                width: 400,
                p: 2,
                borderRadius: '1rem',
                backgroundColor: '#040b1e'
              }}
            >
              <div
                className="twhite tcenter fontS22"
                id="alert-dialog-title"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '2px'
                }}
              >
                Pecu Novus
                <img
                  style={{ width: '30px', marginLeft: '1rem' }}
                  src="https://dev.hootdex.org/hootdex/images/pecuCoin.png"
                  alt=""
                />
              </div>
              <Divider sx={{ backgroundColor: '#091e17', height: '2px' }} />
              {loading && <LinearProgress />}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Collapse
                  in={alert.show}
                  sx={{ maxWidth: 400, position: 'fixed' }}
                >
                  <Alert
                    variant="outlined"
                    severity={alert.type}
                    sx={{
                      mb: 2,
                      backgroundColor: 'white',
                      fontSize: '18px'
                    }}
                  >
                    {alert.msg}
                  </Alert>
                </Collapse>
              </div>
              <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                    <p style={{ color: 'white', marginLeft: '15px' }}>
                      Wallet Name
                    </p>
                    <input
                      className="border inputfWW"
                      type="text"
                      name="walletName"
                    />
                  </DialogContentText> */}
                <DialogContentText id="alert-dialog-description" sx={{ mt: 1 }}>
                  <p style={{ color: 'white', marginLeft: '15px' }}>
                    Access Key
                  </p>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="border inputfWW"
                    type="text"
                    name="email"
                    required
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="border"
                  sx={{ color: 'white' }}
                  onClick={handleSubmit}
                  autoFocus
                >
                  Connect
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#0080003b',
              padding: '1rem',
              margin: '0.7rem',
              borderRadius: '1rem',
              cursor: 'pointer',
              opacity: '0.8'
            }}
          >
            <div style={{ width: '300px', fontSize: '1.2rem' }}>
              {'Please connect your wallet'}
            </div>
          </div>
          {availableWallet.map((e) => (
            <div
              className="wallet"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#edeef2',
                padding: '1rem',
                margin: '0.7rem',
                borderRadius: '1rem',
                border: '1px solid grey',
                cursor: 'pointer',
                opacity: '0.8'
              }}
              onClick={() => {
                if (e.name === 'Pecu Novus') {
                  setShowForm(true);
                }
                if (e.name === 'MetaMask') {
                  requestAccount();
                }
              }}
            >
              <p style={{ fontWeight: '600', color: 'black' }}>{e.name}</p>
              <img
                style={{ width: '30px' }}
                src={`${e.icon}`}
                alt="wallet icon"
              />
            </div>
          ))}
        </div>
      )}
    </Dialog>
  );
};

export default EthWallet;
