import { Edit } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React from 'react';

function CreateToken({
  setActiveStep,
  onChangeHandler,
  account,
  submitHandler
}) {
  const formStyle = {
    width: '600px', // Adjust the width as needed
    margin: '50px auto',
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '500px',
    color: 'black'
  };

  const inputStyle = {
    marginBottom: '30px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '3px'
  };

  return (
    <div>
      <form style={formStyle} onSubmit={(e) => e.preventDefault()}>
        <div
          style={{
            position: 'relative',

            width: '100px'
          }}
        >
          <Avatar
            src={account?.logoRaw}
            style={{ width: '100px', height: '100px' }}
          />
          <input
            id="fileInput"
            className="input"
            //onChange={saveFileDerivatives}
            type="file"
            placeholder="Enter"
            hidden={true}
          ></input>

          <label
            for="fileInput"
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '0px',
              cursor: 'pointer'
            }}
          ></label>
        </div>
        <label>Token Symbol</label>
        <input
          type="text"
          placeholder="symbol"
          name="symbol"
          style={inputStyle}
          value={account?.symbol}
          onChange={onChangeHandler}
        />
        <label>Token Name</label>
        <input
          type="text"
          placeholder="name"
          name="tokenName"
          value={account?.tokenName}
          style={inputStyle}
          onChange={onChangeHandler}
        />
        <label>Token Description</label>
        <textarea
          type="text"
          placeholder="Description"
          name="description"
          value={account?.description}
          rows={6}
          style={inputStyle}
          onChange={onChangeHandler}
        />
        <label>Token Link</label>
        <input
          type="text"
          placeholder="link"
          name="link"
          style={inputStyle}
          value={account?.link}
          onChange={onChangeHandler}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'flex-end'
          }}
        >
          {' '}
          <button
            type="submit"
            style={{
              width: '130px',
              border: '1px solid grey',
              color: 'black',
              padding: '10px',
              margin: '5px',
              borderRadius: '3px',
              cursor: 'pointer'
            }}
            onClick={() => {
              setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
            }}
          >
            Back
          </button>
          <button
            type="submit"
            style={{
              width: '130px',
              backgroundColor: 'orange',
              color: 'white',
              padding: '10px',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer',
              margin: '5px'
            }}
            onClick={submitHandler}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateToken;
