import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuData } from '../Tables/menuData';
import SearchIcon from '@mui/icons-material/Search';
function CommonMenu({ label, setSearch, newsPage }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  return (
    <div>
      <div className="main_nav_container">
        <div className="navigation_container">
          <button className="fxpairs_static_btn">{label}</button>

          {MenuData.filter((e) => e.label !== label).map((e, i) => (
            <button
              onClick={() => {
                navigate(e.path);
              }}
              className="navigation_btns"
            >
              <img src={e.logo}></img>
              <span>{e.label}</span>
            </button>
          ))}
        </div>

        <div className="inside_container_for_responsiveness">
          <div className="search_input_and_icon_container">
            <SearchIcon className="search_icon" />

            <input
              className="new_search_input"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              style={{width:'80%', borderRadius:50}}
            ></input>
          </div>

          <div className="pecuscan_container">
            <a href="https://pecuscan.com/" target={'_blank'} rel="noreferrer">
              Pecuscan
            </a>
          </div>
        </div>
      </div>
      <p
        style={{
          color: 'white',
          fontSize: '1.5em',
          fontWeight: 'bolder',
          marginTop: '1rem'
        }}
      >
        {newsPage && newsPage == true ? '' : label}
      </p>
    </div>
  );
}

export default CommonMenu;
