import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Grid,
  LinearProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PoolGraph from '../../components/Graphs/PoolGraph';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../../store/actionTypes';
import {
  convertToInternationalCurrencySystem,
  get_crypto_Data,
  logoWrapper,
  PecuPriceHttpRequest
} from '../../utils';

export default function StableCoinPoolPage() {
  const { PecuPrice } = useSelector((state) => state);
  const { id } = useParams();
  const [pool, setPool] = useState({});
  const {
    holding_token_amount,
    holding_token_image,
    holding_token_price,
    holding_token_symbol,
    wrapToken_img,
    wrap_token_amount,
    wrap_token_symbol,
    project_token_amount,
    project_token_price,
    project_token_symbol,
    img,
    pecu_amount,
    tvl
  } = pool || {};
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });

  const fetchPoolData = (id) => {
    setLoading(true);
    axios
      .get(`${url}/crypto/liquidity_pool_stable_coin/${id}`)
      .then((res) => {
        let poolData = res.data.data;
        setPool(poolData);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    get_crypto_Data(dispatch, actionTypes.CRYPTO_DATA);
    PecuPriceHttpRequest(dispatch, actionTypes.PECU_PRICE);
    fetchPoolData(id);
  }, []);

  if (loading) {
    return (
      <div>
        <LinearProgress sx={{ backgroundColor: 'grey' }} />
      </div>
    );
  }

  return (
    <Box sx={{ paddingLeft: { xs: '2rem', md: '4rem' } }}>
      <Grid
        width="100%"
        container
        spacing={5}
        // padding={{ xs: 0, md: 0 }}
        // mb={1}
      >
        <Grid item xs={12} mt={4}>
          {/* <div style={{ position: 'sticky' }}>
              <p
                style={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: 'white'
                }}
              >
                
                {'Home  >'}
                <Link
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                  to="/pools"
                >
                  Pools
                </Link>
                {'>'}
                {holding_token_symbol && (
                  <Link
                    style={{
                      fontSize: '1rem',
                      fontWeight: '500',
                      color: 'white'
                    }}
                    to={`/holding-token/${holding_token_symbol}`}
                    state={{
                      token_price: holding_token_price,
                      symbol: holding_token_symbol
                    }}
                  >
                    {holding_token_symbol}
                  </Link>
                )}
                <Link
                  to={`/VentureToken/${project_token_symbol}`}
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                  state={{
                    token_price: project_token_price,
                    symbol: project_token_symbol
                  }}
                >
                  {project_token_symbol}
                </Link>
                {`${
                  wrap_token_symbol
                    ? `/ ${wrap_token_symbol_parser(wrap_token_symbol)}`
                    : null
                } / PECU `}
              </p>
            </div> */}
        </Grid>

        <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
          <div
            style={{
              backgroundColor: 'rgb(25, 27, 31)',
              borderRadius: '20px',
              height: '100%',
              padding: '1rem',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
            className="shadowGrey"
          >
            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                // margin: '0 0 0 1rem',
                width: 'fit-content'
              }}
            >
              <Avatar
                className="rounded"
                src={`https://api.pecunovus.net/hootdex/images/image-1719842511599.png`}
                alt="token logo"
                style={{ width: '20px', height: '20px' }}
              />
              {pool?.tokens?.map(({ logo, identitySymbol, symbol }, i) => (
                <Avatar
                  key={i}
                  className="rounded"
                  src={logo}
                  alt={identitySymbol}
                  style={{ width: '20px', height: '20px' }}
                />
              ))}

              <p
                style={{
                  color: 'white',
                  // marginLeft: '5px',
                  fontSize: '16px',
                  fontWeight: '500'
                  // fontFamily: 'Inter var sans-serif'
                }}
              >
                {`(${pool?.stable_coin_symbol}${pool?.pledge_coins?.map((e) => {
                  return `/${e.symbol}`;
                })})`}
              </p>

              <p
                style={{
                  fontSize: '13px',
                  backgroundColor: 'rgb(64, 68, 79)',
                  padding: '5px',
                  borderRadius: 50,
                  fontWeight: '400',
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                  fontFamily: 'arial',
                  marginLeft: '6px',
                  color: 'white'
                }}
              >
                <ArrowUpwardIcon sx={{ fontSize: '13px' }} /> {`0.00`}%
              </p>
            </div>

            <a
              href="https://pecuscan.com/"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="outlined"
                color="success"
                style={{ borderRadius: 50, width: '100%' }}
              >
                Pecuscan
              </Button>
            </a>
            <br />

            <div
              style={{
                marginTop: '0.5rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minWidth: '90vw',
                width: '100%',
                flexWrap: 'wrap'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  minWidth: '60%',
                  flexDirection: 'column',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '4px 6px',
                    borderRadius: '8px',
                    fontWeight: '400',
                    boxSizing: 'border-box',
                    maxWidth: 'fit-content',
                    color: 'white',
                    margin: '1rem ',
                    marginLeft: 0,
                    cursor: 'pointer',
                    minWidth: '200px'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={`https://api.pecunovus.net/hootdex/images/image-1719842511599.png`}
                    alt="token logo"
                    style={{ width: '20px', height: '20px' }}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: 500,
                      marginLeft: '5px'
                    }}
                  >
                    1 {'USXM'} = {1?.toFixed(5)} USD
                  </p>
                </div>

                {pool?.tokens?.map((token, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: 'rgb(64, 68, 79)',
                        padding: '4px 6px',
                        borderRadius: '8px',
                        fontWeight: '400',
                        boxSizing: 'border-box',
                        maxWidth: 'fit-content',
                        color: 'white',
                        cursor: 'pointer',
                        minWidth: '200px',
                        margin: '1rem 0'
                      }}
                    >
                      <Avatar
                        className="rounded"
                        src={token?.logo}
                        alt={token.identitySymbol}
                        style={{
                          width: '22px',
                          height: '22px',
                          color: 'rgb(86, 90, 105)',
                          backgroundColor: 'orange'
                        }}
                      />
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          marginLeft: '5px'
                        }}
                      >
                        1 {token.identitySymbol}=
                        {token.currentPrice?.toFixed(5)}
                        USD
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <p
                className="token-page-t1 mb-1"
                style={{ color: 'gray', fontFamily: 'arial' }}
              >
                TVL
              </p>
              <p
                className="token-page-t2 mb-1"
                style={{ fontSize: '24px', fontFamily: 'arial' }}
              >
                ${convertToInternationalCurrencySystem(pool.tvl)}
              </p>
            </div>

            <p className="token-page-t2">
              <p
                className="token-page-t1 mb-1"
                style={{ color: 'gray', fontFamily: 'arial' }}
              >
                Volume 24h
              </p>
              <p
                style={{
                  marginTop: '0.5rem',
                  fontSize: '24px',
                  fontFamily: 'arial'
                }}
              >
                {`0.00`}%
              </p>
            </p>
            <br></br>
            <div style={{ marginBottom: '1rem' }}>
              <p
                className="token-page-t1 mb-1"
                style={{ color: 'gray', fontFamily: 'arial' }}
              >
                24h Fees
              </p>
              <p
                className="token-page-t2 mb-1"
                style={{
                  marginTop: '0.5rem',
                  fontSize: '24px',
                  fontFamily: 'arial'
                }}
              >
                0.00%
              </p>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{ marginTop: { xs: 8, md: 3 }, order: { xs: 0, md: 1 } }}
        >
          <Box sx={{ height: '100%' }}>
            <div
              style={{
                backgroundColor: 'rgb(25, 27, 31)',
                height: '100%',
                borderRadius: '20px',
                padding: '1.5rem'
              }}
              className="shadowGrey"
            >
              <PoolGraph id={pool.id} pool={pool} currentValue={PecuPrice} />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
          <Transactions />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
          <Alert
            variant="outlined"
            severity={alert.type}
            sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
          >
            {alert.msg}
          </Alert>
        </Collapse>
      </div>
    </Box>
  );
}
