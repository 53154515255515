import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Grid,
  LinearProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PoolGraph from '../../components/Graphs/PoolGraph';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../../store/actionTypes';
import {
  convertToInternationalCurrencySystem,
  get_crypto_Data,
  logoWrapper,
  PecuPriceHttpRequest
} from '../../utils';

export default function PoolPage() {
  const { PecuPrice, wrapTokens } = useSelector((state) => state);
  const { id } = useParams();
  const [pool, setPool] = useState({});
  const {
    holding_token_amount,
    holding_token_image,
    holding_token_price,
    holding_token_symbol,
    wrapToken_img,
    wrap_token_amount,
    wrap_token_symbol,
    project_token_amount,
    project_token_price,
    project_token_symbol,
    img,
    pecu_amount,
    tvl
  } = pool || {};
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });

  const fetchPoolData = (id) => {
    setLoading(true);
    axios
      .get(`${url}/hootdex/liquidity-pool-info?id=${id}`)
      .then((res) => {
        let poolData = res.data.data[0];
        setPool(poolData);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    get_crypto_Data(dispatch, actionTypes.CRYPTO_DATA);
    PecuPriceHttpRequest(dispatch, actionTypes.PECU_PRICE);
    fetchPoolData(id);
  }, []);

  const wrap_token_symbol_parser = (wrap_token_symbol) => {
    return (
      wrap_token_symbol?.charAt(0).toLowerCase() + wrap_token_symbol?.slice(1)
    );
  };

  if (loading) {
    return (
      <div>
        <LinearProgress sx={{ backgroundColor: 'grey' }} />
      </div>
    );
  }

  return (
    <Box sx={{ paddingLeft: { xs: '2rem', md: '4rem' } }}>
      <Grid
        width="100%"
        container
        spacing={5}
        // padding={{ xs: 0, md: 0 }}
        // mb={1}
      >
        <Grid item xs={12} mt={4}>
          {/* <div style={{ position: 'sticky' }}>
            <p
              style={{
                fontSize: '1rem',
                fontWeight: '500',
                color: 'white'
              }}
            >
              
              {'Home  >'}
              <Link
                style={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: 'white'
                }}
                to="/pools"
              >
                Pools
              </Link>
              {'>'}
              {holding_token_symbol && (
                <Link
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                  to={`/holding-token/${holding_token_symbol}`}
                  state={{
                    token_price: holding_token_price,
                    symbol: holding_token_symbol
                  }}
                >
                  {holding_token_symbol}
                </Link>
              )}
              <Link
                to={`/VentureToken/${project_token_symbol}`}
                style={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: 'white'
                }}
                state={{
                  token_price: project_token_price,
                  symbol: project_token_symbol
                }}
              >
                {project_token_symbol}
              </Link>
              {`${
                wrap_token_symbol
                  ? `/ ${wrap_token_symbol_parser(wrap_token_symbol)}`
                  : null
              } / PECU `}
            </p>
          </div> */}
        </Grid>

        <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
          <div
            style={{
              backgroundColor: 'rgb(25, 27, 31)',
              borderRadius: '20px',
              height: '100%',
              padding: '1rem',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
            className="shadowGrey"
          >
            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                // margin: '0 0 0 1rem',
                width: 'fit-content'
              }}
            >
              {holding_token_symbol && (
                <Avatar
                  className="rounded"
                  src={logoWrapper(holding_token_image)}
                  alt={holding_token_symbol}
                  style={{
                    width: '22px',
                    height: '22px',
                    color: 'rgb(86, 90, 105)'
                  }}
                />
              )}
              {wrapToken_img && (
                <Avatar
                  className="rounded"
                  src={logoWrapper(wrapToken_img)}
                  alt={wrap_token_symbol}
                  style={{
                    width: '22px',
                    height: '22px',
                    color: 'rgb(86, 90, 105)',
                    backgroundColor: 'orange'
                  }}
                />
              )}
              {project_token_symbol && (
                <Avatar
                  className="rounded"
                  src={`https://dev.hootdex.org/${img}`}
                  alt="token logo"
                  style={{
                    width: '22px',
                    height: '22px',
                    color: 'rgb(86, 90, 105)'
                  }}
                />
              )}
              <Avatar
                className="rounded"
                src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                alt="token logo"
                style={{
                  width: '22px',
                  height: '22px',
                  color: 'rgb(86, 90, 105)'
                }}
              />

              <p
                style={{
                  color: 'white',
                  // marginLeft: '5px',
                  fontSize: '16px',
                  fontWeight: '500'
                  // fontFamily: 'Inter var sans-serif'
                }}
              >
                {holding_token_symbol && (
                  <Link
                    style={{
                      color: 'white',
                      // marginLeft: '5px',
                      fontSize: '16px',
                      fontWeight: '500'
                      // fontFamily: 'Inter var sans-serif'
                    }}
                    to={`/holding-token/${holding_token_symbol}`}
                    state={{
                      token_price: holding_token_price,
                      symbol: holding_token_symbol
                    }}
                  >
                    {holding_token_symbol}
                  </Link>
                )}

                <Link
                  style={{
                    color: 'white',
                    // marginLeft: '5px',
                    fontSize: '16px',
                    fontWeight: '500'
                    // fontFamily: 'Inter var sans-serif'
                  }}
                  to={`/VentureToken/${project_token_symbol}`}
                  state={{
                    token_price: project_token_price,
                    symbol: project_token_symbol
                  }}
                >
                  {project_token_symbol}
                </Link>
                <Link
                  to={`/tokens/${wrap_token_symbol_parser(wrap_token_symbol)}`}
                  style={{
                    color: 'white',
                    // marginLeft: '5px',
                    fontSize: '16px',
                    fontWeight: '500'
                    // fontFamily: 'Inter var sans-serif'
                  }}
                  state={wrap_token_symbol}
                >
                  {`${
                    wrap_token_symbol
                      ? `/ ${wrap_token_symbol_parser(wrap_token_symbol)}`
                      : null
                  }`}
                </Link>
                {' / PECU'}
              </p>

              <p
                style={{
                  fontSize: '13px',
                  backgroundColor: 'rgb(64, 68, 79)',
                  padding: '5px',
                  borderRadius: 50,
                  fontWeight: '400',
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                  fontFamily: 'arial',
                  marginLeft: '6px',
                  color: 'white'
                }}
              >
                <ArrowUpwardIcon sx={{ fontSize: '13px' }} /> {`0.00`}%
              </p>
            </div>

            <a
              href="https://pecuscan.com/"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="outlined"
                color="success"
                style={{ borderRadius: 50, width: '100%' }}
              >
                Pecuscan
              </Button>
            </a>
            <br />

            <div
              style={{
                marginTop: '0.5rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minWidth: '90vw',
                width: '100%',
                flexWrap: 'wrap'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  minWidth: '60%',
                  flexDirection: 'column',
                  flexWrap: 'wrap'
                }}
              >
                {project_token_symbol && (
                  <Link
                    to={`/VentureToken/${project_token_symbol}`}
                    state={{
                      token_price: project_token_price,
                      symbol: project_token_symbol
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '4px 6px',
                        borderRadius: '8px',
                        fontWeight: '400',
                        boxSizing: 'border-box',
                        maxWidth: 'fit-content',
                        color: 'white',
                        cursor: 'pointer',
                        minWidth: '200px'
                      }}
                    >
                      <Avatar
                        className="rounded"
                        src={`https://dev.hootdex.org/${img}`}
                        alt="token logo"
                        style={{
                          width: '22px',
                          height: '22px',
                          color: 'rgb(86, 90, 105)'
                        }}
                      />
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          marginLeft: '5px'
                        }}
                      >
                        {}1 {project_token_symbol} =
                        {convertToInternationalCurrencySystem(
                          project_token_price
                        )}
                        USD
                      </p>
                    </div>
                  </Link>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '4px 6px',
                    borderRadius: '8px',
                    fontWeight: '400',
                    boxSizing: 'border-box',
                    maxWidth: 'fit-content',
                    color: 'white',
                    margin: '1rem ',
                    marginLeft: 0,
                    cursor: 'pointer',
                    minWidth: '200px'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                    alt="token logo"
                    style={{
                      width: '22px',
                      height: '22px',
                      color: 'rgb(86, 90, 105)'
                    }}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: 500,
                      marginLeft: '5px'
                    }}
                  >
                    1 {'PECU'} = {PecuPrice?.toFixed(5)} USD
                  </p>
                </div>
                {wrap_token_symbol && (
                  <Link
                    to={`/tokens/${wrap_token_symbol_parser(
                      wrap_token_symbol
                    )}`}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '4px 6px',
                        borderRadius: '8px',
                        fontWeight: '400',
                        boxSizing: 'border-box',
                        maxWidth: 'fit-content',
                        color: 'white',
                        cursor: 'pointer',
                        minWidth: '200px'
                      }}
                    >
                      <Avatar
                        className="rounded"
                        src={wrapToken_img?.replace(
                          'api.hootdex.net',
                          'dev.hootdex.org'
                        )}
                        alt={wrap_token_symbol}
                        style={{
                          width: '22px',
                          height: '22px',
                          color: 'rgb(86, 90, 105)',
                          backgroundColor: 'orange'
                        }}
                      />
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          marginLeft: '5px'
                        }}
                      >
                        1 {wrap_token_symbol_parser(wrap_token_symbol)}=
                        {convertToInternationalCurrencySystem(
                          wrapTokens?.filter(
                            (e) => e.symbol === wrap_token_symbol
                          )[0]?.currentPrice
                        )}
                        USD
                      </p>
                    </div>
                  </Link>
                )}
                {holding_token_symbol && (
                  <Link to={`/holding-token/${holding_token_symbol}`}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: 'rgb(64, 68, 79)',
                        padding: '4px 6px',
                        borderRadius: '8px',
                        fontWeight: '400',
                        boxSizing: 'border-box',
                        maxWidth: 'fit-content',
                        color: 'white',
                        cursor: 'pointer',
                        minWidth: '200px'
                      }}
                    >
                      <Avatar
                        className="rounded"
                        src={holding_token_image?.replace(
                          'api.hootdex.net',
                          'dev.hootdex.org'
                        )}
                        alt={holding_token_symbol}
                        style={{
                          width: '22px',
                          height: '22px',
                          color: 'rgb(86, 90, 105)',
                          backgroundColor: 'orange'
                        }}
                      />
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          marginLeft: '5px'
                        }}
                      >
                        1 {holding_token_symbol}=
                        {holding_token_price?.toFixed(5)}
                        USD
                      </p>
                    </div>
                  </Link>
                )}
              </div>
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <div
                // className="glassmorphosism"
                style={{ marginBottom: '2rem' }}
              >
                <p
                  className="token-page-t2 mb-1"
                  style={{
                    fontSize: '1rem',
                    color: 'grey',
                    fontFamily: 'arial'
                  }}
                >
                  Total Tokens Locked
                </p>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  {project_token_symbol && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        margin: '0.5rem',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          flexDriection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar
                          className="rounded"
                          src={`https://dev.hootdex.org/${img}`}
                          alt="token logo"
                          style={{ width: '22px', height: '22px' }}
                        />
                        <p
                          style={{
                            color: 'white',
                            marginLeft: '1rem',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            fontFamily: 'arial'
                          }}
                        >
                          {project_token_symbol}:
                        </p>
                      </span>

                      <p
                        style={{
                          color: 'white',
                          marginLeft: '1rem',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          fontFamily: 'arial'
                        }}
                      >
                        {` ${convertToInternationalCurrencySystem(
                          project_token_amount
                        )}`}
                      </p>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      margin: '0.5rem',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        flexDriection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Avatar
                        className="rounded"
                        src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                        alt="token logo"
                        style={{ width: '22px', height: '22px' }}
                      />
                      <p
                        style={{
                          color: 'white',
                          marginLeft: '1rem',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          fontFamily: 'arial'
                        }}
                      >
                        {`PECU :`}
                      </p>
                    </span>

                    <p
                      style={{
                        color: 'white',
                        marginLeft: '1rem',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        fontFamily: 'arial'
                      }}
                    >
                      {`${convertToInternationalCurrencySystem(pecu_amount)}`}
                    </p>
                  </div>
                  {wrap_token_symbol && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        margin: '0.5rem',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          flexDriection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar
                          className="rounded"
                          src={wrapToken_img?.replace(
                            'api.hootdex.net',
                            'dev.hootdex.org'
                          )}
                          alt={wrap_token_symbol}
                          style={{
                            width: '22px',
                            height: '22px',
                            backgroundColor: 'orange'
                          }}
                        />
                        <p
                          style={{
                            color: 'white',
                            marginLeft: '1rem',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            fontFamily: 'arial'
                          }}
                        >
                          {wrap_token_symbol_parser(wrap_token_symbol)}
                        </p>
                      </span>
                      <p
                        style={{
                          color: 'white',
                          marginLeft: '1rem',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          fontFamily: 'arial'
                        }}
                      >
                        {`${convertToInternationalCurrencySystem(
                          wrap_token_amount
                        )}
                            `}
                      </p>
                    </div>
                  )}
                  {holding_token_symbol && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        margin: '0.5rem',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          flexDriection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar
                          className="rounded"
                          src={holding_token_image?.replace(
                            'api.hootdex.net',
                            'dev.hootdex.org'
                          )}
                          alt={holding_token_symbol}
                          style={{
                            width: '22px',
                            height: '22px',
                            backgroundColor: 'orange'
                          }}
                        />
                        <p
                          style={{
                            color: 'white',
                            marginLeft: '1rem',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            fontFamily: 'arial'
                          }}
                        >
                          {`${holding_token_symbol} : 
                            `}
                        </p>
                      </span>
                      <p
                        style={{
                          color: 'white',
                          marginLeft: '1rem',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          fontFamily: 'arial'
                        }}
                      >
                        {`${convertToInternationalCurrencySystem(
                          holding_token_amount
                        )}
                            `}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <p className="token-page-t1 mb-1" style={{ color: 'gray' }}>
                TVL
              </p>
              <p className="token-page-t2 mb-1" style={{ fontSize: '24px' }}>
                ${convertToInternationalCurrencySystem(tvl)}
              </p>
            </div>

            <p className="token-page-t2">
              <p className="token-page-t1 mb-1" style={{ color: 'gray' }}>
                Volume 24h
              </p>
              <p
                style={{
                  marginTop: '0.5rem',
                  fontSize: '24px'
                }}
              >
                {`0.00`}%
              </p>
            </p>
            <br></br>
            <div style={{ marginBottom: '1rem' }}>
              <p className="token-page-t1 mb-1" style={{ color: 'gray' }}>
                24h Fees
              </p>
              <p
                className="token-page-t2 mb-1"
                style={{
                  marginTop: '0.5rem',
                  fontSize: '24px'
                }}
              >
                0.00%
              </p>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{ marginTop: { xs: 8, md: 3 }, order: { xs: 0, md: 1 } }}
        >
          <Box sx={{ height: '100%' }}>
            <div
              style={{
                backgroundColor: 'rgb(25, 27, 31)',
                height: '100%',
                borderRadius: '20px',
                padding: '1.5rem'
              }}
              className="shadowGrey"
            >
              <PoolGraph id={pool.id} pool={pool} currentValue={PecuPrice} />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
          <Transactions />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
          <Alert
            variant="outlined"
            severity={alert.type}
            sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
          >
            {alert.msg}
          </Alert>
        </Collapse>
      </div>
    </Box>
  );
}
