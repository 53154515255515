import React from 'react';
import { Avatar, Button, Grid, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
const SwapDiv = ({ token }) => {
  return (
    <>
      {' '}
      <Grid item xs={12} md={6}></Grid>
      <Grid item xs={12} md={6}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginBottom: -40
          }}
        >
          <a href={`http://app.hootdex.net/${token}`} target="_blank">
            <Box
              sx={{
                backgroundColor: ' #76C8A8;',
                color: 'black',
                padding: { xs: '0.5rem', md: '1rem 1.5rem' },
                borderRadius: 50,
                cursor: 'pointer',
                maxHeight: '40px',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              <p>Swap</p>
            </Box>
          </a>
        </div>
      </Grid>
    </>
  );
};

export default SwapDiv;
