/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import { Avatar, Button, Divider, Grid, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { api as axios } from '../../api';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import url from '../../serverUrl';
import Transactions from '../Transactions/transaction.table';
import { ArrowDownward } from '@mui/icons-material';
import CoinInfo from '../../components/coinInfo/coinInfo';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
function convertToInternationalCurrencySystem(labelValue) {
  return labelValue > 1 ? labelValue?.toFixed(5) : labelValue?.toFixed(5);
  // Nine Zeroes for Billions
}

export default function PecuPage() {
  const [data, setData] = useState({});
  const { identitySymbol } = useParams();
  const { PecuPrice } = useSelector((state) => state);

  const [trendHourly, setTrendHourly] = useState({});
  const [coinVolume, setCoinVolume] = useState(114826.00453596);
  const [trend, setTrend] = useState({});
  const get_change_index_coin_hourly = () => {
    axios.get(`/wallet/get_change_index_coin_hourly`).then((res) => {
      setTrendHourly(res.data);
    });
  };
  const calculate_daily_volume = () => {
    axios.get(`/wallet/calculate_daily_volume`).then((res) => {
      setCoinVolume(res.data.total_volume);
    });
  };

  const get_change_index_coin = () => {
    axios.get(`/wallet/get_change_index_coin`).then((res) => {
      setTrend(res.data);
    });
  };

  useEffect(() => {
    get_change_index_coin_hourly();
    get_change_index_coin();
    calculate_daily_volume();
  }, []);
  return (
    <>
      {' '}
      <Helmet>
        <title>{`PECU ${PecuPrice?.toFixed(5) ?? ''}`}</title>
        <link
          rel="icon"
          href={'https://dev.hootdex.org/hootdex/images/pecuCoin.png'}
          type="image"
        />
        {/* Other meta tags or head elements */}
      </Helmet>
      <Box sx={{ padding: { xs: 1, md: '1rem 4rem' }, marginLeft: { xs: 4 } }}>
        <Grid
          sx={{ marginLeft: '-55px' }}
          container
          spacing={5}
          // padding={{ xs: 0, md: 0 }}
          // mb={1}
        >
          {/* <Grid item xs={12} md={6} width={'100%'} > */}
          {/* <div>
                <Link
                  to={`/`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >Home</Link>
                <Link
                  to={`/cryptoPairs`}
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                >
                  {' >'} cryptoPairs
                </Link>
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white'
                  }}
                >
                  {' >'} {tokenSymbol}
                </span>
              </div> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end'
                }}
              ></div>
            </Grid> */}
          <Grid item xs={12} md={4} mt={4} sx={{ order: { xs: 1, md: 0 } }}>
            <div
              style={{
                backgroundColor: 'rgb(25, 27, 31)',
                borderRadius: '20px',
                height: '100%',
                padding: '1rem',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              {/* <img
                  src={token?.logo?.replace(
                    'api.hootdex.net',
                    'dev.hootdex.org'
                  )}
                  width={'100px'}
                /> */}
              {/* <br></br>
                <p>Symbol : {token?.identitySymbol}</p> */}
              <div
                style={{
                  display: 'flex',
                  // alignItems: 'center',
                  // margin: '0 0 0 1rem',
                  width: 'fit-content'
                }}
              >
                <Avatar
                  className="rounded"
                  src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                  alt="token logo"
                  style={{
                    height: '45px',
                    width: '45px',
                    fontSize: '18px',
                    alignSelf: 'center'
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10
                  }}
                >
                  <p
                    style={{
                      color: 'white',
                      // marginLeft: '5px',
                      fontSize: '16px',
                      fontWeight: '500'
                      // fontFamily: 'Inter var sans-serif'
                    }}
                  >
                    {'PECU'}
                  </p>

                  <p style={{ fontSize: '1rem', color: 'grey' }}>
                    {'Pecu Novus'}
                  </p>
                </div>
              </div>
              <a
                href="https://pecuscan.com/"
                style={{ textDecoration: 'none' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="outlined"
                  color="success"
                  style={{ borderRadius: 50, width: '100%' }}
                >
                  Pecuscan
                </Button>
              </a>
              <br />

              <p style={{ fontSize: '1rem', color: 'grey' }}>{'PECU'} price:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minWidth: '90vw',
                  width: '100%',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    minWidth: '60%',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontWeight: '400',
                      color: 'white',
                      cursor: 'pointer',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      style={{
                        fontSize: '24px',
                        fontWeight: 500
                      }}
                    >
                      ${convertToInternationalCurrencySystem(PecuPrice)}
                    </p>

                    <small
                      style={{ fontSize: '16px', color: 'green' }}
                      className={
                        trendHourly?.trend == '1'
                          ? `twhite green`
                          : `twhite red`
                      }
                      align="left"
                    >
                      {trendHourly?.trend == '1' ? (
                        <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                      ) : trendHourly.trend == '-1' ? (
                        <ArrowDownward sx={{ fontSize: '13px' }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ fontSize: '13px' }} />
                      )}
                      {Math.abs(trend?.value).toFixed(5)}%
                    </small>
                  </div>
                </div>
              </div>
              <br />

              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '25px',
                  color: 'grey'
                }}
              >
                <div style={{ color: 'white' }}>Description</div>
                {`Pecu Novus is a community driven secure, swift, simple, powerful and scalable blockchain network that powers the digital asset PECU and provides a solid foundation for those thinkers that are developing disruptive centralized and decentralized platforms.`}
              </p>

              <br />

              <Button
                variant="contained"
                color="success"
                style={{ borderRadius: 50, backgroundColor: '#76C8A8' }}
              >
                <a
                  // className='learnmore'
                  style={{ fontSize: '10px', padding: 5, color: 'black' }}
                  href={'https://www.pecunovus.com/'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </Button>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sx={{ marginTop: { xs: 4, md: 4 }, order: { xs: 0, md: 1 } }}
          >
            <Box sx={{ height: '100%' }}>
              <Box
                sx={{
                  backgroundColor: 'rgb(25, 27, 31)',
                  height: '100%',
                  borderRadius: '20px',
                  padding: { xs: '0 1rem 0 0', md: '1.5rem' }
                }}
                className="shadowGrey"
              >
                <CoinInfo
                  coin={{
                    symbol: 'PECU'
                  }}
                />

                {/* <TokenGraph id={token?.id} setTokenPrice={setTokenPrice} /> */}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} mt={5} sx={{ order: { xs: 2, md: 2 } }}>
            {/* <TopToken /> */}
            <Transactions />
          </Grid>
        </Grid>
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
                    <Alert
                      variant="outlined"
                      severity={alert.type}
                      sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
                    >
                      {alert.msg}
                    </Alert>
                  </Collapse>
                </div> */}
      </Box>
    </>
  );
}
