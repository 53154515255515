import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { api as axios } from '../../../api';
import url from '../../../serverUrl';

const styles = (theme) => ({
  root: {
    width: '400px'
  },
  table: {
    backgroundColor: '#1a1b1f'
  },
  tableRow: {
    '&$hover:hover': {
      backgroundColor: '#31343e'
    }
  },
  tableCell: {
    '$hover:hover &': {
      color: 'green'
    }
  },
  hover: {}
});

function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);
  // timestamp.setHours(timestamp.getHours());
  return timestamp;
}

function DenseTable({ classes, token }) {
  const [bidData, setBidData] = useState([]);
  const [marketTrade, setMarketTrade] = useState([]);
  const [symbol, setSymbol] = useState('');
  const fetchTrade = (symbol) => {
    axios
      .get(`${url}/crypto/marketTrade?symbol=${symbol}`)
      .then((res) => {
        setMarketTrade(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (
      token &&
      (token.symbol !== symbol?.symbol ||
        token.token_symbol !== symbol.token_symbol)
    ) {
      let symbolNew = token.identitySymbol ?? token.token_symbol;
      setSymbol(token);
      fetchTrade(symbolNew);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const intervalId = setInterval(() => {
        let symbolNew = token?.identitySymbol ?? token?.token_symbol;

        fetchTrade(symbolNew);
      }, 2000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [token]);

  return (
    <Paper className={classes.root} style={{ backgroundColor: 'black' }}>
      <Table className={classes.table} stickyHeader>
        <TableRow style={{ position: 'sticky', top: '0' }}>
          <TableCell colSpan={4} className="custom-color">
            <span>
              Market Trades ({symbol?.identitySymbol || symbol?.token_symbol}){' '}
            </span>
          </TableCell>
        </TableRow>

        <TableBody className="tbody-table">
          <TableRow
            hover
            classes={{ hover: classes.hover }}
            className={classes.tableRow}
          >
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Price'}
            </TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Amount'}
            </TableCell>

            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Timestamp'}
            </TableCell>
          </TableRow>

          {marketTrade.map((row) => (
            <>
              <TableRow
                hover
                key={row.pos}
                classes={{ hover: classes.hover }}
                className={classes.tableRow}
              >
                <TableCell
                  style={{
                    fontSize: '11px',
                    color: row.price < symbol.price ? 'green' : 'red'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.amount)?.toFixed(5)}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {getFormattedTime(row.timestamp).toUTCString()}
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
        <TableBody className="tbody-table">
          {bidData.map((row) => (
            <>
              <TableRow
                hover
                key={row.pos}
                classes={{ hover: classes.hover }}
                className={classes.tableRow}
              >
                <TableCell style={{ color: 'orange', fontWeight: 'bold' }}>
                  {row.symbol}
                </TableCell>

                <TableCell style={{ color: 'green' }}>{row.price}</TableCell>
                <TableCell style={{ color: 'green' }}>{row.quantity}</TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

DenseTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DenseTable);
