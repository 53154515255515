export const NavBtnCustomStyle = {
  color: '#686F7E',
  fontFeatureSettings: " 'salt' on, 'liga' off",
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '100%' /* 16px */,
  textTransform: 'none'
};

export const NavDashBtn = {
  color: '#FFF',
  fontFeatureSettings: "'salt' on, 'liga' off",
  fontFamily: 'Poppins !important',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  marginRight: '10px',
  lineHeight: '100%' /* 16px */
};

export const NavIcon = {
  display: 'flex',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center'
};
export const NavIconMobile = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const NavConnectBtn = {
  padding: '8px 15px',
  borderRadius: '40px',
  border: '1px solid #FFF',
  color: 'white',
  textAlign: 'center',
  fontFeatureSettings: "'salt' on, 'liga' off",
  fontFamily: 'Poppins !important',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '100%',
  textTransform: 'none ',
  width: '100px'
};

export const NavGetStartedBtn = {
  color: 'black',
  textAlign: 'center',
  'fontFeature-settings': "'salt' on, 'liga' off",
  fontFamily: 'Poppins !important',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '100%' /* 16px */,
  padding: '12px 15px',
  marginBottom: '2px',
  borderRadius: '40px',
  background: '#76C8A8',
  textTransform: 'none ',
  width: { sm: '90%' }
};
export const NavGetStartedBtnMobile = {
  color: 'black',
  textAlign: 'center',
  'fontFeature-settings': "'salt' on, 'liga' off",
  fontFamily: 'Poppins !important',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '100%' /* 16px */,
  padding: '12px 15px',
  marginBottom: '2px',
  borderRadius: '40px',
  background: '#76C8A8',
  textTransform: 'none ',
  width: '100%'
};

export const DashboardBtnMobile = {
  display: 'flex',
  justifyContent: 'flex-start',
  width: '90%',
  height: '36px',
  padding: '15px 24px',
  alignItems: 'center',
  gap: '15px',
  flexShrink: '0',
  borderRadius: ' 71.14px',
  background: '#76C8A8',
  color: 'black',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#76C8A8' // Set background color to 'transparent' or any other value that suits your design
  }
};

export const SideBarList = {
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'flex-start',
  width: '90%',
  height: '36px',
  padding: '15px 24px',
  alignItems: 'center',
  gap: '15px',
  flexShrink: '0',
  borderRadius: ' 71.14px',
  width: '90%',
  color: '#686F7E',
  fontFamily: 'Poppins !important',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '130%',
  textTransform: 'none'
};
