import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  Link
  // useNavigate
} from 'react-router-dom';
import {
  convertToInternationalCurrencySystem,
  fecthHoldingTokens,
  fetchFanTokens,
  fetchProjectTokens,
  logoWrapper
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDownward,
  ArrowUpward,
  StarOutline,
  ArrowForward,
  FiberManualRecord
} from '@mui/icons-material';
import { api as axios } from '../../api';
import url from '../../serverUrl';
// import { MenuData } from '../../components/Tables/menuData';
import * as actionTypes from '../../store/actionTypes';
import PecuGraph from '../../components/Graphs/PecuGraph';
import CommonMenu from '../../components/commonMenu/commonMenu';
import Transactions from '../Transactions/transaction.table';
import TokenTable from '../../components/Tables/TokenTable/token.table';
import EditToken from '../../screens/editToken';
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);
  timestamp.setHours(timestamp.getHours() - 1);
  return timestamp;
}

const RedSwapBtn = {
  display: 'flex',
  width: '119px',
  height: '36px',
  padding: '10px 19px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '30px',
  border: '1px solid #F93C3C',
  flexShrink: 0,
  color: '#F93C3C',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '100%',
  marginTop: '-20px'
};
const NormalSwapBtn = {
  display: 'flex',
  width: '119px',
  height: '36px',
  padding: '10px 19px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '30px',
  border: '1px solid #76C8A8',
  flexShrink: 0,
  color: '#76C8A8',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '100%',
  marginTop: '-20px'
};
const FanTable = () => {
  // const navigate = useNavigate();
  const loading = false;
  const { PecuPrice, projectTokens, fanTokens, holdingTokens } = useSelector(
    (state) => state
  );
  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const { isAdmin } = useSelector((state) => state);
  // console.log(PecuPrice, projectTokens, 'dddddd');
  const dispatch = useDispatch();
  useEffect(() => {
    fetchFanTokens(dispatch, actionTypes.FETCH_FAN_TOKENS);

    const intervalId = setInterval(() => {
      fetchFanTokens(dispatch, actionTypes.FETCH_FAN_TOKENS);
    }, 1500);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const [trendHourly, setTrendHourly] = useState({});
  const [coinVolume, setCoinVolume] = useState(114826.00453596);
  const [trend, setTrend] = useState({});
  const [searchProject, setSearchProject] = useState('');

  const get_change_index_coin_hourly = () => {
    axios.get(`${url}/wallet/get_change_index_coin_hourly`).then((res) => {
      setTrendHourly(res.data);
    });
  };
  const get_change_index_coin = () => {
    axios.get(`${url}/wallet/get_change_index_coin`).then((res) => {
      setTrend(res.data);
    });
  };

  const calculate_daily_volume = () => {
    axios.get(`${url}/wallet/calculate_daily_volume`).then((res) => {
      setCoinVolume(res.data.total_volume);
    });
  };
  useEffect(() => {
    get_change_index_coin_hourly();
    get_change_index_coin();
    calculate_daily_volume();
  }, []);

  console.log(fanTokens);
  return (
    <>
      <div
        className="screen"
        style={{
          zIndex: '100',
          position: 'sticky',
          width: '100%',
          marginBottom: '1rem',
          marginTop: '4rem'
        }}
      >
        <PecuGraph />
        <div
          style={{
            width: '95%',
            minWidth: '340px'
          }}
          className="res-token"
        >
          <CommonMenu label={'FanTokens'} setSearch={setSearchProject} />

          <TableContainer
            sx={{
              backgroundColor: '#19191A',
              mt: 1,
              mb: 4,
              borderRadius: '1rem',
              minHeight: '80vh'
            }}
            component={Paper}
          >
            {loading && <LinearProgress color="inherit" />}
            {!loading ? (
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: ' 1px solid #1e2128'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    {[
                      'FAV',
                      'NAME',
                      'PRICE (USD)',
                      '1h %',
                      '24h %',
                      'VOLUME 24h',
                      'TVL (USD)',
                      'DATE & TIME',
                      'MARKET STATS'
                    ].map((header, index) => {
                      return (
                        <TableCell style={{ color: 'white' }} key={index}>
                          {header}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {fanTokens?.map((each, index) => (
                    <TableRow key={each.id}>
                      <TableCell
                        style={{
                          color: 'grey',
                          display: 'flex',
                          paddingRight: 0
                        }}
                        align="left"
                      >
                        {/* <span style={{ paddingRight: 10, fontSize: '1.1em' }}>
                          {index + 1}
                        </span> */}
                        <StarOutline fontSize="small" />
                      </TableCell>

                      <TableCell style={{ color: 'grey' }} align="left">
                        <Link
                          to={`/FanToken/${each?.token_symbol}`}
                          pecuCoins={PecuPrice}
                          state={{
                            token_price: each.token_price,
                            symbol: each?.token_symbol
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Avatar
                              className="rounded"
                              src={each?.image}
                              alt="token logo"
                              style={{
                                height: '35px',
                                width: '35px',
                                fontSize: '18px'
                              }}
                            />

                            <span
                              style={{
                                marginLeft: '1rem',
                                fontSize: '14px',
                                color: 'grey',
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <small
                                style={{
                                  fontSize: '13px',
                                  color: 'orange'
                                }}
                              >
                                {each.token_symbol}
                              </small>
                              <small
                                style={{
                                  fontSize: '14px',
                                  marginRight: '4px',
                                  fontWeight: 400
                                }}
                              >{`${each?.token_name}`}</small>
                            </span>
                          </div>
                        </Link>
                      </TableCell>

                      <TableCell
                        // className={
                        //   each.priceChange >= 0 ? `twhite ` : `twhite `
                        // }
                        style={{ color: 'grey' }}
                        align="left"
                      >
                        {each?.token_price >= 1
                          ? (each?.token_price)
                              .toFixed(5)
                              .toLocaleString('en-US')
                          : (each?.token_price).toFixed(5)}
                      </TableCell>

                      <TableCell
                        className={
                          each.price1hour - each.token_price >= 0
                            ? `twhite green`
                            : `twhite red`
                        }
                        align="left"
                      >
                        {each.price1hour - each.token_price > 0 ? (
                          <ArrowUpward sx={{ fontSize: '13px' }} />
                        ) : each.price1hour - each.token_price < 0 ? (
                          <ArrowDownward sx={{ fontSize: '13px' }} />
                        ) : (
                          <ArrowUpward sx={{ fontSize: '13px' }} />
                        )}
                        {each.price1hour
                          ? (
                              Math.abs(
                                (each.price1hour - each.token_price) /
                                  each.price1hour
                              ) * 100
                            ).toFixed(5)
                          : '0.00'}
                        %
                      </TableCell>

                      <TableCell
                        className={
                          each.price24hour - each.token_price >= 0
                            ? `twhite green`
                            : `twhite red`
                        }
                        align="left"
                      >
                        {each.price24hour - each.token_price > 0 ? (
                          <ArrowUpward sx={{ fontSize: '13px' }} />
                        ) : each.price24hour - each.token_price < 0 ? (
                          <ArrowDownward sx={{ fontSize: '13px' }} />
                        ) : (
                          <ArrowUpward sx={{ fontSize: '13px' }} />
                        )}
                        {each.price24hour
                          ? (
                              Math.abs(
                                (each.price24hour - each.token_price) /
                                  each.price24hour
                              ) * 100
                            ).toFixed(5)
                          : '0.00'}{' '}
                        %
                      </TableCell>

                      <TableCell style={{ color: 'grey' }} align="left">
                        {each.volume
                          ? convertToInternationalCurrencySystem(each.volume)
                          : '0.00'}
                      </TableCell>

                      <TableCell style={{ color: 'grey' }} align="left">
                        {convertToInternationalCurrencySystem(each.token_tvl)}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          color: 'grey'
                        }}
                      >
                        <p style={{ fontSize: '12px', fontWeight: 300 }}>
                          {getFormattedTime(each.timestamp).toDateString()}
                        </p>
                        <p style={{ fontSize: '12px', fontWeight: 300 }}>
                          {
                            getFormattedTime(each.timestamp)
                              ?.toUTCString()
                              ?.split(' ')[4]
                          }{' '}
                          GMT
                        </p>
                      </TableCell>

                      <TableCell>
                        {' '}
                        <a
                          href={`https://app.hootdex.net/${each.token_symbol}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            sx={
                              each.priceChange < 0 ? RedSwapBtn : NormalSwapBtn
                            }
                          >
                            Swap
                          </Button>
                        </a>
                      </TableCell>
                      {isAdmin && (
                        <TableCell>
                          {/* {' '}
                    <Button
                    
                    >
                      Edit
                    </Button> */}
                          <button
                            onClick={() => {
                              setOpen(!open);
                              setSelectedToken({
                                ...each,
                                name: each.token_name,
                                logo: each.image,
                                description: each.token_desc,
                                identitySymbol: each.token_symbol,
                                symbol: each.token_symbol
                              });
                            }}
                            className={'NormalSwapBtn'}
                          >
                            Edit
                          </button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                  {/* {filterProject(searchProject)
                    ?.slice(projectPage, projectPage + 10)
                    ?.map((each, index) => {
                      each.symbol = each.token_symbol;
                      each.tokenName = each.token_name;

                      return (
                        <TableRow key={each.id}>
                          <TableCell style={{ color: 'grey' }} align="left">
                            <Link
                              to={`/holding-token/${each.symbol}`}
                              state={each}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Avatar
                                  className="rounded"
                                  src={logoWrapper(each?.image)}
                                  alt={each.symbol.slice(1)}
                                  style={{
                                    height: '35px',
                                    width: '35px',
                                    fontSize: '18px'
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: '1rem',
                                    fontSize: '14px',
                                    color: 'grey',
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  
                                  <small
                                    style={{
                                      fontSize: '13px',
                                      color: 'orange'
                                    }}
                                  >
                                    {each.symbol}
                                  </small>
                                  <small
                                    style={{
                                      fontSize: '14px',
                                      marginRight: '4px',
                                      fontWeight: 400
                                    }}
                                  >
                                    {each.token_name}
                                  </small>
                                </span>
                              </div>
                            </Link>
                          </TableCell>
                          <TableCell
                            className={
                              Math.ceil(each.priceChange) >= 0
                                ? `twhite `
                                : `twhite `
                            }
                            align="left"
                          >
                            
                            {each?.token_price >= 1
                              ? (each?.token_price)
                                .toFixed(5)
                                .toLocaleString('en-US')
                              : (each?.token_price).toFixed(5)}
                          </TableCell>
                          <TableCell
                            className={
                              each.priceChange >= 0
                                ? `twhite green`
                                : `twhite red`
                            }
                            align="left"
                          >
                            {each.priceChange >= 0 ? (
                              <ArrowUpward sx={{ fontSize: '13px' }} />
                            ) : each.priceChange < 0 ? (
                              <ArrowDownward sx={{ fontSize: '13px' }} />
                            ) : (
                              <ArrowUpward sx={{ fontSize: '13px' }} />
                            )}
                            {(each.priceChange / (3 * each.token_price))
                              ?.toFixed(5)
                              .toLocaleString('en-US')}
                            %
                          </TableCell>
                          
                          <TableCell
                            className={
                              each.priceChange >= 0
                                ? `twhite green`
                                : `twhite red`
                            }
                            align="left"
                          >
                            {each.priceChange > 0 ? (
                              <ArrowUpward sx={{ fontSize: '13px' }} />
                            ) : each.priceChange < 0 ? (
                              <ArrowDownward sx={{ fontSize: '13px' }} />
                            ) : (
                              <ArrowUpward sx={{ fontSize: '13px' }} />
                            )}
                            {((each.priceChange / 2) * each.token_price)
                              ?.toFixed(5)
                              .toLocaleString('en-US')}
                            %
                          </TableCell>

                          <TableCell className="twhite pink" align="left">
                            {each.volume
                              ? convertToInternationalCurrencySystem(
                                each.volume
                              )
                              : '0.00'}
                          </TableCell>
                          <TableCell className="twhite blue" align="left">
                            
                            {convertToInternationalCurrencySystem(
                              each.token_tvl
                            )}
                          </TableCell>
                          <TableCell
                            className={'twhite'}
                            align="left"
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <p style={{ fontSize: '12px', fontWeight: 300 }}>
                              {getFormattedTime(each.timestamp).toUTCString()}
                            </p>
                          </TableCell>
                          <TableCell>
                            
                            <a
                              href={`https://app.hootdex.net/${each.token_symbol}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                sx={{
                                  color: 'white',
                                  textTransform: 'capitalize',
                                  backgroundColor: '#363736'
                                  // m: 1,
                                }}
                              >
                                Swap
                              </Button>
                            </a>
                          </TableCell>
                        </TableRow>
                      );
                    })} */}
                </TableBody>
              </Table>
            ) : (
              <Skeleton
                sx={{ bgcolor: '#21242b', mt: 1 }}
                variant="rectangular"
                margin={'1rem'}
                height={100}
              />
            )}
            {open && (
              <EditToken token={selectedToken} setOpen={setOpen} open={open} />
            )}
          </TableContainer>

          <Transactions />
        </div>
      </div>
    </>
  );
};

export default React.memo(FanTable);
